/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '~swiper/swiper-bundle.min.css';
/******* Base styles *******/
html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 1.5;
}

* {
  margin: 0;
  padding: 0;
}

a {
  outline: none;
}

.h-100 {
  height: 100% !important;
}

.w-100 {
  width: 100% !important;
}

.w-90 {
  width: 90% !important;
}

.w-80 {
  width: 80% !important;
}

.w-20 {
  width: 20% !important;
}

.w-10 {
  width: 10% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100%;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.lh {
  line-height: 27px;
}

.d-block {
  display: block !important;
}

.theme-container {
  max-width: 1300px;
  margin: 0 auto;
  width: 100%;
}

.light-block.mat-mdc-card {
  background: rgba(0, 0, 0, 0.03) !important;
  box-shadow: none !important;
}

.transition {
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  transition: 0.3s;
}

.text-truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden !important;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.divider {
  border-top: 1px solid rgba(204, 204, 204, 0.6);
}

.mat-mdc-icon-button .mat-icon.mat-icon-xs,
.mat-icon.mat-icon-xs {
  font-size: 14px !important;
  line-height: 14px !important;
  height: 14px !important;
  width: 14px !important;
  vertical-align: middle !important;
  overflow: unset !important;
}

.mat-mdc-icon-button .mat-icon.mat-icon-sm,
.mat-icon.mat-icon-sm,
.mat-nav-list .mat-mdc-list-item .mat-mdc-list-item-icon.mat-icon-sm {
  font-size: 18px !important;
  line-height: 18px !important;
  height: 18px !important;
  width: 18px !important;
  vertical-align: middle !important;
  overflow: unset !important;
}

.mat-mdc-icon-button .mat-icon.mat-icon-md,
.mat-icon.mat-icon-md,
.mat-nav-list .mat-mdc-list-item .mat-mdc-list-item-icon.mat-icon-sm {
  font-size: 24px !important;
  line-height: 24px !important;
  height: 24px !important;
  width: 24px !important;
  vertical-align: middle !important;
  overflow: unset !important;
}

.mat-mdc-icon-button .mat-icon.mat-icon-lg,
.mat-icon.mat-icon-lg {
  font-size: 36px !important;
  line-height: 36px !important;
  height: 36px !important;
  width: 36px !important;
  vertical-align: middle !important;
  overflow: unset !important;
}

.mat-mdc-icon-button .mat-icon.mat-icon-xlg,
.mat-icon.mat-icon-xlg {
  font-size: 48px !important;
  line-height: 48px !important;
  height: 48px !important;
  width: 48px !important;
  vertical-align: middle !important;
  overflow: unset !important;
}

.mat-mdc-button > .mat-icon.caret {
  width: 14px;
  margin: 0 !important;
}

.mat-step-text-label {
  text-transform: uppercase;
}

.mat-mdc-row, .mat-mdc-header-row {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.pages-container .mat-drawer-content {
  overflow: hidden;
}

.filter-sidenav .mat-drawer-inner-container {
  padding: 2px;
  box-sizing: border-box;
}
.filter-sidenav .mat-drawer-inner-container .mat-expansion-panel {
  border-radius: 0 !important;
}

.app-dropdown.mat-mdc-menu-panel {
  min-width: 60px !important;
}

.app-dropdown .mat-mdc-menu-item {
  min-height: 36px;
  line-height: 36px;
  font-size: 14px;
}
.app-dropdown .mat-mdc-menu-item .mdc-list-item__primary-text {
  display: flex;
  align-items: center;
}

.account-icon {
  margin-top: -2px;
  margin-right: 2px;
}

.app-dropdown.account .mat-mdc-menu-content {
  padding: 0;
}
.app-dropdown.account .mat-mdc-menu-content .user-info {
  padding: 16px;
}
.app-dropdown.account .mat-mdc-menu-content .user-info img {
  margin-right: 16px;
  border-radius: 3px;
}
.app-dropdown.account .mat-mdc-menu-content .user-info .mat-icon {
  margin-top: -2px;
  color: #fbc02d;
}

.categories-dropdown.mat-mdc-menu-panel {
  max-height: 350px;
}

.categories-dropdown .mat-mdc-menu-item {
  min-height: 24px;
  line-height: 24px;
  font-size: 14px;
}

.sub-category button {
  padding-left: 30px;
}

.sub-category .sub-category button {
  padding-left: 50px;
}

.d-block {
  display: block;
}

.info-bar {
  margin-top: 30px;
}
.info-bar .mat-mdc-card {
  height: 100px;
}
.info-bar .mat-mdc-card .content {
  margin-left: 10px;
}
.info-bar .mat-mdc-card .content p {
  font-weight: 500;
}
.info-bar .mat-mdc-card .content span {
  font-size: 13px;
}

.social-icon {
  width: 48px;
  height: 48px;
  cursor: pointer;
  fill: #8f8f8f;
  outline: none;
}

@media (min-width: 960px) {
  .app.main-toolbar-fixed #menu-toolbar {
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 9;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }
  .app.main-toolbar-fixed .main {
    padding-top: 64px;
  }
}
/******* Toolbar *******/
.top-toolbar.mat-toolbar-row {
  height: 32px;
  font-size: 13px;
}
.top-toolbar.mat-toolbar-row .sidenav-toggle {
  height: 31px;
  line-height: 31px;
  min-width: 24px;
  padding: 0;
  border-radius: 0;
}
.top-toolbar.mat-toolbar-row .top-menu {
  margin-right: -16px;
  text-transform: uppercase;
}
.top-toolbar.mat-toolbar-row .top-menu a {
  text-decoration: none;
  font-weight: 400;
  font-size: 13px;
  line-height: 31px;
  padding: 0 10px;
  min-width: 70px;
  border-radius: 0;
  height: 30px;
  text-transform: uppercase;
}
.top-toolbar.mat-toolbar-row .top-menu a .mdc-button__label {
  display: flex;
}
.top-toolbar.mat-toolbar-row .top-menu a.mobile-menu-icon {
  min-width: 36px;
}
.top-toolbar.mat-toolbar-row .top-menu .mat-icon.more {
  height: 28px;
}

.flag-menu-title {
  vertical-align: top !important;
  margin-left: 3px;
}

.top-menu-dropdown .mat-mdc-menu-item img {
  vertical-align: middle;
  margin-right: 8px;
}

.cart-dropdown.mat-mdc-menu-panel {
  padding: 6px 14px;
  font-size: 13px;
}
.cart-dropdown.mat-mdc-menu-panel .list-item {
  padding: 8px 0;
}
.cart-dropdown.mat-mdc-menu-panel .list-item h3 {
  font-size: 14px;
  font-weight: 500;
}
.cart-dropdown.mat-mdc-menu-panel .list-item p {
  font-size: 13px;
  font-weight: 400;
}
.cart-dropdown.mat-mdc-menu-panel .list-item img {
  width: 70px;
  margin-left: 10px;
}
.cart-dropdown.mat-mdc-menu-panel .cart-dropdown-footer {
  font-size: 14px;
}

.shopping-cart-btn {
  color: #fff !important;
}
.shopping-cart-btn .mat-badge-content {
  right: 6px !important;
}

.app-menu-panel.mat-mdc-menu-panel {
  min-width: 60px;
}
.app-menu-panel.mat-mdc-menu-panel .mat-mdc-menu-content:not(:empty) {
  padding: 0;
}
.app-menu-panel.mat-mdc-menu-panel .mat-mdc-menu-item {
  display: flex;
  align-items: center;
  font-size: 12px;
  text-transform: uppercase;
}
.app-menu-panel.mat-mdc-menu-panel .mat-mdc-menu-item .mat-badge-small.mat-badge-above .mat-badge-content {
  top: 8px;
}
.app-menu-panel.mat-mdc-menu-panel.lang {
  min-width: 48px;
}
.app-menu-panel.mat-mdc-menu-panel.lang .mat-mdc-menu-item {
  line-height: 36px;
  min-height: 36px;
  padding: 0;
}
.app-menu-panel.mat-mdc-menu-panel.lang .mat-mdc-menu-item .mdc-list-item__primary-text {
  width: 100%;
}
.app-menu-panel.mat-mdc-menu-panel.lang .mat-mdc-menu-item img {
  display: block;
  margin: 0 auto;
}

.logo-toolbar.mat-toolbar-row {
  height: 104px;
}

.logo {
  font-size: 28px;
  font-weight: 900;
  text-transform: uppercase;
  text-decoration: none;
  color: inherit;
}

.search-form {
  border-radius: 22px;
  overflow: hidden;
}
.search-form .categories {
  height: 40px;
  width: 180px;
  border-radius: 0;
  white-space: nowrap;
}
.search-form input[type=text] {
  border: 0;
  outline: none;
  padding: 0 34px 0 12px;
  border-left: 1px solid #ccc;
}
.search-form .search-btn {
  margin-left: -20px;
  background: #fff !important;
  box-shadow: none !important;
}

.search-dropdown.mat-mdc-menu-panel {
  border-radius: 26px;
  max-width: 100% !important;
  min-height: auto;
}
.search-dropdown.mat-mdc-menu-panel .mat-mdc-menu-content {
  padding: 0;
}

.top-cart span {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.2;
}

/******* Top navbar (menu) *******/
.top-navbar.mat-toolbar {
  height: 48px;
  padding: 0;
}
.top-navbar.mat-toolbar a {
  min-width: 70px;
  border-radius: 0;
  text-transform: uppercase;
  padding: 6px 16px;
  font-weight: 500;
  height: 48px;
}

.cdk-overlay-pane.mega-menu-pane {
  left: 0 !important;
  width: 100%;
}
.cdk-overlay-pane.mega-menu-pane .mat-mdc-menu-panel {
  width: 100%;
  max-width: none;
  border-radius: 0;
}
.cdk-overlay-pane.mega-menu-pane .mat-mdc-menu-panel .mat-mdc-menu-content {
  padding: 0;
}
.cdk-overlay-pane.mega-menu-pane .mega-menu-widget {
  position: relative;
}
.cdk-overlay-pane.mega-menu-pane .mega-menu-widget button.mdc-button {
  position: absolute;
  top: 10px;
  left: 10px;
  font-weight: 400;
}
.cdk-overlay-pane.mega-menu-pane .mega-menu-widget .mat-mdc-chip-listbox {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

/******* Carousel *******/
button.swipe-arrow {
  position: absolute;
  top: 50%;
  margin-top: -22px;
  z-index: 10;
  background-image: none;
}

.swiper-pagination.white .swiper-pagination-bullet {
  background: #fff;
}

.swiper-pagination-bullet-active {
  width: 12px;
  height: 12px;
  vertical-align: -2px;
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after,
.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  content: none;
}

/******* Product *******/
.product-item {
  position: relative;
  padding: 16px;
}
.product-item .mat-mdc-chip-listbox {
  position: absolute;
  top: 10px;
  right: 10px;
}
.product-item .mat-mdc-chip-listbox.list-view {
  left: 10px;
}
.product-item .image-link {
  display: block;
}
.product-item img {
  max-width: 100%;
  margin-bottom: 12px;
}
.product-item .category {
  margin-bottom: 4px;
}
.product-item .title {
  display: block;
  color: inherit;
  text-decoration: none;
  font-weight: 500;
  font-size: 18px;
}
.product-item .prices {
  margin: 12px 0;
}
.product-item .prices .old-price {
  text-decoration: line-through;
  height: 15px;
}
.product-item .prices .new-price {
  font-size: 16px;
  font-weight: 500;
}
.product-item .icons {
  margin-top: 18px;
}

.old-price {
  text-decoration: line-through;
}

/******* Back to top *******/
.back-to-top {
  position: fixed;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 999999;
  right: 20px;
  bottom: 20px;
  opacity: 0.5;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 4px;
}
.back-to-top:hover {
  opacity: 0.9;
}

/******* Responsive *******/
@media (max-width: 575px) {
  .top-toolbar.mat-toolbar-row {
    padding: 0;
  }
  .top-toolbar.mat-toolbar-row .top-menu {
    margin-right: 0;
  }
  .top-toolbar.mat-toolbar-row .top-menu a {
    min-width: 56px;
  }
  .top-toolbar.mat-toolbar-row .sidenav-toggle {
    min-width: 52px;
  }
  .search-form input[type=text] {
    width: 160px;
  }
  .search-form .categories {
    width: 140px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .top-toolbar.mat-toolbar-row {
    padding: 0;
  }
  .top-toolbar.mat-toolbar-row .top-menu {
    margin-right: 0;
  }
  .top-toolbar.mat-toolbar-row .sidenav-toggle {
    min-width: 52px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .top-toolbar.mat-toolbar-row {
    padding: 0;
  }
  .top-toolbar.mat-toolbar-row .top-menu {
    margin-right: 0;
  }
  .top-toolbar.mat-toolbar-row .sidenav-toggle {
    min-width: 52px;
  }
}
@media (min-width: 1380px) {
  .theme-container {
    max-width: 1400px;
  }
}
.sidenav .mat-drawer-inner-container {
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #cccccc;
}
::-webkit-scrollbar-thumb:active {
  background: #888888;
}

::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-track:hover {
  background: #666666;
}
::-webkit-scrollbar-track:active {
  background: #333333;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

ng-scrollbar {
  --scrollbar-size: 6px !important;
  --scrollbar-thumb-color: rgba(204,204,204,0.8) !important;
  --scrollbar-thumb-hover-color: #ccc !important;
  --scrollbar-border-radius: 4px !important;
  --scrollbar-hover-size: 8px !important;
  --scrollbar-padding: 2px !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 2.5rem !important;
}

.mt-5,
.my-5 {
  margin-top: 2.5rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 2.5rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 2.5rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 2.5rem !important;
}

.m-6 {
  margin: 4rem !important;
}

.mt-6,
.my-6 {
  margin-top: 4rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 4rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 4rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 4rem !important;
}

.m-7 {
  margin: 6rem !important;
}

.mt-7,
.my-7 {
  margin-top: 6rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 6rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 6rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 6rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 2.5rem !important;
}

.pt-5,
.py-5 {
  padding-top: 2.5rem !important;
}

.pr-5,
.px-5 {
  padding-right: 2.5rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 2.5rem !important;
}

.pl-5,
.px-5 {
  padding-left: 2.5rem !important;
}

.p-6 {
  padding: 4rem !important;
}

.pt-6,
.py-6 {
  padding-top: 4rem !important;
}

.pr-6,
.px-6 {
  padding-right: 4rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 4rem !important;
}

.pl-6,
.px-6 {
  padding-left: 4rem !important;
}

.p-7 {
  padding: 6rem !important;
}

.pt-7,
.py-7 {
  padding-top: 6rem !important;
}

.pr-7,
.px-7 {
  padding-right: 6rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 6rem !important;
}

.pl-7,
.px-7 {
  padding-left: 6rem !important;
}

.toolbar-dropdown-menu {
  margin-top: 9px;
  border-radius: 0 !important;
}
.toolbar-dropdown-menu .mat-mdc-menu-content {
  padding: 0 !important;
}

.user-menu .mat-toolbar, .user-menu .mat-toolbar-row {
  height: 100px !important;
}
.user-menu .user-info {
  width: 230px;
}
.user-menu .user-info p {
  font-size: 16px;
  line-height: 22px;
  text-align: center;
}
.user-menu .user-info img {
  border-radius: 50%;
}
.user-menu .mat-mdc-menu-item {
  min-height: 36px;
  line-height: 36px;
}

.h-300p {
  height: 300px;
}

.h-400p {
  height: 400px;
}

.uppercase {
  text-transform: uppercase !important;
}

.user-block {
  height: 0;
  position: relative;
  z-index: 1;
  overflow: hidden;
  transition: 0.2s;
}
.user-block img {
  width: 80px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 50%;
}
.user-block .user-info-wrapper {
  padding: 0 6px;
  margin: 6px 0;
  width: 100%;
}
.user-block .user-info-wrapper .user-info {
  text-align: center;
}
.user-block .user-info-wrapper .user-info .name {
  font-size: 15px;
}
.user-block .user-info-wrapper .user-info .position {
  font-size: 13px;
}
.user-block.show {
  height: 107px;
}

.admin-table.mat-mdc-table {
  display: block;
  overflow-x: auto;
}
.admin-table.mat-mdc-table .mat-mdc-row, .admin-table.mat-mdc-table .mat-mdc-header-row {
  display: flex;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  align-items: center;
  min-height: 48px;
  padding: 0 16px;
  min-width: 500px;
}
.admin-table.mat-mdc-table .mat-mdc-cell, .admin-table.mat-mdc-table .mat-mdc-header-cell {
  flex: 1;
  overflow: hidden;
  word-wrap: break-word;
  padding: 0 8px;
}
.admin-table.mat-mdc-table .mat-mdc-cell.image, .admin-table.mat-mdc-table .mat-mdc-header-cell.image {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 60px;
}
.admin-table.mat-mdc-table .mat-mdc-cell.image img, .admin-table.mat-mdc-table .mat-mdc-header-cell.image img {
  display: block;
  border-radius: 50%;
  margin: 4px 0;
}
.admin-table.mat-mdc-table .mat-mdc-cell.status, .admin-table.mat-mdc-table .mat-mdc-header-cell.status {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 32px;
}
.admin-table.mat-mdc-table .mat-mdc-cell.ratings, .admin-table.mat-mdc-table .mat-mdc-header-cell.ratings {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 80px;
}
.admin-table.mat-mdc-table .mat-mdc-header-cell {
  font-size: 14px;
}
.admin-table.mat-mdc-table .mat-mdc-cell .actions {
  text-align: right;
}
.admin-table.mat-mdc-table .mat-mdc-cell .actions button {
  margin: 0 4px;
}
.admin-table.mat-mdc-table.header-sm .mat-mdc-header-row {
  min-height: 36px;
}
.admin-table.mat-mdc-table.truncated .mat-mdc-cell, .admin-table.mat-mdc-table.truncated .mat-mdc-header-cell {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.vertical-tabs.mat-mdc-tab-group {
  flex-direction: row;
}
.vertical-tabs.mat-mdc-tab-group .mat-mdc-tab-labels {
  flex-direction: column;
}
.vertical-tabs.mat-mdc-tab-group .mdc-tab-indicator__content--underline {
  display: none;
}
.vertical-tabs.mat-mdc-tab-group .mat-mdc-tab-body-wrapper {
  padding-left: 16px;
}

.tab-content-wrapped.mat-mdc-tab-group .mat-mdc-tab-body-content {
  overflow: hidden;
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1,
.mat-headline-5,
.mat-typography .mat-h1,
.mat-typography .mat-headline-5,
.mat-typography h1 {
  font: 400 24px / 32px Roboto, sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-headline-6,
.mat-typography .mat-h2,
.mat-typography .mat-headline-6,
.mat-typography h2 {
  font: 500 20px / 32px Roboto, sans-serif;
  letter-spacing: 0.0125em;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subtitle-1,
.mat-typography .mat-h3,
.mat-typography .mat-subtitle-1,
.mat-typography h3 {
  font: 400 16px / 28px Roboto, sans-serif;
  letter-spacing: 0.009375em;
  margin: 0 0 16px;
}

.mat-h4,
.mat-body-1,
.mat-typography .mat-h4,
.mat-typography .mat-body-1,
.mat-typography h4 {
  font: 400 14px / 20px Roboto, sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-subtitle-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-subtitle-2 {
  font: 500 14px / 22px Roboto, sans-serif;
  letter-spacing: 0.0071428571em;
}

.mat-body,
.mat-body-2,
.mat-typography .mat-body,
.mat-typography .mat-body-2,
.mat-typography {
  font: 400 14px / 20px Roboto, sans-serif;
  letter-spacing: 0.0178571429em;
}
.mat-body p,
.mat-body-2 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-2 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, sans-serif;
  letter-spacing: 0.0333333333em;
}

.mat-headline-1,
.mat-typography .mat-headline-1 {
  font: 300 96px / 96px Roboto, sans-serif;
  letter-spacing: -0.015625em;
  margin: 0 0 56px;
}

.mat-headline-2,
.mat-typography .mat-headline-2 {
  font: 300 60px / 60px Roboto, sans-serif;
  letter-spacing: -0.0083333333em;
  margin: 0 0 64px;
}

.mat-headline-3,
.mat-typography .mat-headline-3 {
  font: 400 48px / 50px Roboto, sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-headline-4,
.mat-typography .mat-headline-4 {
  font: 400 34px / 40px Roboto, sans-serif;
  letter-spacing: 0.0073529412em;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto, sans-serif;
  letter-spacing: 0.0178571429em;
}

.mat-button-toggle {
  font-family: Roboto, sans-serif;
}

.mat-calendar {
  font-family: Roboto, sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 600;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-expansion-panel-header {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-expansion-panel-content {
  font: 400 14px / 20px Roboto, sans-serif;
  letter-spacing: 0.0178571429em;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 400;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 32px Roboto, sans-serif;
  letter-spacing: 0.0125em;
  margin: 0;
}

.mat-tree {
  font-family: Roboto, sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-mdc-option {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-body1-font-size, 14px);
  line-height: var(--mdc-typography-body1-line-height, 20px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, normal);
}

.mat-mdc-card-title {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-headline6-font-size, 20px);
  line-height: var(--mdc-typography-headline6-line-height, 32px);
  font-weight: var(--mdc-typography-headline6-font-weight, 500);
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, 0.0125em);
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: var(--mdc-typography-headline6-text-transform, none);
}

.mat-mdc-card-subtitle {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-subtitle2-font-size, 14px);
  line-height: var(--mdc-typography-subtitle2-line-height, 22px);
  font-weight: var(--mdc-typography-subtitle2-font-weight, 500);
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, 0.0071428571em);
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle2-text-transform, none);
}

.mat-mdc-tooltip {
  --mdc-plain-tooltip-supporting-text-font: Roboto, sans-serif;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0.0333333333em;
}

.mdc-text-field__input {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}
.mdc-text-field__affix {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}
[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mdc-text-field--textarea .mdc-text-field__input {
  line-height: 1.5rem;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
[dir=rtl] .mdc-text-field--end-aligned .mdc-text-field__input, .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mdc-floating-label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}

[dir=rtl] .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker), .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.mat-mdc-form-field-subscript-wrapper,
.mat-mdc-form-field-bottom-align::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-caption-font-size, 12px);
  line-height: var(--mdc-typography-caption-line-height, 20px);
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: var(--mdc-typography-caption-text-transform, none);
}

.mat-mdc-form-field,
.mat-mdc-floating-label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-body1-font-size, 14px);
  line-height: var(--mdc-typography-body1-line-height, 20px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: var(--mdc-typography-body1-text-transform, none);
}

.mat-mdc-form-field .mdc-text-field--outlined .mdc-floating-label--float-above {
  font-size: calc(14px * var(--mat-mdc-form-field-floating-label-scale, 0.75));
}
.mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 14px;
}

.mat-mdc-select-panel {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  line-height: var(--mdc-typography-subtitle1-line-height, 28px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
  /* @alternate */
  line-height: 20px;
}

.mat-mdc-select {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-body1-font-size, 14px);
  line-height: var(--mdc-typography-body1-line-height, 20px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: var(--mdc-typography-body1-text-transform, none);
}

.mat-mdc-autocomplete-panel {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  line-height: var(--mdc-typography-subtitle1-line-height, 28px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
  /* @alternate */
  line-height: 20px;
}

.mat-mdc-dialog-container {
  --mdc-dialog-subhead-font: Roboto, sans-serif;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: 0.0125em;
  --mdc-dialog-supporting-text-font: Roboto, sans-serif;
  --mdc-dialog-supporting-text-line-height: 20px;
  --mdc-dialog-supporting-text-size: 14px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: normal;
}

.mat-mdc-standard-chip {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}
.mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}
.mat-mdc-slider {
  --mdc-slider-label-label-text-font: Roboto, sans-serif;
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 22px;
  --mdc-slider-label-label-text-tracking: 0.0071428571em;
  --mdc-slider-label-label-text-weight: 500;
}

.mat-mdc-menu-content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  line-height: var(--mdc-typography-subtitle1-line-height, 28px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
  /* @alternate */
  line-height: 20px;
}
.mat-mdc-menu-content,
.mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-body1-font-size, 14px);
  line-height: var(--mdc-typography-body1-line-height, 20px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: var(--mdc-typography-body1-text-transform, none);
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-font: Roboto, sans-serif;
  --mdc-list-list-item-label-text-line-height: 20px;
  --mdc-list-list-item-label-text-size: 14px;
  --mdc-list-list-item-label-text-tracking: normal;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: Roboto, sans-serif;
  --mdc-list-list-item-supporting-text-line-height: 20px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: 0.0178571429em;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Roboto, sans-serif;
  --mdc-list-list-item-trailing-supporting-text-line-height: 20px;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: 0.0333333333em;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  font: 400 16px / 28px Roboto, sans-serif;
  letter-spacing: 0.009375em;
}

.mat-mdc-paginator {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-caption-font-size, 12px);
  line-height: var(--mdc-typography-caption-line-height, 20px);
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: var(--mdc-typography-caption-text-transform, none);
}

.mat-mdc-paginator .mat-mdc-select-value {
  font-size: 12px;
}

.mdc-tab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 22px);
  font-weight: var(--mdc-typography-button-font-weight, 600);
  letter-spacing: var(--mdc-typography-button-letter-spacing, 0.02em);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
@media all and (-ms-high-contrast: none) {
  .mdc-checkbox .mdc-checkbox__focus-ring {
    display: none;
  }
}

.mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}
.mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 22px);
  font-weight: var(--mdc-typography-button-font-weight, 600);
  letter-spacing: var(--mdc-typography-button-letter-spacing, 0.02em);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}

.mdc-fab--extended {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 22px);
  font-weight: var(--mdc-typography-button-font-weight, 600);
  letter-spacing: var(--mdc-typography-button-letter-spacing, 0.02em);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
.mat-mdc-snack-bar-container {
  --mdc-snackbar-supporting-text-font: Roboto, sans-serif;
  --mdc-snackbar-supporting-text-line-height: 20px;
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-weight: 400;
}

.mdc-data-table__content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mdc-data-table__cell {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mdc-data-table__header-cell {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-subtitle2-font-size, 14px);
  line-height: var(--mdc-typography-subtitle2-line-height, 22px);
  font-weight: var(--mdc-typography-subtitle2-font-weight, 500);
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, 0.0071428571em);
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle2-text-transform, none);
}

[dir=rtl] .mdc-data-table__pagination-button .mdc-button__icon, .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.app.green {
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  /* ngx-pagination */
}
.app.green .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.green .mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.green .mat-mdc-option:hover:not(.mdc-list-item--disabled), .app.green .mat-mdc-option:focus:not(.mdc-list-item--disabled), .app.green .mat-mdc-option.mat-mdc-option-active, .app.green .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app.green .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #689f38);
}
.app.green .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #e0e0e0);
}
.app.green .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #f44336);
}
.app.green .mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.green .mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.app.green .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.app.green .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.app.green .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #689f38;
}
.app.green .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.app.green .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #689f38;
}
.app.green .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.app.green .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.app.green .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.app.green .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #e0e0e0;
}
.app.green .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.app.green .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #e0e0e0;
}
.app.green .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.app.green .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.app.green .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.app.green .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #e0e0e0;
}
.app.green .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.app.green .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #e0e0e0;
}
.app.green .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.app.green .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.app.green .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.app.green .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f44336;
}
.app.green .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.app.green .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f44336;
}
.app.green .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.app.green .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.app.green .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.app.green .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.app.green .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.app.green .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}
.app.green .mat-app-background, .app.green.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-elevation-z0, .app.green .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z1, .app.green .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z2, .app.green .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z3, .app.green .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z4, .app.green .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z5, .app.green .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z6, .app.green .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z7, .app.green .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z8, .app.green .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z9, .app.green .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z10, .app.green .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z11, .app.green .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z12, .app.green .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z13, .app.green .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z14, .app.green .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z15, .app.green .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z16, .app.green .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z17, .app.green .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z18, .app.green .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z19, .app.green .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z20, .app.green .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z21, .app.green .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z22, .app.green .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z23, .app.green .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z24, .app.green .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.app.green .mat-mdc-card {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-color: #fff;
}
.app.green .mat-mdc-card-outlined {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-outline-color: #e0e0e0;
}
.app.green .mat-mdc-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.app.green .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #689f38;
}
.app.green .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(104, 159, 56, 0.25)'/%3E%3C/svg%3E");
}
.app.green .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(104, 159, 56, 0.25);
}
.app.green .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #e0e0e0;
}
.app.green .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(224, 224, 224, 0.25)'/%3E%3C/svg%3E");
}
.app.green .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(224, 224, 224, 0.25);
}
.app.green .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
}
.app.green .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(244, 67, 54, 0.25)'/%3E%3C/svg%3E");
}
.app.green .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(244, 67, 54, 0.25);
}
.app.green .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: white;
}
.app.green .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.app.green .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87);
}
@media all {
  .app.green .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
@media all {
  .app.green .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
.app.green .mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #689f38);
}
.app.green .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.app.green .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.app.green .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.app.green .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.app.green .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.app.green .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.app.green .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.app.green .mdc-text-field--filled .mdc-text-field__ripple::before,
.app.green .mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
.app.green .mdc-text-field--filled:hover .mdc-text-field__ripple::before, .app.green .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.app.green .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .app.green .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.app.green .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: whitesmoke;
}
.app.green .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.app.green .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.app.green .mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-primary, #689f38);
}
.app.green .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.app.green .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.app.green .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.green .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.app.green .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.app.green .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}
.app.green .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.app.green .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.app.green .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #689f38);
}
[dir=rtl] .app.green .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .app.green .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .app.green .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .app.green .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.app.green .mdc-text-field--outlined .mdc-text-field__ripple::before,
.app.green .mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, transparent);
}
[dir=rtl] .app.green .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .app.green .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.app.green .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(104, 159, 56, 0.87);
}
.app.green .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.app.green .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.app.green .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.app.green .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.app.green .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.app.green .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.app.green .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.app.green .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.app.green .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.app.green .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.app.green .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.app.green .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.app.green .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.app.green .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.app.green .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.app.green .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.app.green .mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.38);
}
@media all {
  .app.green .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
@media all {
  .app.green .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
.app.green .mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.green .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.app.green .mdc-text-field--disabled .mdc-text-field-character-counter,
.app.green .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.app.green .mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.app.green .mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.app.green .mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.app.green .mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.app.green .mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
.app.green .mdc-text-field--disabled .mdc-notched-outline__leading,
.app.green .mdc-text-field--disabled .mdc-notched-outline__notch,
.app.green .mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.green .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.green .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.green .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.green .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.green .mdc-text-field--disabled .mdc-text-field-character-counter,
  .app.green .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.green .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.green .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.green .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.green .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.green .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.green .mdc-text-field--disabled .mdc-notched-outline__leading,
  .app.green .mdc-text-field--disabled .mdc-notched-outline__notch,
  .app.green .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.app.green .mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #fafafa;
}
[dir=rtl] .app.green .mdc-text-field--end-aligned .mdc-text-field__input, .app.green .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .app.green .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker), .app.green .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.app.green .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #f44336);
}
.app.green .mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}
.app.green .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}
.app.green .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.green .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(104, 159, 56, 0.87);
}
.app.green .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(224, 224, 224, 0.87);
}
.app.green .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(244, 67, 54, 0.87);
}
.app.green .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, #e0e0e0);
}
.app.green .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-secondary, #e0e0e0);
}
.app.green .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(224, 224, 224, 0.87);
}
.app.green .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.app.green .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.app.green .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.app.green .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.app.green .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.app.green .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.app.green .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.app.green .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.app.green .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.app.green .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.app.green .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.app.green .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.app.green .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.app.green .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.app.green .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.app.green .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.app.green .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.app.green .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.app.green .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, #e0e0e0);
}
.app.green .mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.app.green .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.app.green .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(244, 67, 54, 0.87);
}
.app.green .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.app.green .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.app.green .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.app.green .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.app.green .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.app.green .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.app.green .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.app.green .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.app.green .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.app.green .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.app.green .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.app.green .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.app.green .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.app.green .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.app.green .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.app.green .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.app.green .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.app.green .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.app.green .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.app.green .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.app.green [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.app.green .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.app.green .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.green .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.app.green .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.app.green .mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.green .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.app.green .mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.green .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.app.green .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.app.green .mdc-list-item--disabled .mdc-list-item__start,
.app.green .mdc-list-item--disabled .mdc-list-item__content,
.app.green .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.app.green .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.green .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.green .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.green .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.app.green .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.app.green .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.app.green .mdc-list-item--selected .mdc-list-item__primary-text,
.app.green .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #689f38);
}
.app.green .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.green .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #689f38);
}
.app.green .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.green .mdc-list-divider::after {
  border-bottom-color: white;
}
.app.green .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.green .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-mdc-select-placeholder {
  color: rgba(0, 0, 0, 0.6);
}
.app.green .mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.app.green .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: rgba(104, 159, 56, 0.87);
}
.app.green .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: rgba(224, 224, 224, 0.87);
}
.app.green .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
  color: rgba(244, 67, 54, 0.87);
}
.app.green .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: rgba(244, 67, 54, 0.87);
}
.app.green .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.app.green .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.app.green .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.green .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.app.green .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.app.green .mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.green .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.app.green .mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.green .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.app.green .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.app.green .mdc-list-item--disabled .mdc-list-item__start,
.app.green .mdc-list-item--disabled .mdc-list-item__content,
.app.green .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.app.green .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.green .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.green .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.green .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.app.green .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.app.green .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.app.green .mdc-list-item--selected .mdc-list-item__primary-text,
.app.green .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #689f38);
}
.app.green .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.green .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #689f38);
}
.app.green .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.green .mdc-list-divider::after {
  border-bottom-color: white;
}
.app.green .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.green .mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-with-divider-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}
.app.green .mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
}
.app.green .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, .app.green .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #689f38;
  --mdc-chip-elevated-disabled-container-color: #689f38;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.app.green .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected, .app.green .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-disabled-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-disabled-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-selected-icon-color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected, .app.green .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.app.green .mat-mdc-chip-focus-overlay {
  background: black;
}
.app.green .mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-color: #fff;
}
.app.green .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.green .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #7cb342;
  --mdc-switch-selected-handle-color: #7cb342;
  --mdc-switch-selected-hover-state-layer-color: #7cb342;
  --mdc-switch-selected-pressed-state-layer-color: #7cb342;
  --mdc-switch-selected-focus-handle-color: #33691e;
  --mdc-switch-selected-hover-handle-color: #33691e;
  --mdc-switch-selected-pressed-handle-color: #33691e;
  --mdc-switch-selected-focus-track-color: #aed581;
  --mdc-switch-selected-hover-track-color: #aed581;
  --mdc-switch-selected-pressed-track-color: #aed581;
  --mdc-switch-selected-track-color: #aed581;
}
.app.green .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #757575;
  --mdc-switch-selected-handle-color: #757575;
  --mdc-switch-selected-hover-state-layer-color: #757575;
  --mdc-switch-selected-pressed-state-layer-color: #757575;
  --mdc-switch-selected-focus-handle-color: #212121;
  --mdc-switch-selected-hover-handle-color: #212121;
  --mdc-switch-selected-pressed-handle-color: #212121;
  --mdc-switch-selected-focus-track-color: #e0e0e0;
  --mdc-switch-selected-hover-track-color: #e0e0e0;
  --mdc-switch-selected-pressed-track-color: #e0e0e0;
  --mdc-switch-selected-track-color: #e0e0e0;
}
.app.green .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e53935;
  --mdc-switch-selected-handle-color: #e53935;
  --mdc-switch-selected-hover-state-layer-color: #e53935;
  --mdc-switch-selected-pressed-state-layer-color: #e53935;
  --mdc-switch-selected-focus-handle-color: #b71c1c;
  --mdc-switch-selected-hover-handle-color: #b71c1c;
  --mdc-switch-selected-pressed-handle-color: #b71c1c;
  --mdc-switch-selected-focus-track-color: #e57373;
  --mdc-switch-selected-hover-track-color: #e57373;
  --mdc-switch-selected-pressed-track-color: #e57373;
  --mdc-switch-selected-track-color: #e57373;
}
.app.green .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.green .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #689f38;
  --mdc-radio-selected-hover-icon-color: #689f38;
  --mdc-radio-selected-icon-color: #689f38;
  --mdc-radio-selected-pressed-icon-color: #689f38;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #689f38;
}
.app.green .mat-mdc-radio-button.mat-primary .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #e0e0e0;
  --mdc-radio-selected-hover-icon-color: #e0e0e0;
  --mdc-radio-selected-icon-color: #e0e0e0;
  --mdc-radio-selected-pressed-icon-color: #e0e0e0;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #e0e0e0;
}
.app.green .mat-mdc-radio-button.mat-accent .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #f44336;
}
.app.green .mat-mdc-radio-button.mat-warn .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.app.green .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #689f38;
  --mdc-slider-focus-handle-color: #689f38;
  --mdc-slider-hover-handle-color: #689f38;
  --mdc-slider-active-track-color: #689f38;
  --mdc-slider-inactive-track-color: #689f38;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #689f38;
  --mat-mdc-slider-ripple-color: #689f38;
  --mat-mdc-slider-hover-ripple-color: rgba(104, 159, 56, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(104, 159, 56, 0.2);
}
.app.green .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #e0e0e0;
  --mdc-slider-focus-handle-color: #e0e0e0;
  --mdc-slider-hover-handle-color: #e0e0e0;
  --mdc-slider-active-track-color: #e0e0e0;
  --mdc-slider-inactive-track-color: #e0e0e0;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #e0e0e0;
  --mat-mdc-slider-ripple-color: #e0e0e0;
  --mat-mdc-slider-hover-ripple-color: rgba(224, 224, 224, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(224, 224, 224, 0.2);
}
.app.green .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mat-mdc-slider-ripple-color: #f44336;
  --mat-mdc-slider-hover-ripple-color: rgba(244, 67, 54, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(244, 67, 54, 0.2);
}
.app.green .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.app.green .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.green .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.app.green .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.app.green .mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.green .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.app.green .mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.green .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.app.green .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.app.green .mdc-list-item--disabled .mdc-list-item__start,
.app.green .mdc-list-item--disabled .mdc-list-item__content,
.app.green .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.app.green .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.green .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.green .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.green .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.app.green .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.app.green .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.app.green .mdc-list-item--selected .mdc-list-item__primary-text,
.app.green .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #689f38);
}
.app.green .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.green .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #689f38);
}
.app.green .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.green .mdc-list-divider::after {
  border-bottom-color: white;
}
.app.green .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.green .mat-mdc-menu-item[disabled],
.app.green .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.app.green .mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38));
}
.app.green .mat-mdc-menu-item .mat-icon-no-color,
.app.green .mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.green .mat-mdc-menu-item:hover:not([disabled]),
.app.green .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.app.green .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.app.green .mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.app.green .mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}
.app.green .mat-mdc-list-option .mdc-list-item__start, .app.green .mat-mdc-list-option .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #689f38;
  --mdc-checkbox-selected-hover-icon-color: #689f38;
  --mdc-checkbox-selected-icon-color: #689f38;
  --mdc-checkbox-selected-pressed-icon-color: #689f38;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.app.green .mat-mdc-list-option .mdc-list-item__start, .app.green .mat-mdc-list-option .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #689f38;
  --mdc-radio-selected-hover-icon-color: #689f38;
  --mdc-radio-selected-icon-color: #689f38;
  --mdc-radio-selected-pressed-icon-color: #689f38;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #689f38;
}
.app.green .mat-mdc-list-option .mdc-list-item__start .mdc-radio--disabled + label, .app.green .mat-mdc-list-option .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-mdc-list-option.mat-accent .mdc-list-item__start, .app.green .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #e0e0e0;
  --mdc-checkbox-selected-hover-icon-color: #e0e0e0;
  --mdc-checkbox-selected-icon-color: #e0e0e0;
  --mdc-checkbox-selected-pressed-icon-color: #e0e0e0;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.app.green .mat-mdc-list-option.mat-accent .mdc-list-item__start, .app.green .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #e0e0e0;
  --mdc-radio-selected-hover-icon-color: #e0e0e0;
  --mdc-radio-selected-icon-color: #e0e0e0;
  --mdc-radio-selected-pressed-icon-color: #e0e0e0;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #e0e0e0;
}
.app.green .mat-mdc-list-option.mat-accent .mdc-list-item__start .mdc-radio--disabled + label, .app.green .mat-mdc-list-option.mat-accent .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-mdc-list-option.mat-warn .mdc-list-item__start, .app.green .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.app.green .mat-mdc-list-option.mat-warn .mdc-list-item__start, .app.green .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #f44336;
}
.app.green .mat-mdc-list-option.mat-warn .mdc-list-item__start .mdc-radio--disabled + label, .app.green .mat-mdc-list-option.mat-warn .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.app.green .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #689f38;
}
.app.green .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.green .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #689f38;
}
.app.green .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.app.green .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.app.green .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.app.green .mat-mdc-paginator {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.54);
}
.app.green .mat-mdc-paginator-decrement,
.app.green .mat-mdc-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.app.green .mat-mdc-paginator-first,
.app.green .mat-mdc-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.app.green .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.app.green .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.app.green .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.app.green .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.green .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.12);
}
.app.green .mat-mdc-tab, .app.green .mat-mdc-tab-link {
  background-color: transparent;
}
.app.green .mat-mdc-tab .mdc-tab__text-label, .app.green .mat-mdc-tab-link .mdc-tab__text-label {
  color: rgba(0, 0, 0, 0.6);
}
.app.green .mat-mdc-tab.mat-mdc-tab-disabled .mdc-tab__ripple::before,
.app.green .mat-mdc-tab.mat-mdc-tab-disabled .mat-ripple-element, .app.green .mat-mdc-tab-link.mat-mdc-tab-disabled .mdc-tab__ripple::before,
.app.green .mat-mdc-tab-link.mat-mdc-tab-disabled .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .app.green .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #689f38;
}
.app.green .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .app.green .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #689f38);
}
.app.green .mdc-tab__ripple::before,
.app.green .mat-mdc-tab .mat-ripple-element,
.app.green .mat-mdc-tab-header-pagination .mat-ripple-element,
.app.green .mat-mdc-tab-link .mat-ripple-element {
  background-color: #689f38;
}
.app.green .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .app.green .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .app.green .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .app.green .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #e0e0e0;
}
.app.green .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .app.green .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .app.green .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .app.green .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #e0e0e0);
}
.app.green .mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
.app.green .mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
.app.green .mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.app.green .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element, .app.green .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
.app.green .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
.app.green .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.app.green .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element {
  background-color: #e0e0e0;
}
.app.green .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .app.green .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .app.green .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .app.green .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #f44336;
}
.app.green .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .app.green .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .app.green .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .app.green .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #f44336);
}
.app.green .mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
.app.green .mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
.app.green .mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.app.green .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element, .app.green .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
.app.green .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
.app.green .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.app.green .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element {
  background-color: #f44336;
}
.app.green .mat-mdc-tab-group.mat-background-primary, .app.green .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-mdc-tab-header-with-background-background-color: #689f38;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.app.green .mat-mdc-tab-group.mat-background-accent, .app.green .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-mdc-tab-header-with-background-background-color: #e0e0e0;
  --mat-mdc-tab-header-with-background-foreground-color: #000;
}
.app.green .mat-mdc-tab-group.mat-background-warn, .app.green .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-mdc-tab-header-with-background-background-color: #f44336;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.app.green .mat-mdc-tab-header-pagination-chevron {
  border-color: var(--mdc-theme-on-surface, #000);
}
.app.green .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.green .mat-mdc-checkbox .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.green .mat-mdc-checkbox .mdc-checkbox__ripple {
  background: #000;
}
.app.green .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #689f38;
  --mdc-checkbox-selected-hover-icon-color: #689f38;
  --mdc-checkbox-selected-icon-color: #689f38;
  --mdc-checkbox-selected-pressed-icon-color: #689f38;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.app.green .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(104, 159, 56, 0.1);
}
.app.green .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #689f38;
}
.app.green .mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #e0e0e0;
  --mdc-checkbox-selected-hover-icon-color: #e0e0e0;
  --mdc-checkbox-selected-icon-color: #e0e0e0;
  --mdc-checkbox-selected-pressed-icon-color: #e0e0e0;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.app.green .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(224, 224, 224, 0.1);
}
.app.green .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #e0e0e0;
}
.app.green .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.app.green .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(244, 67, 54, 0.1);
}
.app.green .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #f44336;
}
.app.green .mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.app.green .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #689f38;
}
.app.green .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #e0e0e0;
}
.app.green .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
}
.app.green .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.app.green .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #689f38;
  --mdc-filled-button-label-text-color: #fff;
}
.app.green .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #e0e0e0;
  --mdc-filled-button-label-text-color: #000;
}
.app.green .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: #fff;
}
.app.green .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.app.green .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #689f38;
  --mdc-protected-button-label-text-color: #fff;
}
.app.green .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #e0e0e0;
  --mdc-protected-button-label-text-color: #000;
}
.app.green .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: #fff;
}
.app.green .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}
.app.green .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.app.green .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.app.green .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #689f38;
}
.app.green .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #e0e0e0;
}
.app.green .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
}
.app.green .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}
.app.green .mat-mdc-button, .app.green .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.green .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .app.green .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.green .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.green .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .app.green .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.green .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.green .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .app.green .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.green .mat-mdc-button.mat-primary, .app.green .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #689f38;
  --mat-mdc-button-ripple-color: rgba(104, 159, 56, 0.1);
}
.app.green .mat-mdc-button.mat-accent, .app.green .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #e0e0e0;
  --mat-mdc-button-ripple-color: rgba(224, 224, 224, 0.1);
}
.app.green .mat-mdc-button.mat-warn, .app.green .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.app.green .mat-mdc-raised-button, .app.green .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.green .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .app.green .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.green .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.green .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .app.green .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.green .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.green .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .app.green .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.green .mat-mdc-raised-button.mat-primary, .app.green .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.green .mat-mdc-raised-button.mat-accent, .app.green .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.green .mat-mdc-raised-button.mat-warn, .app.green .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.green .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.green .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.green .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.green .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.green .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.green .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #689f38;
  --mat-mdc-button-ripple-color: rgba(104, 159, 56, 0.1);
}
.app.green .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #e0e0e0;
  --mat-mdc-button-ripple-color: rgba(224, 224, 224, 0.1);
}
.app.green .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.app.green .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #689f38;
}
.app.green .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #e0e0e0;
}
.app.green .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
}
.app.green .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-mdc-fab, .app.green .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.green .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before, .app.green .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.green .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.green .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .app.green .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.green .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.green .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before, .app.green .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.green .mat-mdc-fab.mat-primary, .app.green .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.green .mat-mdc-fab.mat-accent, .app.green .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.green .mat-mdc-fab.mat-warn, .app.green .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.green .mat-mdc-fab.mat-unthemed, .app.green .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #fff;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.app.green .mat-mdc-fab.mat-primary, .app.green .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #689f38;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.app.green .mat-mdc-fab.mat-accent, .app.green .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #e0e0e0;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.app.green .mat-mdc-fab.mat-warn, .app.green .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.app.green .mat-mdc-fab[disabled][disabled], .app.green .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: #e0e0e0;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
}
.app.green .mdc-data-table {
  background-color: var(--mdc-theme-surface, #fff);
  border-color: rgba(0, 0, 0, 0.12);
}
.app.green .mdc-data-table__row {
  background-color: inherit;
}
.app.green .mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, #fff);
}
.app.green .mdc-data-table__row--selected {
  background-color: rgba(104, 159, 56, 0.04);
}
.app.green .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.app.green .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.app.green .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.green .mdc-data-table__cell,
.app.green .mdc-data-table__header-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.app.green .mdc-data-table__pagination {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.green .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.green .mdc-data-table__header-cell {
  color: rgba(0, 0, 0, 0.87);
}
.app.green .mdc-data-table__pagination-total,
.app.green .mdc-data-table__pagination-rows-per-page-label,
.app.green .mdc-data-table__cell {
  color: rgba(0, 0, 0, 0.87);
}
[dir=rtl] .app.green .mdc-data-table__pagination-button .mdc-button__icon, .app.green .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.app.green .mat-mdc-table {
  background: white;
}
.app.green .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #689f38;
}
.app.green .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #e0e0e0;
}
.app.green .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}
.app.green .mat-badge {
  position: relative;
}
.app.green .mat-badge.mat-badge {
  overflow: visible;
}
.app.green .mat-badge-hidden .mat-badge-content {
  display: none;
}
.app.green .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.app.green .ng-animate-disabled .mat-badge-content,
.app.green .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.app.green .mat-badge-content.mat-badge-active {
  transform: none;
}
.app.green .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.app.green .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.app.green .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.app.green .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .app.green .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.app.green .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .app.green .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.app.green .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .app.green .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.app.green .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .app.green .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.app.green .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.app.green .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.app.green .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.app.green .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .app.green .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.app.green .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .app.green .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.app.green .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .app.green .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.app.green .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .app.green .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.app.green .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.app.green .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.app.green .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.app.green .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .app.green .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.app.green .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .app.green .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.app.green .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .app.green .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.app.green .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .app.green .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.app.green .mat-badge-content {
  color: white;
  background: #689f38;
}
.cdk-high-contrast-active .app.green .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.app.green .mat-badge-accent .mat-badge-content {
  background: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
.app.green .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.app.green .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.green .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.app.green .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.app.green .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.green .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.app.green .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.app.green .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.app.green [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.app.green .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.app.green .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.app.green .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.app.green .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.app.green .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.app.green .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app.green .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.app.green .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.app.green .mat-datepicker-toggle,
.app.green .mat-datepicker-content .mat-calendar-next-button,
.app.green .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.app.green .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.app.green .mat-calendar-table-header,
.app.green .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.green .mat-calendar-body-cell-content,
.app.green .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.app.green .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.app.green .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.app.green .mat-calendar-body-in-range::before {
  background: rgba(104, 159, 56, 0.2);
}
.app.green .mat-calendar-body-comparison-identical,
.app.green .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.green .mat-calendar-body-comparison-bridge-start::before,
.app.green [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(104, 159, 56, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.green .mat-calendar-body-comparison-bridge-end::before,
.app.green [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(104, 159, 56, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.green .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.green .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.green .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.green .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.green .mat-calendar-body-selected {
  background-color: #689f38;
  color: white;
}
.app.green .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(104, 159, 56, 0.4);
}
.app.green .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.green .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.green .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(104, 159, 56, 0.3);
}
@media (hover: hover) {
  .app.green .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(104, 159, 56, 0.3);
  }
}
.app.green .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(224, 224, 224, 0.2);
}
.app.green .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.app.green .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.green .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.app.green .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(224, 224, 224, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.green .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.app.green .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(224, 224, 224, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.green .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.green .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.green .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.green .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.green .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(224, 224, 224, 0.4);
}
.app.green .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.app.green .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.green .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(224, 224, 224, 0.3);
}
@media (hover: hover) {
  .app.green .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(224, 224, 224, 0.3);
  }
}
.app.green .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.app.green .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.app.green .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.green .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.app.green .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.green .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.app.green .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.green .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.green .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.green .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.green .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.green .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.app.green .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.app.green .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.green .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.green .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .app.green .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}
.app.green .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.app.green .mat-datepicker-toggle-active {
  color: #689f38;
}
.app.green .mat-datepicker-toggle-active.mat-accent {
  color: #e0e0e0;
}
.app.green .mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
.app.green .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.green .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.app.green .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.green .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.green .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .app.green .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .app.green .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .app.green .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.app.green .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-expansion-panel-header-description,
.app.green .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.green .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.app.green .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.app.green .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.app.green .mat-icon.mat-primary {
  color: #689f38;
}
.app.green .mat-icon.mat-accent {
  color: #e0e0e0;
}
.app.green .mat-icon.mat-warn {
  color: #f44336;
}
.app.green .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-drawer.mat-drawer-push {
  background-color: white;
}
.app.green .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.green .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.green .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.green [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.green [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.green .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.app.green .mat-step-header.cdk-keyboard-focused, .app.green .mat-step-header.cdk-program-focused, .app.green .mat-step-header:hover:not([aria-disabled]), .app.green .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.green .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .app.green .mat-step-header:hover {
    background: none;
  }
}
.app.green .mat-step-header .mat-step-label,
.app.green .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.app.green .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.app.green .mat-step-header .mat-step-icon-selected,
.app.green .mat-step-header .mat-step-icon-state-done,
.app.green .mat-step-header .mat-step-icon-state-edit {
  background-color: #689f38;
  color: white;
}
.app.green .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-step-header.mat-accent .mat-step-icon-selected,
.app.green .mat-step-header.mat-accent .mat-step-icon-state-done,
.app.green .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.app.green .mat-step-header.mat-warn .mat-step-icon-selected,
.app.green .mat-step-header.mat-warn .mat-step-icon-state-done,
.app.green .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.app.green .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.app.green .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
.app.green .mat-stepper-horizontal, .app.green .mat-stepper-vertical {
  background-color: white;
}
.app.green .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.app.green .mat-horizontal-stepper-header::before,
.app.green .mat-horizontal-stepper-header::after,
.app.green .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.green .mat-sort-header-arrow {
  color: #757575;
}
.app.green .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-toolbar.mat-primary {
  background: #689f38;
  color: white;
}
.app.green .mat-toolbar.mat-accent {
  background: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.app.green .mat-toolbar .mat-form-field-underline,
.app.green .mat-toolbar .mat-form-field-ripple,
.app.green .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.app.green .mat-toolbar .mat-form-field-label,
.app.green .mat-toolbar .mat-focused .mat-form-field-label,
.app.green .mat-toolbar .mat-select-value,
.app.green .mat-toolbar .mat-select-arrow,
.app.green .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.app.green .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.app.green .mat-tree {
  background: white;
}
.app.green .mat-tree-node,
.app.green .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.app.green .bg-primary {
  background: black;
  color: white;
}
.app.green .bg-accent {
  background: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.green .bg-warn {
  background: #f44336;
  color: white;
}
.app.green .text-muted {
  color: rgba(0, 0, 0, 0.54) !important;
}
.app.green .secondary-color {
  color: #dcedc8;
}
.app.green .primary-color {
  color: #689f38;
}
.app.green .top-navbar.mat-toolbar,
.app.green .app-dropdown .mat-mdc-menu-item,
.app.green .app-dropdown .user-info,
.app.green .account-sidenav .mat-nav-list .mat-mdc-list-item,
.app.green .product-item .title,
.app.green .info-bar .mat-mdc-card .content p,
.app.green .filter-sidenav .mat-expansion-panel-header-title,
.app.green .mat-tab-body-content,
.app.green .account-card-title {
  color: #616161 !important;
}
.app.green .top-toolbar.mat-toolbar-row {
  border-bottom: 1px solid rgba(241, 248, 233, 0.2);
  color: #dcedc8;
}
.app.green .top-toolbar.mat-toolbar-row .top-menu a,
.app.green .top-toolbar.mat-toolbar-row .sidenav-toggle {
  color: #dcedc8;
}
.app.green .top-navbar {
  background: #fafafa;
}
.app.green .border-bottom-mute {
  border-bottom: 1px solid rgba(241, 248, 233, 0.2);
}
.app.green .search-dropdown.mat-mdc-menu-panel {
  background: transparent;
}
.app.green .mat-mdc-snack-bar-container.success .mdc-snackbar__surface {
  background: #388E3C;
}
.app.green .mat-mdc-snack-bar-container.error .mdc-snackbar__surface {
  background: #E53935;
}
.app.green .new-price {
  color: #f44336;
}
.app.green .primary-text {
  color: #689f38;
}
.app.green .active-link {
  background-color: black;
  color: #fff;
}
.app.green .horizontal-active-link,
.app.green .app-dropdown .mat-mdc-menu-item.horizontal-active-link {
  color: #689f38 !important;
}
.app.green .filter-brands button.selected,
.app.green .filter-buttons button.selected {
  box-shadow: 0px 0px 1px 2px #689f38;
}
.app.green .product-pagination .ngx-pagination {
  margin-bottom: 0;
  padding: 14px 10px;
}
.app.green .product-pagination .ngx-pagination .current {
  background: black;
  color: white;
}
.app.green .product-pagination .ngx-pagination a:hover, .app.green .product-pagination .ngx-pagination button:hover {
  background: rgb(165, 165, 165);
  color: black;
}
.app.green .breadcrumb a {
  color: #6b6b6b;
}
.app.green .breadcrumb .breadcrumb-item + .breadcrumb-item:before {
  color: rgba(0, 0, 0, 0.87);
}
.app.green .user-block {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.app.green .menu-item .mat-mdc-button:not(.active-link) .menu-icon {
  color: black;
}
.app.green .messages .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label {
  color: rgba(255, 255, 255, 0.6);
}
.app.green .messages .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: white;
}
.app.blue {
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  /* ngx-pagination */
}
.app.blue .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.blue .mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.blue .mat-mdc-option:hover:not(.mdc-list-item--disabled), .app.blue .mat-mdc-option:focus:not(.mdc-list-item--disabled), .app.blue .mat-mdc-option.mat-mdc-option-active, .app.blue .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app.blue .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #1976d2);
}
.app.blue .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #e0e0e0);
}
.app.blue .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #ff5252);
}
.app.blue .mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.blue .mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.app.blue .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.app.blue .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.app.blue .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #1976d2;
}
.app.blue .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.app.blue .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #1976d2;
}
.app.blue .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.app.blue .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.app.blue .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.app.blue .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #e0e0e0;
}
.app.blue .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.app.blue .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #e0e0e0;
}
.app.blue .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.app.blue .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.app.blue .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.app.blue .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #e0e0e0;
}
.app.blue .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.app.blue .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #e0e0e0;
}
.app.blue .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.app.blue .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.app.blue .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.app.blue .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ff5252;
}
.app.blue .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.app.blue .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ff5252;
}
.app.blue .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.app.blue .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.app.blue .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.app.blue .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.app.blue .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.app.blue .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}
.app.blue .mat-app-background, .app.blue.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-elevation-z0, .app.blue .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z1, .app.blue .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z2, .app.blue .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z3, .app.blue .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z4, .app.blue .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z5, .app.blue .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z6, .app.blue .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z7, .app.blue .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z8, .app.blue .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z9, .app.blue .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z10, .app.blue .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z11, .app.blue .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z12, .app.blue .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z13, .app.blue .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z14, .app.blue .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z15, .app.blue .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z16, .app.blue .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z17, .app.blue .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z18, .app.blue .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z19, .app.blue .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z20, .app.blue .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z21, .app.blue .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z22, .app.blue .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z23, .app.blue .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z24, .app.blue .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.app.blue .mat-mdc-card {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-color: #fff;
}
.app.blue .mat-mdc-card-outlined {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-outline-color: #e0e0e0;
}
.app.blue .mat-mdc-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.app.blue .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #1976d2;
}
.app.blue .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(25, 118, 210, 0.25)'/%3E%3C/svg%3E");
}
.app.blue .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(25, 118, 210, 0.25);
}
.app.blue .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #e0e0e0;
}
.app.blue .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(224, 224, 224, 0.25)'/%3E%3C/svg%3E");
}
.app.blue .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(224, 224, 224, 0.25);
}
.app.blue .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #ff5252;
}
.app.blue .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(255, 82, 82, 0.25)'/%3E%3C/svg%3E");
}
.app.blue .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(255, 82, 82, 0.25);
}
.app.blue .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: white;
}
.app.blue .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.app.blue .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87);
}
@media all {
  .app.blue .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
@media all {
  .app.blue .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
.app.blue .mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #1976d2);
}
.app.blue .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.app.blue .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.app.blue .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.app.blue .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.app.blue .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.app.blue .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.app.blue .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.app.blue .mdc-text-field--filled .mdc-text-field__ripple::before,
.app.blue .mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
.app.blue .mdc-text-field--filled:hover .mdc-text-field__ripple::before, .app.blue .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.app.blue .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .app.blue .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.app.blue .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: whitesmoke;
}
.app.blue .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.app.blue .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.app.blue .mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-primary, #1976d2);
}
.app.blue .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.app.blue .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.app.blue .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.blue .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.app.blue .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.app.blue .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}
.app.blue .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.app.blue .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.app.blue .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #1976d2);
}
[dir=rtl] .app.blue .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .app.blue .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .app.blue .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .app.blue .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.app.blue .mdc-text-field--outlined .mdc-text-field__ripple::before,
.app.blue .mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, transparent);
}
[dir=rtl] .app.blue .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .app.blue .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.app.blue .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(25, 118, 210, 0.87);
}
.app.blue .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #ff5252);
}
.app.blue .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #ff5252);
}
.app.blue .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #ff5252);
}
.app.blue .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #ff5252);
}
.app.blue .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #ff5252);
}
.app.blue .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #ff5252);
}
.app.blue .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #ff5252);
}
.app.blue .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.app.blue .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.app.blue .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ff5252);
}
.app.blue .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.app.blue .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.app.blue .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ff5252);
}
.app.blue .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.app.blue .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.app.blue .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ff5252);
}
.app.blue .mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.38);
}
@media all {
  .app.blue .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
@media all {
  .app.blue .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
.app.blue .mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.blue .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.app.blue .mdc-text-field--disabled .mdc-text-field-character-counter,
.app.blue .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.app.blue .mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.app.blue .mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.app.blue .mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.app.blue .mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.app.blue .mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
.app.blue .mdc-text-field--disabled .mdc-notched-outline__leading,
.app.blue .mdc-text-field--disabled .mdc-notched-outline__notch,
.app.blue .mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.blue .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.blue .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.blue .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.blue .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.blue .mdc-text-field--disabled .mdc-text-field-character-counter,
  .app.blue .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.blue .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.blue .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.blue .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.blue .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.blue .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.blue .mdc-text-field--disabled .mdc-notched-outline__leading,
  .app.blue .mdc-text-field--disabled .mdc-notched-outline__notch,
  .app.blue .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.app.blue .mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #fafafa;
}
[dir=rtl] .app.blue .mdc-text-field--end-aligned .mdc-text-field__input, .app.blue .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .app.blue .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker), .app.blue .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.app.blue .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #ff5252);
}
.app.blue .mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}
.app.blue .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}
.app.blue .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.blue .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(25, 118, 210, 0.87);
}
.app.blue .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(224, 224, 224, 0.87);
}
.app.blue .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(255, 82, 82, 0.87);
}
.app.blue .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, #e0e0e0);
}
.app.blue .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-secondary, #e0e0e0);
}
.app.blue .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(224, 224, 224, 0.87);
}
.app.blue .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #ff5252);
}
.app.blue .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #ff5252);
}
.app.blue .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #ff5252);
}
.app.blue .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #ff5252);
}
.app.blue .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #ff5252);
}
.app.blue .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #ff5252);
}
.app.blue .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #ff5252);
}
.app.blue .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.app.blue .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.app.blue .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ff5252);
}
.app.blue .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.app.blue .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.app.blue .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ff5252);
}
.app.blue .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.app.blue .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.app.blue .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ff5252);
}
.app.blue .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.app.blue .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.app.blue .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, #e0e0e0);
}
.app.blue .mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #ff5252);
}
.app.blue .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #ff5252);
}
.app.blue .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(255, 82, 82, 0.87);
}
.app.blue .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #ff5252);
}
.app.blue .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #ff5252);
}
.app.blue .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #ff5252);
}
.app.blue .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #ff5252);
}
.app.blue .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #ff5252);
}
.app.blue .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #ff5252);
}
.app.blue .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #ff5252);
}
.app.blue .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.app.blue .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.app.blue .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ff5252);
}
.app.blue .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.app.blue .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.app.blue .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ff5252);
}
.app.blue .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.app.blue .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.app.blue .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ff5252);
}
.app.blue .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.app.blue .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.app.blue .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ff5252);
}
.app.blue .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.app.blue [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.app.blue .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.app.blue .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.blue .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.app.blue .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.app.blue .mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.blue .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.app.blue .mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.blue .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.app.blue .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.app.blue .mdc-list-item--disabled .mdc-list-item__start,
.app.blue .mdc-list-item--disabled .mdc-list-item__content,
.app.blue .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.app.blue .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.blue .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.blue .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.blue .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.app.blue .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.app.blue .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.app.blue .mdc-list-item--selected .mdc-list-item__primary-text,
.app.blue .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #1976d2);
}
.app.blue .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.blue .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #1976d2);
}
.app.blue .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.blue .mdc-list-divider::after {
  border-bottom-color: white;
}
.app.blue .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.blue .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-mdc-select-placeholder {
  color: rgba(0, 0, 0, 0.6);
}
.app.blue .mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.app.blue .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: rgba(25, 118, 210, 0.87);
}
.app.blue .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: rgba(224, 224, 224, 0.87);
}
.app.blue .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
  color: rgba(255, 82, 82, 0.87);
}
.app.blue .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: rgba(255, 82, 82, 0.87);
}
.app.blue .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.app.blue .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.app.blue .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.blue .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.app.blue .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.app.blue .mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.blue .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.app.blue .mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.blue .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.app.blue .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.app.blue .mdc-list-item--disabled .mdc-list-item__start,
.app.blue .mdc-list-item--disabled .mdc-list-item__content,
.app.blue .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.app.blue .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.blue .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.blue .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.blue .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.app.blue .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.app.blue .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.app.blue .mdc-list-item--selected .mdc-list-item__primary-text,
.app.blue .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #1976d2);
}
.app.blue .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.blue .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #1976d2);
}
.app.blue .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.blue .mdc-list-divider::after {
  border-bottom-color: white;
}
.app.blue .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.blue .mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-with-divider-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}
.app.blue .mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
}
.app.blue .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, .app.blue .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #1976d2;
  --mdc-chip-elevated-disabled-container-color: #1976d2;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.app.blue .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected, .app.blue .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-disabled-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-disabled-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-selected-icon-color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected, .app.blue .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #ff5252;
  --mdc-chip-elevated-disabled-container-color: #ff5252;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.app.blue .mat-mdc-chip-focus-overlay {
  background: black;
}
.app.blue .mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-color: #fff;
}
.app.blue .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.blue .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #1e88e5;
  --mdc-switch-selected-handle-color: #1e88e5;
  --mdc-switch-selected-hover-state-layer-color: #1e88e5;
  --mdc-switch-selected-pressed-state-layer-color: #1e88e5;
  --mdc-switch-selected-focus-handle-color: #0d47a1;
  --mdc-switch-selected-hover-handle-color: #0d47a1;
  --mdc-switch-selected-pressed-handle-color: #0d47a1;
  --mdc-switch-selected-focus-track-color: #64b5f6;
  --mdc-switch-selected-hover-track-color: #64b5f6;
  --mdc-switch-selected-pressed-track-color: #64b5f6;
  --mdc-switch-selected-track-color: #64b5f6;
}
.app.blue .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #757575;
  --mdc-switch-selected-handle-color: #757575;
  --mdc-switch-selected-hover-state-layer-color: #757575;
  --mdc-switch-selected-pressed-state-layer-color: #757575;
  --mdc-switch-selected-focus-handle-color: #212121;
  --mdc-switch-selected-hover-handle-color: #212121;
  --mdc-switch-selected-pressed-handle-color: #212121;
  --mdc-switch-selected-focus-track-color: #e0e0e0;
  --mdc-switch-selected-hover-track-color: #e0e0e0;
  --mdc-switch-selected-pressed-track-color: #e0e0e0;
  --mdc-switch-selected-track-color: #e0e0e0;
}
.app.blue .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e53935;
  --mdc-switch-selected-handle-color: #e53935;
  --mdc-switch-selected-hover-state-layer-color: #e53935;
  --mdc-switch-selected-pressed-state-layer-color: #e53935;
  --mdc-switch-selected-focus-handle-color: #b71c1c;
  --mdc-switch-selected-hover-handle-color: #b71c1c;
  --mdc-switch-selected-pressed-handle-color: #b71c1c;
  --mdc-switch-selected-focus-track-color: #e57373;
  --mdc-switch-selected-hover-track-color: #e57373;
  --mdc-switch-selected-pressed-track-color: #e57373;
  --mdc-switch-selected-track-color: #e57373;
}
.app.blue .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.blue .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #1976d2;
  --mdc-radio-selected-hover-icon-color: #1976d2;
  --mdc-radio-selected-icon-color: #1976d2;
  --mdc-radio-selected-pressed-icon-color: #1976d2;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #1976d2;
}
.app.blue .mat-mdc-radio-button.mat-primary .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #e0e0e0;
  --mdc-radio-selected-hover-icon-color: #e0e0e0;
  --mdc-radio-selected-icon-color: #e0e0e0;
  --mdc-radio-selected-pressed-icon-color: #e0e0e0;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #e0e0e0;
}
.app.blue .mat-mdc-radio-button.mat-accent .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ff5252;
  --mdc-radio-selected-hover-icon-color: #ff5252;
  --mdc-radio-selected-icon-color: #ff5252;
  --mdc-radio-selected-pressed-icon-color: #ff5252;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #ff5252;
}
.app.blue .mat-mdc-radio-button.mat-warn .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.app.blue .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #1976d2;
  --mdc-slider-focus-handle-color: #1976d2;
  --mdc-slider-hover-handle-color: #1976d2;
  --mdc-slider-active-track-color: #1976d2;
  --mdc-slider-inactive-track-color: #1976d2;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #1976d2;
  --mat-mdc-slider-ripple-color: #1976d2;
  --mat-mdc-slider-hover-ripple-color: rgba(25, 118, 210, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(25, 118, 210, 0.2);
}
.app.blue .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #e0e0e0;
  --mdc-slider-focus-handle-color: #e0e0e0;
  --mdc-slider-hover-handle-color: #e0e0e0;
  --mdc-slider-active-track-color: #e0e0e0;
  --mdc-slider-inactive-track-color: #e0e0e0;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #e0e0e0;
  --mat-mdc-slider-ripple-color: #e0e0e0;
  --mat-mdc-slider-hover-ripple-color: rgba(224, 224, 224, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(224, 224, 224, 0.2);
}
.app.blue .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #ff5252;
  --mdc-slider-focus-handle-color: #ff5252;
  --mdc-slider-hover-handle-color: #ff5252;
  --mdc-slider-active-track-color: #ff5252;
  --mdc-slider-inactive-track-color: #ff5252;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #ff5252;
  --mat-mdc-slider-ripple-color: #ff5252;
  --mat-mdc-slider-hover-ripple-color: rgba(255, 82, 82, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(255, 82, 82, 0.2);
}
.app.blue .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.app.blue .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.blue .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.app.blue .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.app.blue .mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.blue .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.app.blue .mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.blue .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.app.blue .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.app.blue .mdc-list-item--disabled .mdc-list-item__start,
.app.blue .mdc-list-item--disabled .mdc-list-item__content,
.app.blue .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.app.blue .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.blue .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.blue .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.blue .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.app.blue .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.app.blue .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.app.blue .mdc-list-item--selected .mdc-list-item__primary-text,
.app.blue .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #1976d2);
}
.app.blue .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.blue .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #1976d2);
}
.app.blue .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.blue .mdc-list-divider::after {
  border-bottom-color: white;
}
.app.blue .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.blue .mat-mdc-menu-item[disabled],
.app.blue .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.app.blue .mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38));
}
.app.blue .mat-mdc-menu-item .mat-icon-no-color,
.app.blue .mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.blue .mat-mdc-menu-item:hover:not([disabled]),
.app.blue .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.app.blue .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.app.blue .mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.app.blue .mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}
.app.blue .mat-mdc-list-option .mdc-list-item__start, .app.blue .mat-mdc-list-option .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #1976d2;
  --mdc-checkbox-selected-hover-icon-color: #1976d2;
  --mdc-checkbox-selected-icon-color: #1976d2;
  --mdc-checkbox-selected-pressed-icon-color: #1976d2;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.app.blue .mat-mdc-list-option .mdc-list-item__start, .app.blue .mat-mdc-list-option .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #1976d2;
  --mdc-radio-selected-hover-icon-color: #1976d2;
  --mdc-radio-selected-icon-color: #1976d2;
  --mdc-radio-selected-pressed-icon-color: #1976d2;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #1976d2;
}
.app.blue .mat-mdc-list-option .mdc-list-item__start .mdc-radio--disabled + label, .app.blue .mat-mdc-list-option .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-mdc-list-option.mat-accent .mdc-list-item__start, .app.blue .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #e0e0e0;
  --mdc-checkbox-selected-hover-icon-color: #e0e0e0;
  --mdc-checkbox-selected-icon-color: #e0e0e0;
  --mdc-checkbox-selected-pressed-icon-color: #e0e0e0;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.app.blue .mat-mdc-list-option.mat-accent .mdc-list-item__start, .app.blue .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #e0e0e0;
  --mdc-radio-selected-hover-icon-color: #e0e0e0;
  --mdc-radio-selected-icon-color: #e0e0e0;
  --mdc-radio-selected-pressed-icon-color: #e0e0e0;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #e0e0e0;
}
.app.blue .mat-mdc-list-option.mat-accent .mdc-list-item__start .mdc-radio--disabled + label, .app.blue .mat-mdc-list-option.mat-accent .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-mdc-list-option.mat-warn .mdc-list-item__start, .app.blue .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ff5252;
  --mdc-checkbox-selected-hover-icon-color: #ff5252;
  --mdc-checkbox-selected-icon-color: #ff5252;
  --mdc-checkbox-selected-pressed-icon-color: #ff5252;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.app.blue .mat-mdc-list-option.mat-warn .mdc-list-item__start, .app.blue .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ff5252;
  --mdc-radio-selected-hover-icon-color: #ff5252;
  --mdc-radio-selected-icon-color: #ff5252;
  --mdc-radio-selected-pressed-icon-color: #ff5252;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #ff5252;
}
.app.blue .mat-mdc-list-option.mat-warn .mdc-list-item__start .mdc-radio--disabled + label, .app.blue .mat-mdc-list-option.mat-warn .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.app.blue .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #1976d2;
}
.app.blue .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.blue .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #1976d2;
}
.app.blue .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.app.blue .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.app.blue .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.app.blue .mat-mdc-paginator {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.54);
}
.app.blue .mat-mdc-paginator-decrement,
.app.blue .mat-mdc-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.app.blue .mat-mdc-paginator-first,
.app.blue .mat-mdc-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.app.blue .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.app.blue .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.app.blue .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.app.blue .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.blue .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.12);
}
.app.blue .mat-mdc-tab, .app.blue .mat-mdc-tab-link {
  background-color: transparent;
}
.app.blue .mat-mdc-tab .mdc-tab__text-label, .app.blue .mat-mdc-tab-link .mdc-tab__text-label {
  color: rgba(0, 0, 0, 0.6);
}
.app.blue .mat-mdc-tab.mat-mdc-tab-disabled .mdc-tab__ripple::before,
.app.blue .mat-mdc-tab.mat-mdc-tab-disabled .mat-ripple-element, .app.blue .mat-mdc-tab-link.mat-mdc-tab-disabled .mdc-tab__ripple::before,
.app.blue .mat-mdc-tab-link.mat-mdc-tab-disabled .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .app.blue .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #1976d2;
}
.app.blue .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .app.blue .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #1976d2);
}
.app.blue .mdc-tab__ripple::before,
.app.blue .mat-mdc-tab .mat-ripple-element,
.app.blue .mat-mdc-tab-header-pagination .mat-ripple-element,
.app.blue .mat-mdc-tab-link .mat-ripple-element {
  background-color: #1976d2;
}
.app.blue .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .app.blue .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .app.blue .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .app.blue .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #e0e0e0;
}
.app.blue .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .app.blue .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .app.blue .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .app.blue .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #e0e0e0);
}
.app.blue .mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
.app.blue .mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
.app.blue .mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.app.blue .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element, .app.blue .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
.app.blue .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
.app.blue .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.app.blue .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element {
  background-color: #e0e0e0;
}
.app.blue .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .app.blue .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .app.blue .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .app.blue .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #ff5252;
}
.app.blue .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .app.blue .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .app.blue .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .app.blue .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #ff5252);
}
.app.blue .mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
.app.blue .mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
.app.blue .mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.app.blue .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element, .app.blue .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
.app.blue .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
.app.blue .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.app.blue .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element {
  background-color: #ff5252;
}
.app.blue .mat-mdc-tab-group.mat-background-primary, .app.blue .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-mdc-tab-header-with-background-background-color: #1976d2;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.app.blue .mat-mdc-tab-group.mat-background-accent, .app.blue .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-mdc-tab-header-with-background-background-color: #e0e0e0;
  --mat-mdc-tab-header-with-background-foreground-color: #000;
}
.app.blue .mat-mdc-tab-group.mat-background-warn, .app.blue .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-mdc-tab-header-with-background-background-color: #ff5252;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.app.blue .mat-mdc-tab-header-pagination-chevron {
  border-color: var(--mdc-theme-on-surface, #000);
}
.app.blue .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.blue .mat-mdc-checkbox .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.blue .mat-mdc-checkbox .mdc-checkbox__ripple {
  background: #000;
}
.app.blue .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #1976d2;
  --mdc-checkbox-selected-hover-icon-color: #1976d2;
  --mdc-checkbox-selected-icon-color: #1976d2;
  --mdc-checkbox-selected-pressed-icon-color: #1976d2;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.app.blue .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(25, 118, 210, 0.1);
}
.app.blue .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #1976d2;
}
.app.blue .mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #e0e0e0;
  --mdc-checkbox-selected-hover-icon-color: #e0e0e0;
  --mdc-checkbox-selected-icon-color: #e0e0e0;
  --mdc-checkbox-selected-pressed-icon-color: #e0e0e0;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.app.blue .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(224, 224, 224, 0.1);
}
.app.blue .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #e0e0e0;
}
.app.blue .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ff5252;
  --mdc-checkbox-selected-hover-icon-color: #ff5252;
  --mdc-checkbox-selected-icon-color: #ff5252;
  --mdc-checkbox-selected-pressed-icon-color: #ff5252;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.app.blue .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(255, 82, 82, 0.1);
}
.app.blue .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #ff5252;
}
.app.blue .mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.app.blue .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #1976d2;
}
.app.blue .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #e0e0e0;
}
.app.blue .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #ff5252;
}
.app.blue .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.app.blue .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #1976d2;
  --mdc-filled-button-label-text-color: #fff;
}
.app.blue .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #e0e0e0;
  --mdc-filled-button-label-text-color: #000;
}
.app.blue .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #ff5252;
  --mdc-filled-button-label-text-color: #fff;
}
.app.blue .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.app.blue .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #1976d2;
  --mdc-protected-button-label-text-color: #fff;
}
.app.blue .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #e0e0e0;
  --mdc-protected-button-label-text-color: #000;
}
.app.blue .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #ff5252;
  --mdc-protected-button-label-text-color: #fff;
}
.app.blue .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}
.app.blue .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.app.blue .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.app.blue .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #1976d2;
}
.app.blue .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #e0e0e0;
}
.app.blue .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #ff5252;
}
.app.blue .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}
.app.blue .mat-mdc-button, .app.blue .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.blue .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .app.blue .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.blue .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.blue .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .app.blue .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.blue .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.blue .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .app.blue .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.blue .mat-mdc-button.mat-primary, .app.blue .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #1976d2;
  --mat-mdc-button-ripple-color: rgba(25, 118, 210, 0.1);
}
.app.blue .mat-mdc-button.mat-accent, .app.blue .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #e0e0e0;
  --mat-mdc-button-ripple-color: rgba(224, 224, 224, 0.1);
}
.app.blue .mat-mdc-button.mat-warn, .app.blue .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #ff5252;
  --mat-mdc-button-ripple-color: rgba(255, 82, 82, 0.1);
}
.app.blue .mat-mdc-raised-button, .app.blue .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.blue .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .app.blue .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.blue .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.blue .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .app.blue .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.blue .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.blue .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .app.blue .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.blue .mat-mdc-raised-button.mat-primary, .app.blue .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.blue .mat-mdc-raised-button.mat-accent, .app.blue .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.blue .mat-mdc-raised-button.mat-warn, .app.blue .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.blue .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.blue .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.blue .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.blue .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.blue .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.blue .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #1976d2;
  --mat-mdc-button-ripple-color: rgba(25, 118, 210, 0.1);
}
.app.blue .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #e0e0e0;
  --mat-mdc-button-ripple-color: rgba(224, 224, 224, 0.1);
}
.app.blue .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #ff5252;
  --mat-mdc-button-ripple-color: rgba(255, 82, 82, 0.1);
}
.app.blue .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #1976d2;
}
.app.blue .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #e0e0e0;
}
.app.blue .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #ff5252;
}
.app.blue .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-mdc-fab, .app.blue .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.blue .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before, .app.blue .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.blue .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.blue .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .app.blue .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.blue .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.blue .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before, .app.blue .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.blue .mat-mdc-fab.mat-primary, .app.blue .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.blue .mat-mdc-fab.mat-accent, .app.blue .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.blue .mat-mdc-fab.mat-warn, .app.blue .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.blue .mat-mdc-fab.mat-unthemed, .app.blue .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #fff;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.app.blue .mat-mdc-fab.mat-primary, .app.blue .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #1976d2;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.app.blue .mat-mdc-fab.mat-accent, .app.blue .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #e0e0e0;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.app.blue .mat-mdc-fab.mat-warn, .app.blue .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #ff5252;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.app.blue .mat-mdc-fab[disabled][disabled], .app.blue .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: #e0e0e0;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
}
.app.blue .mdc-data-table {
  background-color: var(--mdc-theme-surface, #fff);
  border-color: rgba(0, 0, 0, 0.12);
}
.app.blue .mdc-data-table__row {
  background-color: inherit;
}
.app.blue .mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, #fff);
}
.app.blue .mdc-data-table__row--selected {
  background-color: rgba(25, 118, 210, 0.04);
}
.app.blue .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.app.blue .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.app.blue .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.blue .mdc-data-table__cell,
.app.blue .mdc-data-table__header-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.app.blue .mdc-data-table__pagination {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.blue .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.blue .mdc-data-table__header-cell {
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .mdc-data-table__pagination-total,
.app.blue .mdc-data-table__pagination-rows-per-page-label,
.app.blue .mdc-data-table__cell {
  color: rgba(0, 0, 0, 0.87);
}
[dir=rtl] .app.blue .mdc-data-table__pagination-button .mdc-button__icon, .app.blue .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.app.blue .mat-mdc-table {
  background: white;
}
.app.blue .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #1976d2;
}
.app.blue .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #e0e0e0;
}
.app.blue .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #ff5252;
}
.app.blue .mat-badge {
  position: relative;
}
.app.blue .mat-badge.mat-badge {
  overflow: visible;
}
.app.blue .mat-badge-hidden .mat-badge-content {
  display: none;
}
.app.blue .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.app.blue .ng-animate-disabled .mat-badge-content,
.app.blue .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.app.blue .mat-badge-content.mat-badge-active {
  transform: none;
}
.app.blue .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.app.blue .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.app.blue .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.app.blue .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .app.blue .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.app.blue .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .app.blue .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.app.blue .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .app.blue .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.app.blue .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .app.blue .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.app.blue .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.app.blue .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.app.blue .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.app.blue .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .app.blue .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.app.blue .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .app.blue .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.app.blue .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .app.blue .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.app.blue .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .app.blue .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.app.blue .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.app.blue .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.app.blue .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.app.blue .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .app.blue .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.app.blue .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .app.blue .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.app.blue .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .app.blue .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.app.blue .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .app.blue .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.app.blue .mat-badge-content {
  color: white;
  background: #1976d2;
}
.cdk-high-contrast-active .app.blue .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.app.blue .mat-badge-accent .mat-badge-content {
  background: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-badge-warn .mat-badge-content {
  color: white;
  background: #ff5252;
}
.app.blue .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.app.blue .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.app.blue .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.app.blue .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.blue .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.app.blue .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.app.blue .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.app.blue [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.app.blue .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.app.blue .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.app.blue .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.app.blue .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.app.blue .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.app.blue .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app.blue .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.app.blue .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.app.blue .mat-datepicker-toggle,
.app.blue .mat-datepicker-content .mat-calendar-next-button,
.app.blue .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.app.blue .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.app.blue .mat-calendar-table-header,
.app.blue .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.blue .mat-calendar-body-cell-content,
.app.blue .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.app.blue .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.app.blue .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.app.blue .mat-calendar-body-in-range::before {
  background: rgba(25, 118, 210, 0.2);
}
.app.blue .mat-calendar-body-comparison-identical,
.app.blue .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.blue .mat-calendar-body-comparison-bridge-start::before,
.app.blue [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(25, 118, 210, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.blue .mat-calendar-body-comparison-bridge-end::before,
.app.blue [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(25, 118, 210, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.blue .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.blue .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.blue .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.blue .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.blue .mat-calendar-body-selected {
  background-color: #1976d2;
  color: white;
}
.app.blue .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(25, 118, 210, 0.4);
}
.app.blue .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.blue .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.blue .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(25, 118, 210, 0.3);
}
@media (hover: hover) {
  .app.blue .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(25, 118, 210, 0.3);
  }
}
.app.blue .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(224, 224, 224, 0.2);
}
.app.blue .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.app.blue .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.blue .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.app.blue .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(224, 224, 224, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.blue .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.app.blue .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(224, 224, 224, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.blue .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.blue .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.blue .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.blue .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.blue .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(224, 224, 224, 0.4);
}
.app.blue .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.app.blue .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.blue .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(224, 224, 224, 0.3);
}
@media (hover: hover) {
  .app.blue .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(224, 224, 224, 0.3);
  }
}
.app.blue .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(255, 82, 82, 0.2);
}
.app.blue .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.app.blue .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.blue .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.app.blue .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 82, 82, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.blue .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.app.blue .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 82, 82, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.blue .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.blue .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.blue .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.blue .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.blue .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #ff5252;
  color: white;
}
.app.blue .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 82, 82, 0.4);
}
.app.blue .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.blue .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.blue .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 82, 82, 0.3);
}
@media (hover: hover) {
  .app.blue .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 82, 82, 0.3);
  }
}
.app.blue .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-datepicker-toggle-active {
  color: #1976d2;
}
.app.blue .mat-datepicker-toggle-active.mat-accent {
  color: #e0e0e0;
}
.app.blue .mat-datepicker-toggle-active.mat-warn {
  color: #ff5252;
}
.app.blue .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.blue .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.app.blue .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.blue .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .app.blue .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .app.blue .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .app.blue .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.app.blue .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-expansion-panel-header-description,
.app.blue .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.blue .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.app.blue .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.app.blue .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.app.blue .mat-icon.mat-primary {
  color: #1976d2;
}
.app.blue .mat-icon.mat-accent {
  color: #e0e0e0;
}
.app.blue .mat-icon.mat-warn {
  color: #ff5252;
}
.app.blue .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-drawer.mat-drawer-push {
  background-color: white;
}
.app.blue .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.blue [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.blue [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.app.blue .mat-step-header.cdk-keyboard-focused, .app.blue .mat-step-header.cdk-program-focused, .app.blue .mat-step-header:hover:not([aria-disabled]), .app.blue .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.blue .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .app.blue .mat-step-header:hover {
    background: none;
  }
}
.app.blue .mat-step-header .mat-step-label,
.app.blue .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.app.blue .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.app.blue .mat-step-header .mat-step-icon-selected,
.app.blue .mat-step-header .mat-step-icon-state-done,
.app.blue .mat-step-header .mat-step-icon-state-edit {
  background-color: #1976d2;
  color: white;
}
.app.blue .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-step-header.mat-accent .mat-step-icon-selected,
.app.blue .mat-step-header.mat-accent .mat-step-icon-state-done,
.app.blue .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.app.blue .mat-step-header.mat-warn .mat-step-icon-selected,
.app.blue .mat-step-header.mat-warn .mat-step-icon-state-done,
.app.blue .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #ff5252;
  color: white;
}
.app.blue .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #ff5252;
}
.app.blue .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-step-header .mat-step-label.mat-step-label-error {
  color: #ff5252;
}
.app.blue .mat-stepper-horizontal, .app.blue .mat-stepper-vertical {
  background-color: white;
}
.app.blue .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.app.blue .mat-horizontal-stepper-header::before,
.app.blue .mat-horizontal-stepper-header::after,
.app.blue .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.blue .mat-sort-header-arrow {
  color: #757575;
}
.app.blue .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-toolbar.mat-primary {
  background: #1976d2;
  color: white;
}
.app.blue .mat-toolbar.mat-accent {
  background: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-toolbar.mat-warn {
  background: #ff5252;
  color: white;
}
.app.blue .mat-toolbar .mat-form-field-underline,
.app.blue .mat-toolbar .mat-form-field-ripple,
.app.blue .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.app.blue .mat-toolbar .mat-form-field-label,
.app.blue .mat-toolbar .mat-focused .mat-form-field-label,
.app.blue .mat-toolbar .mat-select-value,
.app.blue .mat-toolbar .mat-select-arrow,
.app.blue .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.app.blue .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.app.blue .mat-tree {
  background: white;
}
.app.blue .mat-tree-node,
.app.blue .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .bg-primary {
  background: black;
  color: white;
}
.app.blue .bg-accent {
  background: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .bg-warn {
  background: #ff5252;
  color: white;
}
.app.blue .text-muted {
  color: rgba(0, 0, 0, 0.54) !important;
}
.app.blue .secondary-color {
  color: #bbdefb;
}
.app.blue .primary-color {
  color: #1976d2;
}
.app.blue .top-navbar.mat-toolbar,
.app.blue .app-dropdown .mat-mdc-menu-item,
.app.blue .app-dropdown .user-info,
.app.blue .account-sidenav .mat-nav-list .mat-mdc-list-item,
.app.blue .product-item .title,
.app.blue .info-bar .mat-mdc-card .content p,
.app.blue .filter-sidenav .mat-expansion-panel-header-title,
.app.blue .mat-tab-body-content,
.app.blue .account-card-title {
  color: #616161 !important;
}
.app.blue .top-toolbar.mat-toolbar-row {
  border-bottom: 1px solid rgba(227, 242, 253, 0.2);
  color: #bbdefb;
}
.app.blue .top-toolbar.mat-toolbar-row .top-menu a,
.app.blue .top-toolbar.mat-toolbar-row .sidenav-toggle {
  color: #bbdefb;
}
.app.blue .top-navbar {
  background: #fafafa;
}
.app.blue .border-bottom-mute {
  border-bottom: 1px solid rgba(227, 242, 253, 0.2);
}
.app.blue .search-dropdown.mat-mdc-menu-panel {
  background: transparent;
}
.app.blue .mat-mdc-snack-bar-container.success .mdc-snackbar__surface {
  background: #388E3C;
}
.app.blue .mat-mdc-snack-bar-container.error .mdc-snackbar__surface {
  background: #E53935;
}
.app.blue .new-price {
  color: #ff5252;
}
.app.blue .primary-text {
  color: #1976d2;
}
.app.blue .active-link {
  background-color: black;
  color: #fff;
}
.app.blue .horizontal-active-link,
.app.blue .app-dropdown .mat-mdc-menu-item.horizontal-active-link {
  color: #1976d2 !important;
}
.app.blue .filter-brands button.selected,
.app.blue .filter-buttons button.selected {
  box-shadow: 0px 0px 1px 2px #1976d2;
}
.app.blue .product-pagination .ngx-pagination {
  margin-bottom: 0;
  padding: 14px 10px;
}
.app.blue .product-pagination .ngx-pagination .current {
  background: black;
  color: white;
}
.app.blue .product-pagination .ngx-pagination a:hover, .app.blue .product-pagination .ngx-pagination button:hover {
  background: rgb(165, 165, 165);
  color: black;
}
.app.blue .breadcrumb a {
  color: #6b6b6b;
}
.app.blue .breadcrumb .breadcrumb-item + .breadcrumb-item:before {
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .user-block {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.app.blue .menu-item .mat-mdc-button:not(.active-link) .menu-icon {
  color: black;
}
.app.blue .messages .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label {
  color: rgba(255, 255, 255, 0.6);
}
.app.blue .messages .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: white;
}
.app.red {
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  /* ngx-pagination */
}
.app.red .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.red .mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.red .mat-mdc-option:hover:not(.mdc-list-item--disabled), .app.red .mat-mdc-option:focus:not(.mdc-list-item--disabled), .app.red .mat-mdc-option.mat-mdc-option-active, .app.red .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app.red .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #d32f2f);
}
.app.red .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #e0e0e0);
}
.app.red .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #ff3d00);
}
.app.red .mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.red .mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.app.red .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.app.red .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.app.red .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #d32f2f;
}
.app.red .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.app.red .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #d32f2f;
}
.app.red .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.app.red .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.app.red .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.app.red .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #e0e0e0;
}
.app.red .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.app.red .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #e0e0e0;
}
.app.red .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.app.red .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.app.red .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.app.red .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #e0e0e0;
}
.app.red .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.app.red .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #e0e0e0;
}
.app.red .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.app.red .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.app.red .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.app.red .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ff3d00;
}
.app.red .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.app.red .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ff3d00;
}
.app.red .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.app.red .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.app.red .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.app.red .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.app.red .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.app.red .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}
.app.red .mat-app-background, .app.red.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-elevation-z0, .app.red .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z1, .app.red .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z2, .app.red .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z3, .app.red .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z4, .app.red .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z5, .app.red .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z6, .app.red .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z7, .app.red .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z8, .app.red .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z9, .app.red .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z10, .app.red .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z11, .app.red .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z12, .app.red .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z13, .app.red .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z14, .app.red .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z15, .app.red .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z16, .app.red .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z17, .app.red .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z18, .app.red .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z19, .app.red .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z20, .app.red .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z21, .app.red .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z22, .app.red .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z23, .app.red .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z24, .app.red .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.app.red .mat-mdc-card {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-color: #fff;
}
.app.red .mat-mdc-card-outlined {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-outline-color: #e0e0e0;
}
.app.red .mat-mdc-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.app.red .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #d32f2f;
}
.app.red .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(211, 47, 47, 0.25)'/%3E%3C/svg%3E");
}
.app.red .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(211, 47, 47, 0.25);
}
.app.red .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #e0e0e0;
}
.app.red .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(224, 224, 224, 0.25)'/%3E%3C/svg%3E");
}
.app.red .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(224, 224, 224, 0.25);
}
.app.red .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #ff3d00;
}
.app.red .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(255, 61, 0, 0.25)'/%3E%3C/svg%3E");
}
.app.red .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(255, 61, 0, 0.25);
}
.app.red .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: white;
}
.app.red .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.app.red .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87);
}
@media all {
  .app.red .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
@media all {
  .app.red .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
.app.red .mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #d32f2f);
}
.app.red .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.app.red .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.app.red .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.app.red .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.app.red .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.app.red .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.app.red .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.app.red .mdc-text-field--filled .mdc-text-field__ripple::before,
.app.red .mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
.app.red .mdc-text-field--filled:hover .mdc-text-field__ripple::before, .app.red .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.app.red .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .app.red .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.app.red .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: whitesmoke;
}
.app.red .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.app.red .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.app.red .mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-primary, #d32f2f);
}
.app.red .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.app.red .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.app.red .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.red .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.app.red .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.app.red .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}
.app.red .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.app.red .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.app.red .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #d32f2f);
}
[dir=rtl] .app.red .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .app.red .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .app.red .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .app.red .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.app.red .mdc-text-field--outlined .mdc-text-field__ripple::before,
.app.red .mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, transparent);
}
[dir=rtl] .app.red .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .app.red .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.app.red .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(211, 47, 47, 0.87);
}
.app.red .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #ff3d00);
}
.app.red .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #ff3d00);
}
.app.red .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #ff3d00);
}
.app.red .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #ff3d00);
}
.app.red .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #ff3d00);
}
.app.red .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #ff3d00);
}
.app.red .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #ff3d00);
}
.app.red .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.app.red .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.app.red .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ff3d00);
}
.app.red .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.app.red .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.app.red .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ff3d00);
}
.app.red .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.app.red .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.app.red .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ff3d00);
}
.app.red .mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.38);
}
@media all {
  .app.red .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
@media all {
  .app.red .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
.app.red .mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.red .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.app.red .mdc-text-field--disabled .mdc-text-field-character-counter,
.app.red .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.app.red .mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.app.red .mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.app.red .mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.app.red .mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.app.red .mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
.app.red .mdc-text-field--disabled .mdc-notched-outline__leading,
.app.red .mdc-text-field--disabled .mdc-notched-outline__notch,
.app.red .mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.red .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.red .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.red .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.red .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.red .mdc-text-field--disabled .mdc-text-field-character-counter,
  .app.red .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.red .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.red .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.red .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.red .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.red .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.red .mdc-text-field--disabled .mdc-notched-outline__leading,
  .app.red .mdc-text-field--disabled .mdc-notched-outline__notch,
  .app.red .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.app.red .mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #fafafa;
}
[dir=rtl] .app.red .mdc-text-field--end-aligned .mdc-text-field__input, .app.red .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .app.red .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker), .app.red .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.app.red .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #ff3d00);
}
.app.red .mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}
.app.red .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}
.app.red .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.red .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(211, 47, 47, 0.87);
}
.app.red .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(224, 224, 224, 0.87);
}
.app.red .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(255, 61, 0, 0.87);
}
.app.red .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, #e0e0e0);
}
.app.red .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-secondary, #e0e0e0);
}
.app.red .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(224, 224, 224, 0.87);
}
.app.red .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #ff3d00);
}
.app.red .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #ff3d00);
}
.app.red .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #ff3d00);
}
.app.red .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #ff3d00);
}
.app.red .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #ff3d00);
}
.app.red .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #ff3d00);
}
.app.red .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #ff3d00);
}
.app.red .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.app.red .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.app.red .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ff3d00);
}
.app.red .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.app.red .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.app.red .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ff3d00);
}
.app.red .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.app.red .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.app.red .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ff3d00);
}
.app.red .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.app.red .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.app.red .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, #e0e0e0);
}
.app.red .mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #ff3d00);
}
.app.red .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #ff3d00);
}
.app.red .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(255, 61, 0, 0.87);
}
.app.red .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #ff3d00);
}
.app.red .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #ff3d00);
}
.app.red .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #ff3d00);
}
.app.red .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #ff3d00);
}
.app.red .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #ff3d00);
}
.app.red .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #ff3d00);
}
.app.red .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #ff3d00);
}
.app.red .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.app.red .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.app.red .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ff3d00);
}
.app.red .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.app.red .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.app.red .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ff3d00);
}
.app.red .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.app.red .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.app.red .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ff3d00);
}
.app.red .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.app.red .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.app.red .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ff3d00);
}
.app.red .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.app.red [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.app.red .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.app.red .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.red .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.app.red .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.app.red .mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.red .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.app.red .mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.red .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.app.red .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.app.red .mdc-list-item--disabled .mdc-list-item__start,
.app.red .mdc-list-item--disabled .mdc-list-item__content,
.app.red .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.app.red .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.red .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.red .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.red .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.app.red .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.app.red .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.app.red .mdc-list-item--selected .mdc-list-item__primary-text,
.app.red .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #d32f2f);
}
.app.red .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.red .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #d32f2f);
}
.app.red .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.red .mdc-list-divider::after {
  border-bottom-color: white;
}
.app.red .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.red .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-mdc-select-placeholder {
  color: rgba(0, 0, 0, 0.6);
}
.app.red .mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.app.red .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: rgba(211, 47, 47, 0.87);
}
.app.red .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: rgba(224, 224, 224, 0.87);
}
.app.red .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
  color: rgba(255, 61, 0, 0.87);
}
.app.red .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: rgba(255, 61, 0, 0.87);
}
.app.red .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.app.red .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.app.red .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.red .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.app.red .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.app.red .mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.red .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.app.red .mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.red .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.app.red .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.app.red .mdc-list-item--disabled .mdc-list-item__start,
.app.red .mdc-list-item--disabled .mdc-list-item__content,
.app.red .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.app.red .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.red .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.red .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.red .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.app.red .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.app.red .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.app.red .mdc-list-item--selected .mdc-list-item__primary-text,
.app.red .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #d32f2f);
}
.app.red .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.red .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #d32f2f);
}
.app.red .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.red .mdc-list-divider::after {
  border-bottom-color: white;
}
.app.red .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.red .mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-with-divider-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}
.app.red .mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
}
.app.red .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, .app.red .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #d32f2f;
  --mdc-chip-elevated-disabled-container-color: #d32f2f;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.app.red .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected, .app.red .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-disabled-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-disabled-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-selected-icon-color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected, .app.red .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #ff3d00;
  --mdc-chip-elevated-disabled-container-color: #ff3d00;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.app.red .mat-mdc-chip-focus-overlay {
  background: black;
}
.app.red .mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-color: #fff;
}
.app.red .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.red .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #e53935;
  --mdc-switch-selected-handle-color: #e53935;
  --mdc-switch-selected-hover-state-layer-color: #e53935;
  --mdc-switch-selected-pressed-state-layer-color: #e53935;
  --mdc-switch-selected-focus-handle-color: #b71c1c;
  --mdc-switch-selected-hover-handle-color: #b71c1c;
  --mdc-switch-selected-pressed-handle-color: #b71c1c;
  --mdc-switch-selected-focus-track-color: #e57373;
  --mdc-switch-selected-hover-track-color: #e57373;
  --mdc-switch-selected-pressed-track-color: #e57373;
  --mdc-switch-selected-track-color: #e57373;
}
.app.red .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #757575;
  --mdc-switch-selected-handle-color: #757575;
  --mdc-switch-selected-hover-state-layer-color: #757575;
  --mdc-switch-selected-pressed-state-layer-color: #757575;
  --mdc-switch-selected-focus-handle-color: #212121;
  --mdc-switch-selected-hover-handle-color: #212121;
  --mdc-switch-selected-pressed-handle-color: #212121;
  --mdc-switch-selected-focus-track-color: #e0e0e0;
  --mdc-switch-selected-hover-track-color: #e0e0e0;
  --mdc-switch-selected-pressed-track-color: #e0e0e0;
  --mdc-switch-selected-track-color: #e0e0e0;
}
.app.red .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #f4511e;
  --mdc-switch-selected-handle-color: #f4511e;
  --mdc-switch-selected-hover-state-layer-color: #f4511e;
  --mdc-switch-selected-pressed-state-layer-color: #f4511e;
  --mdc-switch-selected-focus-handle-color: #bf360c;
  --mdc-switch-selected-hover-handle-color: #bf360c;
  --mdc-switch-selected-pressed-handle-color: #bf360c;
  --mdc-switch-selected-focus-track-color: #ff8a65;
  --mdc-switch-selected-hover-track-color: #ff8a65;
  --mdc-switch-selected-pressed-track-color: #ff8a65;
  --mdc-switch-selected-track-color: #ff8a65;
}
.app.red .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.red .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #d32f2f;
  --mdc-radio-selected-hover-icon-color: #d32f2f;
  --mdc-radio-selected-icon-color: #d32f2f;
  --mdc-radio-selected-pressed-icon-color: #d32f2f;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #d32f2f;
}
.app.red .mat-mdc-radio-button.mat-primary .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #e0e0e0;
  --mdc-radio-selected-hover-icon-color: #e0e0e0;
  --mdc-radio-selected-icon-color: #e0e0e0;
  --mdc-radio-selected-pressed-icon-color: #e0e0e0;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #e0e0e0;
}
.app.red .mat-mdc-radio-button.mat-accent .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ff3d00;
  --mdc-radio-selected-hover-icon-color: #ff3d00;
  --mdc-radio-selected-icon-color: #ff3d00;
  --mdc-radio-selected-pressed-icon-color: #ff3d00;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #ff3d00;
}
.app.red .mat-mdc-radio-button.mat-warn .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.app.red .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #d32f2f;
  --mdc-slider-focus-handle-color: #d32f2f;
  --mdc-slider-hover-handle-color: #d32f2f;
  --mdc-slider-active-track-color: #d32f2f;
  --mdc-slider-inactive-track-color: #d32f2f;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #d32f2f;
  --mat-mdc-slider-ripple-color: #d32f2f;
  --mat-mdc-slider-hover-ripple-color: rgba(211, 47, 47, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(211, 47, 47, 0.2);
}
.app.red .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #e0e0e0;
  --mdc-slider-focus-handle-color: #e0e0e0;
  --mdc-slider-hover-handle-color: #e0e0e0;
  --mdc-slider-active-track-color: #e0e0e0;
  --mdc-slider-inactive-track-color: #e0e0e0;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #e0e0e0;
  --mat-mdc-slider-ripple-color: #e0e0e0;
  --mat-mdc-slider-hover-ripple-color: rgba(224, 224, 224, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(224, 224, 224, 0.2);
}
.app.red .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #ff3d00;
  --mdc-slider-focus-handle-color: #ff3d00;
  --mdc-slider-hover-handle-color: #ff3d00;
  --mdc-slider-active-track-color: #ff3d00;
  --mdc-slider-inactive-track-color: #ff3d00;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #ff3d00;
  --mat-mdc-slider-ripple-color: #ff3d00;
  --mat-mdc-slider-hover-ripple-color: rgba(255, 61, 0, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(255, 61, 0, 0.2);
}
.app.red .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.app.red .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.red .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.app.red .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.app.red .mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.red .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.app.red .mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.red .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.app.red .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.app.red .mdc-list-item--disabled .mdc-list-item__start,
.app.red .mdc-list-item--disabled .mdc-list-item__content,
.app.red .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.app.red .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.red .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.red .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.red .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.app.red .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.app.red .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.app.red .mdc-list-item--selected .mdc-list-item__primary-text,
.app.red .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #d32f2f);
}
.app.red .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.red .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #d32f2f);
}
.app.red .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.red .mdc-list-divider::after {
  border-bottom-color: white;
}
.app.red .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.red .mat-mdc-menu-item[disabled],
.app.red .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.app.red .mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38));
}
.app.red .mat-mdc-menu-item .mat-icon-no-color,
.app.red .mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.red .mat-mdc-menu-item:hover:not([disabled]),
.app.red .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.app.red .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.app.red .mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.app.red .mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}
.app.red .mat-mdc-list-option .mdc-list-item__start, .app.red .mat-mdc-list-option .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #d32f2f;
  --mdc-checkbox-selected-hover-icon-color: #d32f2f;
  --mdc-checkbox-selected-icon-color: #d32f2f;
  --mdc-checkbox-selected-pressed-icon-color: #d32f2f;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.app.red .mat-mdc-list-option .mdc-list-item__start, .app.red .mat-mdc-list-option .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #d32f2f;
  --mdc-radio-selected-hover-icon-color: #d32f2f;
  --mdc-radio-selected-icon-color: #d32f2f;
  --mdc-radio-selected-pressed-icon-color: #d32f2f;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #d32f2f;
}
.app.red .mat-mdc-list-option .mdc-list-item__start .mdc-radio--disabled + label, .app.red .mat-mdc-list-option .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-mdc-list-option.mat-accent .mdc-list-item__start, .app.red .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #e0e0e0;
  --mdc-checkbox-selected-hover-icon-color: #e0e0e0;
  --mdc-checkbox-selected-icon-color: #e0e0e0;
  --mdc-checkbox-selected-pressed-icon-color: #e0e0e0;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.app.red .mat-mdc-list-option.mat-accent .mdc-list-item__start, .app.red .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #e0e0e0;
  --mdc-radio-selected-hover-icon-color: #e0e0e0;
  --mdc-radio-selected-icon-color: #e0e0e0;
  --mdc-radio-selected-pressed-icon-color: #e0e0e0;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #e0e0e0;
}
.app.red .mat-mdc-list-option.mat-accent .mdc-list-item__start .mdc-radio--disabled + label, .app.red .mat-mdc-list-option.mat-accent .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-mdc-list-option.mat-warn .mdc-list-item__start, .app.red .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ff3d00;
  --mdc-checkbox-selected-hover-icon-color: #ff3d00;
  --mdc-checkbox-selected-icon-color: #ff3d00;
  --mdc-checkbox-selected-pressed-icon-color: #ff3d00;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.app.red .mat-mdc-list-option.mat-warn .mdc-list-item__start, .app.red .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ff3d00;
  --mdc-radio-selected-hover-icon-color: #ff3d00;
  --mdc-radio-selected-icon-color: #ff3d00;
  --mdc-radio-selected-pressed-icon-color: #ff3d00;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #ff3d00;
}
.app.red .mat-mdc-list-option.mat-warn .mdc-list-item__start .mdc-radio--disabled + label, .app.red .mat-mdc-list-option.mat-warn .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.app.red .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #d32f2f;
}
.app.red .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.red .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #d32f2f;
}
.app.red .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.app.red .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.app.red .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.app.red .mat-mdc-paginator {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.54);
}
.app.red .mat-mdc-paginator-decrement,
.app.red .mat-mdc-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.app.red .mat-mdc-paginator-first,
.app.red .mat-mdc-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.app.red .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.app.red .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.app.red .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.app.red .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.red .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.12);
}
.app.red .mat-mdc-tab, .app.red .mat-mdc-tab-link {
  background-color: transparent;
}
.app.red .mat-mdc-tab .mdc-tab__text-label, .app.red .mat-mdc-tab-link .mdc-tab__text-label {
  color: rgba(0, 0, 0, 0.6);
}
.app.red .mat-mdc-tab.mat-mdc-tab-disabled .mdc-tab__ripple::before,
.app.red .mat-mdc-tab.mat-mdc-tab-disabled .mat-ripple-element, .app.red .mat-mdc-tab-link.mat-mdc-tab-disabled .mdc-tab__ripple::before,
.app.red .mat-mdc-tab-link.mat-mdc-tab-disabled .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .app.red .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #d32f2f;
}
.app.red .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .app.red .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #d32f2f);
}
.app.red .mdc-tab__ripple::before,
.app.red .mat-mdc-tab .mat-ripple-element,
.app.red .mat-mdc-tab-header-pagination .mat-ripple-element,
.app.red .mat-mdc-tab-link .mat-ripple-element {
  background-color: #d32f2f;
}
.app.red .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .app.red .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .app.red .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .app.red .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #e0e0e0;
}
.app.red .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .app.red .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .app.red .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .app.red .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #e0e0e0);
}
.app.red .mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
.app.red .mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
.app.red .mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.app.red .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element, .app.red .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
.app.red .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
.app.red .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.app.red .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element {
  background-color: #e0e0e0;
}
.app.red .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .app.red .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .app.red .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .app.red .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #ff3d00;
}
.app.red .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .app.red .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .app.red .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .app.red .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #ff3d00);
}
.app.red .mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
.app.red .mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
.app.red .mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.app.red .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element, .app.red .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
.app.red .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
.app.red .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.app.red .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element {
  background-color: #ff3d00;
}
.app.red .mat-mdc-tab-group.mat-background-primary, .app.red .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-mdc-tab-header-with-background-background-color: #d32f2f;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.app.red .mat-mdc-tab-group.mat-background-accent, .app.red .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-mdc-tab-header-with-background-background-color: #e0e0e0;
  --mat-mdc-tab-header-with-background-foreground-color: #000;
}
.app.red .mat-mdc-tab-group.mat-background-warn, .app.red .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-mdc-tab-header-with-background-background-color: #ff3d00;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.app.red .mat-mdc-tab-header-pagination-chevron {
  border-color: var(--mdc-theme-on-surface, #000);
}
.app.red .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.red .mat-mdc-checkbox .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.red .mat-mdc-checkbox .mdc-checkbox__ripple {
  background: #000;
}
.app.red .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #d32f2f;
  --mdc-checkbox-selected-hover-icon-color: #d32f2f;
  --mdc-checkbox-selected-icon-color: #d32f2f;
  --mdc-checkbox-selected-pressed-icon-color: #d32f2f;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.app.red .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(211, 47, 47, 0.1);
}
.app.red .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #d32f2f;
}
.app.red .mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #e0e0e0;
  --mdc-checkbox-selected-hover-icon-color: #e0e0e0;
  --mdc-checkbox-selected-icon-color: #e0e0e0;
  --mdc-checkbox-selected-pressed-icon-color: #e0e0e0;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.app.red .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(224, 224, 224, 0.1);
}
.app.red .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #e0e0e0;
}
.app.red .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ff3d00;
  --mdc-checkbox-selected-hover-icon-color: #ff3d00;
  --mdc-checkbox-selected-icon-color: #ff3d00;
  --mdc-checkbox-selected-pressed-icon-color: #ff3d00;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.app.red .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(255, 61, 0, 0.1);
}
.app.red .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #ff3d00;
}
.app.red .mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.app.red .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #d32f2f;
}
.app.red .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #e0e0e0;
}
.app.red .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #ff3d00;
}
.app.red .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.app.red .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #d32f2f;
  --mdc-filled-button-label-text-color: #fff;
}
.app.red .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #e0e0e0;
  --mdc-filled-button-label-text-color: #000;
}
.app.red .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #ff3d00;
  --mdc-filled-button-label-text-color: #fff;
}
.app.red .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.app.red .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #d32f2f;
  --mdc-protected-button-label-text-color: #fff;
}
.app.red .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #e0e0e0;
  --mdc-protected-button-label-text-color: #000;
}
.app.red .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #ff3d00;
  --mdc-protected-button-label-text-color: #fff;
}
.app.red .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}
.app.red .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.app.red .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.app.red .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #d32f2f;
}
.app.red .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #e0e0e0;
}
.app.red .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #ff3d00;
}
.app.red .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}
.app.red .mat-mdc-button, .app.red .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.red .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .app.red .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.red .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.red .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .app.red .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.red .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.red .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .app.red .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.red .mat-mdc-button.mat-primary, .app.red .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #d32f2f;
  --mat-mdc-button-ripple-color: rgba(211, 47, 47, 0.1);
}
.app.red .mat-mdc-button.mat-accent, .app.red .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #e0e0e0;
  --mat-mdc-button-ripple-color: rgba(224, 224, 224, 0.1);
}
.app.red .mat-mdc-button.mat-warn, .app.red .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #ff3d00;
  --mat-mdc-button-ripple-color: rgba(255, 61, 0, 0.1);
}
.app.red .mat-mdc-raised-button, .app.red .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.red .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .app.red .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.red .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.red .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .app.red .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.red .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.red .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .app.red .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.red .mat-mdc-raised-button.mat-primary, .app.red .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.red .mat-mdc-raised-button.mat-accent, .app.red .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.red .mat-mdc-raised-button.mat-warn, .app.red .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.red .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.red .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.red .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.red .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.red .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.red .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #d32f2f;
  --mat-mdc-button-ripple-color: rgba(211, 47, 47, 0.1);
}
.app.red .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #e0e0e0;
  --mat-mdc-button-ripple-color: rgba(224, 224, 224, 0.1);
}
.app.red .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #ff3d00;
  --mat-mdc-button-ripple-color: rgba(255, 61, 0, 0.1);
}
.app.red .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #d32f2f;
}
.app.red .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #e0e0e0;
}
.app.red .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #ff3d00;
}
.app.red .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-mdc-fab, .app.red .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.red .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before, .app.red .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.red .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.red .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .app.red .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.red .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.red .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before, .app.red .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.red .mat-mdc-fab.mat-primary, .app.red .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.red .mat-mdc-fab.mat-accent, .app.red .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.red .mat-mdc-fab.mat-warn, .app.red .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.red .mat-mdc-fab.mat-unthemed, .app.red .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #fff;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.app.red .mat-mdc-fab.mat-primary, .app.red .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #d32f2f;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.app.red .mat-mdc-fab.mat-accent, .app.red .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #e0e0e0;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.app.red .mat-mdc-fab.mat-warn, .app.red .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #ff3d00;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.app.red .mat-mdc-fab[disabled][disabled], .app.red .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: #e0e0e0;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
}
.app.red .mdc-data-table {
  background-color: var(--mdc-theme-surface, #fff);
  border-color: rgba(0, 0, 0, 0.12);
}
.app.red .mdc-data-table__row {
  background-color: inherit;
}
.app.red .mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, #fff);
}
.app.red .mdc-data-table__row--selected {
  background-color: rgba(211, 47, 47, 0.04);
}
.app.red .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.app.red .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.app.red .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.red .mdc-data-table__cell,
.app.red .mdc-data-table__header-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.app.red .mdc-data-table__pagination {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.red .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.red .mdc-data-table__header-cell {
  color: rgba(0, 0, 0, 0.87);
}
.app.red .mdc-data-table__pagination-total,
.app.red .mdc-data-table__pagination-rows-per-page-label,
.app.red .mdc-data-table__cell {
  color: rgba(0, 0, 0, 0.87);
}
[dir=rtl] .app.red .mdc-data-table__pagination-button .mdc-button__icon, .app.red .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.app.red .mat-mdc-table {
  background: white;
}
.app.red .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #d32f2f;
}
.app.red .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #e0e0e0;
}
.app.red .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #ff3d00;
}
.app.red .mat-badge {
  position: relative;
}
.app.red .mat-badge.mat-badge {
  overflow: visible;
}
.app.red .mat-badge-hidden .mat-badge-content {
  display: none;
}
.app.red .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.app.red .ng-animate-disabled .mat-badge-content,
.app.red .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.app.red .mat-badge-content.mat-badge-active {
  transform: none;
}
.app.red .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.app.red .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.app.red .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.app.red .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .app.red .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.app.red .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .app.red .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.app.red .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .app.red .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.app.red .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .app.red .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.app.red .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.app.red .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.app.red .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.app.red .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .app.red .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.app.red .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .app.red .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.app.red .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .app.red .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.app.red .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .app.red .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.app.red .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.app.red .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.app.red .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.app.red .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .app.red .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.app.red .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .app.red .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.app.red .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .app.red .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.app.red .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .app.red .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.app.red .mat-badge-content {
  color: white;
  background: #d32f2f;
}
.cdk-high-contrast-active .app.red .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.app.red .mat-badge-accent .mat-badge-content {
  background: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-badge-warn .mat-badge-content {
  color: white;
  background: #ff3d00;
}
.app.red .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.app.red .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.red .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.app.red .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.app.red .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.red .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.app.red .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.app.red .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.app.red [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.app.red .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.app.red .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.app.red .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.app.red .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.app.red .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.app.red .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app.red .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.app.red .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.app.red .mat-datepicker-toggle,
.app.red .mat-datepicker-content .mat-calendar-next-button,
.app.red .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.app.red .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.app.red .mat-calendar-table-header,
.app.red .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.red .mat-calendar-body-cell-content,
.app.red .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.app.red .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.app.red .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.app.red .mat-calendar-body-in-range::before {
  background: rgba(211, 47, 47, 0.2);
}
.app.red .mat-calendar-body-comparison-identical,
.app.red .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.red .mat-calendar-body-comparison-bridge-start::before,
.app.red [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(211, 47, 47, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.red .mat-calendar-body-comparison-bridge-end::before,
.app.red [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(211, 47, 47, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.red .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.red .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.red .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.red .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.red .mat-calendar-body-selected {
  background-color: #d32f2f;
  color: white;
}
.app.red .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(211, 47, 47, 0.4);
}
.app.red .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.red .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.red .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(211, 47, 47, 0.3);
}
@media (hover: hover) {
  .app.red .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(211, 47, 47, 0.3);
  }
}
.app.red .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(224, 224, 224, 0.2);
}
.app.red .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.app.red .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.red .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.app.red .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(224, 224, 224, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.red .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.app.red .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(224, 224, 224, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.red .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.red .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.red .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.red .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.red .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(224, 224, 224, 0.4);
}
.app.red .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.app.red .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.red .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(224, 224, 224, 0.3);
}
@media (hover: hover) {
  .app.red .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(224, 224, 224, 0.3);
  }
}
.app.red .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(255, 61, 0, 0.2);
}
.app.red .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.app.red .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.red .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.app.red .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 61, 0, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.red .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.app.red .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 61, 0, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.red .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.red .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.red .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.red .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.red .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #ff3d00;
  color: white;
}
.app.red .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 61, 0, 0.4);
}
.app.red .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.red .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.red .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 61, 0, 0.3);
}
@media (hover: hover) {
  .app.red .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 61, 0, 0.3);
  }
}
.app.red .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.app.red .mat-datepicker-toggle-active {
  color: #d32f2f;
}
.app.red .mat-datepicker-toggle-active.mat-accent {
  color: #e0e0e0;
}
.app.red .mat-datepicker-toggle-active.mat-warn {
  color: #ff3d00;
}
.app.red .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.red .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.app.red .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.red .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.red .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .app.red .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .app.red .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .app.red .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.app.red .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-expansion-panel-header-description,
.app.red .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.red .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.app.red .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.app.red .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.app.red .mat-icon.mat-primary {
  color: #d32f2f;
}
.app.red .mat-icon.mat-accent {
  color: #e0e0e0;
}
.app.red .mat-icon.mat-warn {
  color: #ff3d00;
}
.app.red .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-drawer.mat-drawer-push {
  background-color: white;
}
.app.red .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.red .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.red .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.red [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.red [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.red .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.app.red .mat-step-header.cdk-keyboard-focused, .app.red .mat-step-header.cdk-program-focused, .app.red .mat-step-header:hover:not([aria-disabled]), .app.red .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.red .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .app.red .mat-step-header:hover {
    background: none;
  }
}
.app.red .mat-step-header .mat-step-label,
.app.red .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.app.red .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.app.red .mat-step-header .mat-step-icon-selected,
.app.red .mat-step-header .mat-step-icon-state-done,
.app.red .mat-step-header .mat-step-icon-state-edit {
  background-color: #d32f2f;
  color: white;
}
.app.red .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-step-header.mat-accent .mat-step-icon-selected,
.app.red .mat-step-header.mat-accent .mat-step-icon-state-done,
.app.red .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.app.red .mat-step-header.mat-warn .mat-step-icon-selected,
.app.red .mat-step-header.mat-warn .mat-step-icon-state-done,
.app.red .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #ff3d00;
  color: white;
}
.app.red .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #ff3d00;
}
.app.red .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-step-header .mat-step-label.mat-step-label-error {
  color: #ff3d00;
}
.app.red .mat-stepper-horizontal, .app.red .mat-stepper-vertical {
  background-color: white;
}
.app.red .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.app.red .mat-horizontal-stepper-header::before,
.app.red .mat-horizontal-stepper-header::after,
.app.red .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.red .mat-sort-header-arrow {
  color: #757575;
}
.app.red .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-toolbar.mat-primary {
  background: #d32f2f;
  color: white;
}
.app.red .mat-toolbar.mat-accent {
  background: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-toolbar.mat-warn {
  background: #ff3d00;
  color: white;
}
.app.red .mat-toolbar .mat-form-field-underline,
.app.red .mat-toolbar .mat-form-field-ripple,
.app.red .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.app.red .mat-toolbar .mat-form-field-label,
.app.red .mat-toolbar .mat-focused .mat-form-field-label,
.app.red .mat-toolbar .mat-select-value,
.app.red .mat-toolbar .mat-select-arrow,
.app.red .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.app.red .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.app.red .mat-tree {
  background: white;
}
.app.red .mat-tree-node,
.app.red .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.app.red .bg-primary {
  background: black;
  color: white;
}
.app.red .bg-accent {
  background: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.red .bg-warn {
  background: #ff3d00;
  color: white;
}
.app.red .text-muted {
  color: rgba(0, 0, 0, 0.54) !important;
}
.app.red .secondary-color {
  color: #ffcdd2;
}
.app.red .primary-color {
  color: #d32f2f;
}
.app.red .top-navbar.mat-toolbar,
.app.red .app-dropdown .mat-mdc-menu-item,
.app.red .app-dropdown .user-info,
.app.red .account-sidenav .mat-nav-list .mat-mdc-list-item,
.app.red .product-item .title,
.app.red .info-bar .mat-mdc-card .content p,
.app.red .filter-sidenav .mat-expansion-panel-header-title,
.app.red .mat-tab-body-content,
.app.red .account-card-title {
  color: #616161 !important;
}
.app.red .top-toolbar.mat-toolbar-row {
  border-bottom: 1px solid rgba(255, 235, 238, 0.2);
  color: #ffcdd2;
}
.app.red .top-toolbar.mat-toolbar-row .top-menu a,
.app.red .top-toolbar.mat-toolbar-row .sidenav-toggle {
  color: #ffcdd2;
}
.app.red .top-navbar {
  background: #fafafa;
}
.app.red .border-bottom-mute {
  border-bottom: 1px solid rgba(255, 235, 238, 0.2);
}
.app.red .search-dropdown.mat-mdc-menu-panel {
  background: transparent;
}
.app.red .mat-mdc-snack-bar-container.success .mdc-snackbar__surface {
  background: #388E3C;
}
.app.red .mat-mdc-snack-bar-container.error .mdc-snackbar__surface {
  background: #E53935;
}
.app.red .new-price {
  color: #ff3d00;
}
.app.red .primary-text {
  color: #d32f2f;
}
.app.red .active-link {
  background-color: black;
  color: #fff;
}
.app.red .horizontal-active-link,
.app.red .app-dropdown .mat-mdc-menu-item.horizontal-active-link {
  color: #d32f2f !important;
}
.app.red .filter-brands button.selected,
.app.red .filter-buttons button.selected {
  box-shadow: 0px 0px 1px 2px #d32f2f;
}
.app.red .product-pagination .ngx-pagination {
  margin-bottom: 0;
  padding: 14px 10px;
}
.app.red .product-pagination .ngx-pagination .current {
  background: black;
  color: white;
}
.app.red .product-pagination .ngx-pagination a:hover, .app.red .product-pagination .ngx-pagination button:hover {
  background: rgb(165, 165, 165);
  color: black;
}
.app.red .breadcrumb a {
  color: #6b6b6b;
}
.app.red .breadcrumb .breadcrumb-item + .breadcrumb-item:before {
  color: rgba(0, 0, 0, 0.87);
}
.app.red .user-block {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.app.red .menu-item .mat-mdc-button:not(.active-link) .menu-icon {
  color: black;
}
.app.red .messages .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label {
  color: rgba(255, 255, 255, 0.6);
}
.app.red .messages .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: white;
}
.app.pink {
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  /* ngx-pagination */
}
.app.pink .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.pink .mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.pink .mat-mdc-option:hover:not(.mdc-list-item--disabled), .app.pink .mat-mdc-option:focus:not(.mdc-list-item--disabled), .app.pink .mat-mdc-option.mat-mdc-option-active, .app.pink .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app.pink .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #c2185b);
}
.app.pink .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #e0e0e0);
}
.app.pink .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #ff3d00);
}
.app.pink .mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.pink .mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.app.pink .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.app.pink .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.app.pink .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #c2185b;
}
.app.pink .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.app.pink .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #c2185b;
}
.app.pink .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.app.pink .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.app.pink .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.app.pink .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #e0e0e0;
}
.app.pink .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.app.pink .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #e0e0e0;
}
.app.pink .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.app.pink .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.app.pink .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.app.pink .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #e0e0e0;
}
.app.pink .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.app.pink .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #e0e0e0;
}
.app.pink .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.app.pink .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.app.pink .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.app.pink .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ff3d00;
}
.app.pink .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.app.pink .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ff3d00;
}
.app.pink .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.app.pink .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.app.pink .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.app.pink .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.app.pink .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.app.pink .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}
.app.pink .mat-app-background, .app.pink.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-elevation-z0, .app.pink .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z1, .app.pink .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z2, .app.pink .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z3, .app.pink .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z4, .app.pink .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z5, .app.pink .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z6, .app.pink .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z7, .app.pink .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z8, .app.pink .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z9, .app.pink .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z10, .app.pink .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z11, .app.pink .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z12, .app.pink .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z13, .app.pink .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z14, .app.pink .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z15, .app.pink .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z16, .app.pink .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z17, .app.pink .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z18, .app.pink .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z19, .app.pink .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z20, .app.pink .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z21, .app.pink .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z22, .app.pink .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z23, .app.pink .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z24, .app.pink .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.app.pink .mat-mdc-card {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-color: #fff;
}
.app.pink .mat-mdc-card-outlined {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-outline-color: #e0e0e0;
}
.app.pink .mat-mdc-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.app.pink .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #c2185b;
}
.app.pink .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(194, 24, 91, 0.25)'/%3E%3C/svg%3E");
}
.app.pink .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(194, 24, 91, 0.25);
}
.app.pink .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #e0e0e0;
}
.app.pink .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(224, 224, 224, 0.25)'/%3E%3C/svg%3E");
}
.app.pink .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(224, 224, 224, 0.25);
}
.app.pink .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #ff3d00;
}
.app.pink .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(255, 61, 0, 0.25)'/%3E%3C/svg%3E");
}
.app.pink .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(255, 61, 0, 0.25);
}
.app.pink .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: white;
}
.app.pink .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.app.pink .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87);
}
@media all {
  .app.pink .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
@media all {
  .app.pink .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
.app.pink .mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #c2185b);
}
.app.pink .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.app.pink .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.app.pink .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.app.pink .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.app.pink .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.app.pink .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.app.pink .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.app.pink .mdc-text-field--filled .mdc-text-field__ripple::before,
.app.pink .mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
.app.pink .mdc-text-field--filled:hover .mdc-text-field__ripple::before, .app.pink .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.app.pink .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .app.pink .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.app.pink .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: whitesmoke;
}
.app.pink .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.app.pink .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.app.pink .mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-primary, #c2185b);
}
.app.pink .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.app.pink .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.app.pink .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.pink .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.app.pink .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.app.pink .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}
.app.pink .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.app.pink .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.app.pink .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #c2185b);
}
[dir=rtl] .app.pink .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .app.pink .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .app.pink .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .app.pink .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.app.pink .mdc-text-field--outlined .mdc-text-field__ripple::before,
.app.pink .mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, transparent);
}
[dir=rtl] .app.pink .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .app.pink .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.app.pink .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(194, 24, 91, 0.87);
}
.app.pink .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #ff3d00);
}
.app.pink .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #ff3d00);
}
.app.pink .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #ff3d00);
}
.app.pink .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #ff3d00);
}
.app.pink .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #ff3d00);
}
.app.pink .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #ff3d00);
}
.app.pink .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #ff3d00);
}
.app.pink .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.app.pink .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.app.pink .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ff3d00);
}
.app.pink .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.app.pink .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.app.pink .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ff3d00);
}
.app.pink .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.app.pink .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.app.pink .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ff3d00);
}
.app.pink .mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.38);
}
@media all {
  .app.pink .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
@media all {
  .app.pink .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
.app.pink .mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.pink .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.app.pink .mdc-text-field--disabled .mdc-text-field-character-counter,
.app.pink .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.app.pink .mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.app.pink .mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.app.pink .mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.app.pink .mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.app.pink .mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
.app.pink .mdc-text-field--disabled .mdc-notched-outline__leading,
.app.pink .mdc-text-field--disabled .mdc-notched-outline__notch,
.app.pink .mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.pink .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.pink .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.pink .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.pink .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.pink .mdc-text-field--disabled .mdc-text-field-character-counter,
  .app.pink .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.pink .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.pink .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.pink .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.pink .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.pink .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.pink .mdc-text-field--disabled .mdc-notched-outline__leading,
  .app.pink .mdc-text-field--disabled .mdc-notched-outline__notch,
  .app.pink .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.app.pink .mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #fafafa;
}
[dir=rtl] .app.pink .mdc-text-field--end-aligned .mdc-text-field__input, .app.pink .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .app.pink .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker), .app.pink .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.app.pink .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #ff3d00);
}
.app.pink .mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}
.app.pink .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}
.app.pink .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.pink .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(194, 24, 91, 0.87);
}
.app.pink .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(224, 224, 224, 0.87);
}
.app.pink .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(255, 61, 0, 0.87);
}
.app.pink .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, #e0e0e0);
}
.app.pink .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-secondary, #e0e0e0);
}
.app.pink .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(224, 224, 224, 0.87);
}
.app.pink .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #ff3d00);
}
.app.pink .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #ff3d00);
}
.app.pink .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #ff3d00);
}
.app.pink .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #ff3d00);
}
.app.pink .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #ff3d00);
}
.app.pink .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #ff3d00);
}
.app.pink .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #ff3d00);
}
.app.pink .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.app.pink .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.app.pink .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ff3d00);
}
.app.pink .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.app.pink .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.app.pink .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ff3d00);
}
.app.pink .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.app.pink .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.app.pink .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ff3d00);
}
.app.pink .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.app.pink .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.app.pink .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, #e0e0e0);
}
.app.pink .mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #ff3d00);
}
.app.pink .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #ff3d00);
}
.app.pink .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(255, 61, 0, 0.87);
}
.app.pink .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #ff3d00);
}
.app.pink .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #ff3d00);
}
.app.pink .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #ff3d00);
}
.app.pink .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #ff3d00);
}
.app.pink .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #ff3d00);
}
.app.pink .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #ff3d00);
}
.app.pink .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #ff3d00);
}
.app.pink .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.app.pink .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.app.pink .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ff3d00);
}
.app.pink .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.app.pink .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.app.pink .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ff3d00);
}
.app.pink .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.app.pink .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.app.pink .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ff3d00);
}
.app.pink .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.app.pink .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.app.pink .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ff3d00);
}
.app.pink .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.app.pink [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.app.pink .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.app.pink .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.pink .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.app.pink .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.app.pink .mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.pink .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.app.pink .mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.pink .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.app.pink .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.app.pink .mdc-list-item--disabled .mdc-list-item__start,
.app.pink .mdc-list-item--disabled .mdc-list-item__content,
.app.pink .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.app.pink .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.pink .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.pink .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.pink .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.app.pink .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.app.pink .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.app.pink .mdc-list-item--selected .mdc-list-item__primary-text,
.app.pink .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #c2185b);
}
.app.pink .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.pink .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #c2185b);
}
.app.pink .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.pink .mdc-list-divider::after {
  border-bottom-color: white;
}
.app.pink .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.pink .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-mdc-select-placeholder {
  color: rgba(0, 0, 0, 0.6);
}
.app.pink .mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.app.pink .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: rgba(194, 24, 91, 0.87);
}
.app.pink .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: rgba(224, 224, 224, 0.87);
}
.app.pink .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
  color: rgba(255, 61, 0, 0.87);
}
.app.pink .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: rgba(255, 61, 0, 0.87);
}
.app.pink .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.app.pink .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.app.pink .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.pink .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.app.pink .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.app.pink .mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.pink .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.app.pink .mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.pink .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.app.pink .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.app.pink .mdc-list-item--disabled .mdc-list-item__start,
.app.pink .mdc-list-item--disabled .mdc-list-item__content,
.app.pink .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.app.pink .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.pink .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.pink .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.pink .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.app.pink .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.app.pink .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.app.pink .mdc-list-item--selected .mdc-list-item__primary-text,
.app.pink .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #c2185b);
}
.app.pink .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.pink .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #c2185b);
}
.app.pink .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.pink .mdc-list-divider::after {
  border-bottom-color: white;
}
.app.pink .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.pink .mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-with-divider-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}
.app.pink .mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
}
.app.pink .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, .app.pink .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #c2185b;
  --mdc-chip-elevated-disabled-container-color: #c2185b;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.app.pink .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected, .app.pink .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-disabled-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-disabled-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-selected-icon-color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected, .app.pink .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #ff3d00;
  --mdc-chip-elevated-disabled-container-color: #ff3d00;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.app.pink .mat-mdc-chip-focus-overlay {
  background: black;
}
.app.pink .mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-color: #fff;
}
.app.pink .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.pink .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #d81b60;
  --mdc-switch-selected-handle-color: #d81b60;
  --mdc-switch-selected-hover-state-layer-color: #d81b60;
  --mdc-switch-selected-pressed-state-layer-color: #d81b60;
  --mdc-switch-selected-focus-handle-color: #880e4f;
  --mdc-switch-selected-hover-handle-color: #880e4f;
  --mdc-switch-selected-pressed-handle-color: #880e4f;
  --mdc-switch-selected-focus-track-color: #f06292;
  --mdc-switch-selected-hover-track-color: #f06292;
  --mdc-switch-selected-pressed-track-color: #f06292;
  --mdc-switch-selected-track-color: #f06292;
}
.app.pink .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #757575;
  --mdc-switch-selected-handle-color: #757575;
  --mdc-switch-selected-hover-state-layer-color: #757575;
  --mdc-switch-selected-pressed-state-layer-color: #757575;
  --mdc-switch-selected-focus-handle-color: #212121;
  --mdc-switch-selected-hover-handle-color: #212121;
  --mdc-switch-selected-pressed-handle-color: #212121;
  --mdc-switch-selected-focus-track-color: #e0e0e0;
  --mdc-switch-selected-hover-track-color: #e0e0e0;
  --mdc-switch-selected-pressed-track-color: #e0e0e0;
  --mdc-switch-selected-track-color: #e0e0e0;
}
.app.pink .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #f4511e;
  --mdc-switch-selected-handle-color: #f4511e;
  --mdc-switch-selected-hover-state-layer-color: #f4511e;
  --mdc-switch-selected-pressed-state-layer-color: #f4511e;
  --mdc-switch-selected-focus-handle-color: #bf360c;
  --mdc-switch-selected-hover-handle-color: #bf360c;
  --mdc-switch-selected-pressed-handle-color: #bf360c;
  --mdc-switch-selected-focus-track-color: #ff8a65;
  --mdc-switch-selected-hover-track-color: #ff8a65;
  --mdc-switch-selected-pressed-track-color: #ff8a65;
  --mdc-switch-selected-track-color: #ff8a65;
}
.app.pink .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.pink .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #c2185b;
  --mdc-radio-selected-hover-icon-color: #c2185b;
  --mdc-radio-selected-icon-color: #c2185b;
  --mdc-radio-selected-pressed-icon-color: #c2185b;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #c2185b;
}
.app.pink .mat-mdc-radio-button.mat-primary .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #e0e0e0;
  --mdc-radio-selected-hover-icon-color: #e0e0e0;
  --mdc-radio-selected-icon-color: #e0e0e0;
  --mdc-radio-selected-pressed-icon-color: #e0e0e0;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #e0e0e0;
}
.app.pink .mat-mdc-radio-button.mat-accent .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ff3d00;
  --mdc-radio-selected-hover-icon-color: #ff3d00;
  --mdc-radio-selected-icon-color: #ff3d00;
  --mdc-radio-selected-pressed-icon-color: #ff3d00;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #ff3d00;
}
.app.pink .mat-mdc-radio-button.mat-warn .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.app.pink .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #c2185b;
  --mdc-slider-focus-handle-color: #c2185b;
  --mdc-slider-hover-handle-color: #c2185b;
  --mdc-slider-active-track-color: #c2185b;
  --mdc-slider-inactive-track-color: #c2185b;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #c2185b;
  --mat-mdc-slider-ripple-color: #c2185b;
  --mat-mdc-slider-hover-ripple-color: rgba(194, 24, 91, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(194, 24, 91, 0.2);
}
.app.pink .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #e0e0e0;
  --mdc-slider-focus-handle-color: #e0e0e0;
  --mdc-slider-hover-handle-color: #e0e0e0;
  --mdc-slider-active-track-color: #e0e0e0;
  --mdc-slider-inactive-track-color: #e0e0e0;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #e0e0e0;
  --mat-mdc-slider-ripple-color: #e0e0e0;
  --mat-mdc-slider-hover-ripple-color: rgba(224, 224, 224, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(224, 224, 224, 0.2);
}
.app.pink .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #ff3d00;
  --mdc-slider-focus-handle-color: #ff3d00;
  --mdc-slider-hover-handle-color: #ff3d00;
  --mdc-slider-active-track-color: #ff3d00;
  --mdc-slider-inactive-track-color: #ff3d00;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #ff3d00;
  --mat-mdc-slider-ripple-color: #ff3d00;
  --mat-mdc-slider-hover-ripple-color: rgba(255, 61, 0, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(255, 61, 0, 0.2);
}
.app.pink .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.app.pink .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.pink .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.app.pink .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.app.pink .mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.pink .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.app.pink .mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.pink .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.app.pink .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.app.pink .mdc-list-item--disabled .mdc-list-item__start,
.app.pink .mdc-list-item--disabled .mdc-list-item__content,
.app.pink .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.app.pink .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.pink .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.pink .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.pink .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.app.pink .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.app.pink .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.app.pink .mdc-list-item--selected .mdc-list-item__primary-text,
.app.pink .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #c2185b);
}
.app.pink .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.pink .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #c2185b);
}
.app.pink .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.pink .mdc-list-divider::after {
  border-bottom-color: white;
}
.app.pink .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.pink .mat-mdc-menu-item[disabled],
.app.pink .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.app.pink .mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38));
}
.app.pink .mat-mdc-menu-item .mat-icon-no-color,
.app.pink .mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.pink .mat-mdc-menu-item:hover:not([disabled]),
.app.pink .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.app.pink .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.app.pink .mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.app.pink .mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}
.app.pink .mat-mdc-list-option .mdc-list-item__start, .app.pink .mat-mdc-list-option .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #c2185b;
  --mdc-checkbox-selected-hover-icon-color: #c2185b;
  --mdc-checkbox-selected-icon-color: #c2185b;
  --mdc-checkbox-selected-pressed-icon-color: #c2185b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.app.pink .mat-mdc-list-option .mdc-list-item__start, .app.pink .mat-mdc-list-option .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #c2185b;
  --mdc-radio-selected-hover-icon-color: #c2185b;
  --mdc-radio-selected-icon-color: #c2185b;
  --mdc-radio-selected-pressed-icon-color: #c2185b;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #c2185b;
}
.app.pink .mat-mdc-list-option .mdc-list-item__start .mdc-radio--disabled + label, .app.pink .mat-mdc-list-option .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-mdc-list-option.mat-accent .mdc-list-item__start, .app.pink .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #e0e0e0;
  --mdc-checkbox-selected-hover-icon-color: #e0e0e0;
  --mdc-checkbox-selected-icon-color: #e0e0e0;
  --mdc-checkbox-selected-pressed-icon-color: #e0e0e0;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.app.pink .mat-mdc-list-option.mat-accent .mdc-list-item__start, .app.pink .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #e0e0e0;
  --mdc-radio-selected-hover-icon-color: #e0e0e0;
  --mdc-radio-selected-icon-color: #e0e0e0;
  --mdc-radio-selected-pressed-icon-color: #e0e0e0;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #e0e0e0;
}
.app.pink .mat-mdc-list-option.mat-accent .mdc-list-item__start .mdc-radio--disabled + label, .app.pink .mat-mdc-list-option.mat-accent .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-mdc-list-option.mat-warn .mdc-list-item__start, .app.pink .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ff3d00;
  --mdc-checkbox-selected-hover-icon-color: #ff3d00;
  --mdc-checkbox-selected-icon-color: #ff3d00;
  --mdc-checkbox-selected-pressed-icon-color: #ff3d00;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.app.pink .mat-mdc-list-option.mat-warn .mdc-list-item__start, .app.pink .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ff3d00;
  --mdc-radio-selected-hover-icon-color: #ff3d00;
  --mdc-radio-selected-icon-color: #ff3d00;
  --mdc-radio-selected-pressed-icon-color: #ff3d00;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #ff3d00;
}
.app.pink .mat-mdc-list-option.mat-warn .mdc-list-item__start .mdc-radio--disabled + label, .app.pink .mat-mdc-list-option.mat-warn .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.app.pink .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #c2185b;
}
.app.pink .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.pink .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #c2185b;
}
.app.pink .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.app.pink .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.app.pink .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.app.pink .mat-mdc-paginator {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.54);
}
.app.pink .mat-mdc-paginator-decrement,
.app.pink .mat-mdc-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.app.pink .mat-mdc-paginator-first,
.app.pink .mat-mdc-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.app.pink .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.app.pink .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.app.pink .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.app.pink .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.pink .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.12);
}
.app.pink .mat-mdc-tab, .app.pink .mat-mdc-tab-link {
  background-color: transparent;
}
.app.pink .mat-mdc-tab .mdc-tab__text-label, .app.pink .mat-mdc-tab-link .mdc-tab__text-label {
  color: rgba(0, 0, 0, 0.6);
}
.app.pink .mat-mdc-tab.mat-mdc-tab-disabled .mdc-tab__ripple::before,
.app.pink .mat-mdc-tab.mat-mdc-tab-disabled .mat-ripple-element, .app.pink .mat-mdc-tab-link.mat-mdc-tab-disabled .mdc-tab__ripple::before,
.app.pink .mat-mdc-tab-link.mat-mdc-tab-disabled .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .app.pink .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #c2185b;
}
.app.pink .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .app.pink .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #c2185b);
}
.app.pink .mdc-tab__ripple::before,
.app.pink .mat-mdc-tab .mat-ripple-element,
.app.pink .mat-mdc-tab-header-pagination .mat-ripple-element,
.app.pink .mat-mdc-tab-link .mat-ripple-element {
  background-color: #c2185b;
}
.app.pink .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .app.pink .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .app.pink .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .app.pink .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #e0e0e0;
}
.app.pink .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .app.pink .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .app.pink .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .app.pink .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #e0e0e0);
}
.app.pink .mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
.app.pink .mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
.app.pink .mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.app.pink .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element, .app.pink .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
.app.pink .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
.app.pink .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.app.pink .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element {
  background-color: #e0e0e0;
}
.app.pink .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .app.pink .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .app.pink .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .app.pink .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #ff3d00;
}
.app.pink .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .app.pink .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .app.pink .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .app.pink .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #ff3d00);
}
.app.pink .mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
.app.pink .mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
.app.pink .mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.app.pink .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element, .app.pink .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
.app.pink .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
.app.pink .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.app.pink .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element {
  background-color: #ff3d00;
}
.app.pink .mat-mdc-tab-group.mat-background-primary, .app.pink .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-mdc-tab-header-with-background-background-color: #c2185b;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.app.pink .mat-mdc-tab-group.mat-background-accent, .app.pink .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-mdc-tab-header-with-background-background-color: #e0e0e0;
  --mat-mdc-tab-header-with-background-foreground-color: #000;
}
.app.pink .mat-mdc-tab-group.mat-background-warn, .app.pink .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-mdc-tab-header-with-background-background-color: #ff3d00;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.app.pink .mat-mdc-tab-header-pagination-chevron {
  border-color: var(--mdc-theme-on-surface, #000);
}
.app.pink .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.pink .mat-mdc-checkbox .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.pink .mat-mdc-checkbox .mdc-checkbox__ripple {
  background: #000;
}
.app.pink .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #c2185b;
  --mdc-checkbox-selected-hover-icon-color: #c2185b;
  --mdc-checkbox-selected-icon-color: #c2185b;
  --mdc-checkbox-selected-pressed-icon-color: #c2185b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.app.pink .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(194, 24, 91, 0.1);
}
.app.pink .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #c2185b;
}
.app.pink .mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #e0e0e0;
  --mdc-checkbox-selected-hover-icon-color: #e0e0e0;
  --mdc-checkbox-selected-icon-color: #e0e0e0;
  --mdc-checkbox-selected-pressed-icon-color: #e0e0e0;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.app.pink .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(224, 224, 224, 0.1);
}
.app.pink .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #e0e0e0;
}
.app.pink .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ff3d00;
  --mdc-checkbox-selected-hover-icon-color: #ff3d00;
  --mdc-checkbox-selected-icon-color: #ff3d00;
  --mdc-checkbox-selected-pressed-icon-color: #ff3d00;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.app.pink .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(255, 61, 0, 0.1);
}
.app.pink .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #ff3d00;
}
.app.pink .mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.app.pink .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #c2185b;
}
.app.pink .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #e0e0e0;
}
.app.pink .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #ff3d00;
}
.app.pink .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.app.pink .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #c2185b;
  --mdc-filled-button-label-text-color: #fff;
}
.app.pink .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #e0e0e0;
  --mdc-filled-button-label-text-color: #000;
}
.app.pink .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #ff3d00;
  --mdc-filled-button-label-text-color: #fff;
}
.app.pink .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.app.pink .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #c2185b;
  --mdc-protected-button-label-text-color: #fff;
}
.app.pink .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #e0e0e0;
  --mdc-protected-button-label-text-color: #000;
}
.app.pink .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #ff3d00;
  --mdc-protected-button-label-text-color: #fff;
}
.app.pink .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}
.app.pink .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.app.pink .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.app.pink .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #c2185b;
}
.app.pink .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #e0e0e0;
}
.app.pink .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #ff3d00;
}
.app.pink .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}
.app.pink .mat-mdc-button, .app.pink .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.pink .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .app.pink .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.pink .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.pink .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .app.pink .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.pink .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.pink .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .app.pink .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.pink .mat-mdc-button.mat-primary, .app.pink .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #c2185b;
  --mat-mdc-button-ripple-color: rgba(194, 24, 91, 0.1);
}
.app.pink .mat-mdc-button.mat-accent, .app.pink .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #e0e0e0;
  --mat-mdc-button-ripple-color: rgba(224, 224, 224, 0.1);
}
.app.pink .mat-mdc-button.mat-warn, .app.pink .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #ff3d00;
  --mat-mdc-button-ripple-color: rgba(255, 61, 0, 0.1);
}
.app.pink .mat-mdc-raised-button, .app.pink .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.pink .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .app.pink .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.pink .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.pink .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .app.pink .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.pink .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.pink .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .app.pink .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.pink .mat-mdc-raised-button.mat-primary, .app.pink .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.pink .mat-mdc-raised-button.mat-accent, .app.pink .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.pink .mat-mdc-raised-button.mat-warn, .app.pink .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.pink .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.pink .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.pink .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.pink .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.pink .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.pink .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #c2185b;
  --mat-mdc-button-ripple-color: rgba(194, 24, 91, 0.1);
}
.app.pink .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #e0e0e0;
  --mat-mdc-button-ripple-color: rgba(224, 224, 224, 0.1);
}
.app.pink .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #ff3d00;
  --mat-mdc-button-ripple-color: rgba(255, 61, 0, 0.1);
}
.app.pink .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #c2185b;
}
.app.pink .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #e0e0e0;
}
.app.pink .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #ff3d00;
}
.app.pink .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-mdc-fab, .app.pink .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.pink .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before, .app.pink .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.pink .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.pink .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .app.pink .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.pink .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.pink .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before, .app.pink .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.pink .mat-mdc-fab.mat-primary, .app.pink .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.pink .mat-mdc-fab.mat-accent, .app.pink .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.pink .mat-mdc-fab.mat-warn, .app.pink .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.pink .mat-mdc-fab.mat-unthemed, .app.pink .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #fff;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.app.pink .mat-mdc-fab.mat-primary, .app.pink .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #c2185b;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.app.pink .mat-mdc-fab.mat-accent, .app.pink .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #e0e0e0;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.app.pink .mat-mdc-fab.mat-warn, .app.pink .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #ff3d00;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.app.pink .mat-mdc-fab[disabled][disabled], .app.pink .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: #e0e0e0;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
}
.app.pink .mdc-data-table {
  background-color: var(--mdc-theme-surface, #fff);
  border-color: rgba(0, 0, 0, 0.12);
}
.app.pink .mdc-data-table__row {
  background-color: inherit;
}
.app.pink .mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, #fff);
}
.app.pink .mdc-data-table__row--selected {
  background-color: rgba(194, 24, 91, 0.04);
}
.app.pink .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.app.pink .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.app.pink .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.pink .mdc-data-table__cell,
.app.pink .mdc-data-table__header-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.app.pink .mdc-data-table__pagination {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.pink .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.pink .mdc-data-table__header-cell {
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .mdc-data-table__pagination-total,
.app.pink .mdc-data-table__pagination-rows-per-page-label,
.app.pink .mdc-data-table__cell {
  color: rgba(0, 0, 0, 0.87);
}
[dir=rtl] .app.pink .mdc-data-table__pagination-button .mdc-button__icon, .app.pink .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.app.pink .mat-mdc-table {
  background: white;
}
.app.pink .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #c2185b;
}
.app.pink .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #e0e0e0;
}
.app.pink .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #ff3d00;
}
.app.pink .mat-badge {
  position: relative;
}
.app.pink .mat-badge.mat-badge {
  overflow: visible;
}
.app.pink .mat-badge-hidden .mat-badge-content {
  display: none;
}
.app.pink .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.app.pink .ng-animate-disabled .mat-badge-content,
.app.pink .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.app.pink .mat-badge-content.mat-badge-active {
  transform: none;
}
.app.pink .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.app.pink .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.app.pink .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.app.pink .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .app.pink .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.app.pink .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .app.pink .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.app.pink .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .app.pink .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.app.pink .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .app.pink .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.app.pink .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.app.pink .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.app.pink .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.app.pink .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .app.pink .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.app.pink .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .app.pink .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.app.pink .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .app.pink .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.app.pink .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .app.pink .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.app.pink .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.app.pink .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.app.pink .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.app.pink .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .app.pink .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.app.pink .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .app.pink .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.app.pink .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .app.pink .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.app.pink .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .app.pink .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.app.pink .mat-badge-content {
  color: white;
  background: #c2185b;
}
.cdk-high-contrast-active .app.pink .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.app.pink .mat-badge-accent .mat-badge-content {
  background: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-badge-warn .mat-badge-content {
  color: white;
  background: #ff3d00;
}
.app.pink .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.app.pink .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.app.pink .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.app.pink .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.pink .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.app.pink .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.app.pink .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.app.pink [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.app.pink .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.app.pink .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.app.pink .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.app.pink .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.app.pink .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.app.pink .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app.pink .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.app.pink .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.app.pink .mat-datepicker-toggle,
.app.pink .mat-datepicker-content .mat-calendar-next-button,
.app.pink .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.app.pink .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.app.pink .mat-calendar-table-header,
.app.pink .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.pink .mat-calendar-body-cell-content,
.app.pink .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.app.pink .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.app.pink .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.app.pink .mat-calendar-body-in-range::before {
  background: rgba(194, 24, 91, 0.2);
}
.app.pink .mat-calendar-body-comparison-identical,
.app.pink .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.pink .mat-calendar-body-comparison-bridge-start::before,
.app.pink [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(194, 24, 91, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.pink .mat-calendar-body-comparison-bridge-end::before,
.app.pink [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(194, 24, 91, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.pink .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.pink .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.pink .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.pink .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.pink .mat-calendar-body-selected {
  background-color: #c2185b;
  color: white;
}
.app.pink .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(194, 24, 91, 0.4);
}
.app.pink .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.pink .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.pink .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(194, 24, 91, 0.3);
}
@media (hover: hover) {
  .app.pink .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(194, 24, 91, 0.3);
  }
}
.app.pink .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(224, 224, 224, 0.2);
}
.app.pink .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.app.pink .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.pink .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.app.pink .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(224, 224, 224, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.pink .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.app.pink .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(224, 224, 224, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.pink .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.pink .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.pink .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.pink .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.pink .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(224, 224, 224, 0.4);
}
.app.pink .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.app.pink .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.pink .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(224, 224, 224, 0.3);
}
@media (hover: hover) {
  .app.pink .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(224, 224, 224, 0.3);
  }
}
.app.pink .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(255, 61, 0, 0.2);
}
.app.pink .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.app.pink .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.pink .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.app.pink .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 61, 0, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.pink .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.app.pink .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 61, 0, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.pink .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.pink .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.pink .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.pink .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.pink .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #ff3d00;
  color: white;
}
.app.pink .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 61, 0, 0.4);
}
.app.pink .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.pink .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.pink .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 61, 0, 0.3);
}
@media (hover: hover) {
  .app.pink .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 61, 0, 0.3);
  }
}
.app.pink .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-datepicker-toggle-active {
  color: #c2185b;
}
.app.pink .mat-datepicker-toggle-active.mat-accent {
  color: #e0e0e0;
}
.app.pink .mat-datepicker-toggle-active.mat-warn {
  color: #ff3d00;
}
.app.pink .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.pink .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.app.pink .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.pink .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .app.pink .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .app.pink .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .app.pink .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.app.pink .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-expansion-panel-header-description,
.app.pink .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.pink .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.app.pink .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.app.pink .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.app.pink .mat-icon.mat-primary {
  color: #c2185b;
}
.app.pink .mat-icon.mat-accent {
  color: #e0e0e0;
}
.app.pink .mat-icon.mat-warn {
  color: #ff3d00;
}
.app.pink .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-drawer.mat-drawer-push {
  background-color: white;
}
.app.pink .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.pink [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.pink [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.app.pink .mat-step-header.cdk-keyboard-focused, .app.pink .mat-step-header.cdk-program-focused, .app.pink .mat-step-header:hover:not([aria-disabled]), .app.pink .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.pink .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .app.pink .mat-step-header:hover {
    background: none;
  }
}
.app.pink .mat-step-header .mat-step-label,
.app.pink .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.app.pink .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.app.pink .mat-step-header .mat-step-icon-selected,
.app.pink .mat-step-header .mat-step-icon-state-done,
.app.pink .mat-step-header .mat-step-icon-state-edit {
  background-color: #c2185b;
  color: white;
}
.app.pink .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-step-header.mat-accent .mat-step-icon-selected,
.app.pink .mat-step-header.mat-accent .mat-step-icon-state-done,
.app.pink .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.app.pink .mat-step-header.mat-warn .mat-step-icon-selected,
.app.pink .mat-step-header.mat-warn .mat-step-icon-state-done,
.app.pink .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #ff3d00;
  color: white;
}
.app.pink .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #ff3d00;
}
.app.pink .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-step-header .mat-step-label.mat-step-label-error {
  color: #ff3d00;
}
.app.pink .mat-stepper-horizontal, .app.pink .mat-stepper-vertical {
  background-color: white;
}
.app.pink .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.app.pink .mat-horizontal-stepper-header::before,
.app.pink .mat-horizontal-stepper-header::after,
.app.pink .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.pink .mat-sort-header-arrow {
  color: #757575;
}
.app.pink .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-toolbar.mat-primary {
  background: #c2185b;
  color: white;
}
.app.pink .mat-toolbar.mat-accent {
  background: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-toolbar.mat-warn {
  background: #ff3d00;
  color: white;
}
.app.pink .mat-toolbar .mat-form-field-underline,
.app.pink .mat-toolbar .mat-form-field-ripple,
.app.pink .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.app.pink .mat-toolbar .mat-form-field-label,
.app.pink .mat-toolbar .mat-focused .mat-form-field-label,
.app.pink .mat-toolbar .mat-select-value,
.app.pink .mat-toolbar .mat-select-arrow,
.app.pink .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.app.pink .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.app.pink .mat-tree {
  background: white;
}
.app.pink .mat-tree-node,
.app.pink .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .bg-primary {
  background: black;
  color: white;
}
.app.pink .bg-accent {
  background: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .bg-warn {
  background: #ff3d00;
  color: white;
}
.app.pink .text-muted {
  color: rgba(0, 0, 0, 0.54) !important;
}
.app.pink .secondary-color {
  color: #f8bbd0;
}
.app.pink .primary-color {
  color: #c2185b;
}
.app.pink .top-navbar.mat-toolbar,
.app.pink .app-dropdown .mat-mdc-menu-item,
.app.pink .app-dropdown .user-info,
.app.pink .account-sidenav .mat-nav-list .mat-mdc-list-item,
.app.pink .product-item .title,
.app.pink .info-bar .mat-mdc-card .content p,
.app.pink .filter-sidenav .mat-expansion-panel-header-title,
.app.pink .mat-tab-body-content,
.app.pink .account-card-title {
  color: #616161 !important;
}
.app.pink .top-toolbar.mat-toolbar-row {
  border-bottom: 1px solid rgba(252, 228, 236, 0.2);
  color: #f8bbd0;
}
.app.pink .top-toolbar.mat-toolbar-row .top-menu a,
.app.pink .top-toolbar.mat-toolbar-row .sidenav-toggle {
  color: #f8bbd0;
}
.app.pink .top-navbar {
  background: #fafafa;
}
.app.pink .border-bottom-mute {
  border-bottom: 1px solid rgba(252, 228, 236, 0.2);
}
.app.pink .search-dropdown.mat-mdc-menu-panel {
  background: transparent;
}
.app.pink .mat-mdc-snack-bar-container.success .mdc-snackbar__surface {
  background: #388E3C;
}
.app.pink .mat-mdc-snack-bar-container.error .mdc-snackbar__surface {
  background: #E53935;
}
.app.pink .new-price {
  color: #ff3d00;
}
.app.pink .primary-text {
  color: #c2185b;
}
.app.pink .active-link {
  background-color: black;
  color: #fff;
}
.app.pink .horizontal-active-link,
.app.pink .app-dropdown .mat-mdc-menu-item.horizontal-active-link {
  color: #c2185b !important;
}
.app.pink .filter-brands button.selected,
.app.pink .filter-buttons button.selected {
  box-shadow: 0px 0px 1px 2px #c2185b;
}
.app.pink .product-pagination .ngx-pagination {
  margin-bottom: 0;
  padding: 14px 10px;
}
.app.pink .product-pagination .ngx-pagination .current {
  background: black;
  color: white;
}
.app.pink .product-pagination .ngx-pagination a:hover, .app.pink .product-pagination .ngx-pagination button:hover {
  background: rgb(165, 165, 165);
  color: black;
}
.app.pink .breadcrumb a {
  color: #6b6b6b;
}
.app.pink .breadcrumb .breadcrumb-item + .breadcrumb-item:before {
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .user-block {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.app.pink .menu-item .mat-mdc-button:not(.active-link) .menu-icon {
  color: black;
}
.app.pink .messages .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label {
  color: rgba(255, 255, 255, 0.6);
}
.app.pink .messages .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: white;
}
.app.purple {
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  /* ngx-pagination */
}
.app.purple .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.purple .mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.purple .mat-mdc-option:hover:not(.mdc-list-item--disabled), .app.purple .mat-mdc-option:focus:not(.mdc-list-item--disabled), .app.purple .mat-mdc-option.mat-mdc-option-active, .app.purple .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app.purple .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #7b1fa2);
}
.app.purple .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #e0e0e0);
}
.app.purple .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #ff5252);
}
.app.purple .mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.purple .mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.app.purple .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.app.purple .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.app.purple .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #7b1fa2;
}
.app.purple .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.app.purple .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #7b1fa2;
}
.app.purple .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.app.purple .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.app.purple .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.app.purple .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #e0e0e0;
}
.app.purple .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.app.purple .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #e0e0e0;
}
.app.purple .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.app.purple .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.app.purple .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.app.purple .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #e0e0e0;
}
.app.purple .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.app.purple .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #e0e0e0;
}
.app.purple .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.app.purple .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.app.purple .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.app.purple .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ff5252;
}
.app.purple .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.app.purple .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ff5252;
}
.app.purple .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.app.purple .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.app.purple .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.app.purple .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.app.purple .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.app.purple .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}
.app.purple .mat-app-background, .app.purple.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-elevation-z0, .app.purple .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z1, .app.purple .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z2, .app.purple .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z3, .app.purple .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z4, .app.purple .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z5, .app.purple .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z6, .app.purple .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z7, .app.purple .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z8, .app.purple .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z9, .app.purple .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z10, .app.purple .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z11, .app.purple .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z12, .app.purple .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z13, .app.purple .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z14, .app.purple .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z15, .app.purple .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z16, .app.purple .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z17, .app.purple .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z18, .app.purple .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z19, .app.purple .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z20, .app.purple .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z21, .app.purple .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z22, .app.purple .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z23, .app.purple .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z24, .app.purple .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.app.purple .mat-mdc-card {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-color: #fff;
}
.app.purple .mat-mdc-card-outlined {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-outline-color: #e0e0e0;
}
.app.purple .mat-mdc-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.app.purple .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #7b1fa2;
}
.app.purple .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(123, 31, 162, 0.25)'/%3E%3C/svg%3E");
}
.app.purple .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(123, 31, 162, 0.25);
}
.app.purple .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #e0e0e0;
}
.app.purple .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(224, 224, 224, 0.25)'/%3E%3C/svg%3E");
}
.app.purple .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(224, 224, 224, 0.25);
}
.app.purple .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #ff5252;
}
.app.purple .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(255, 82, 82, 0.25)'/%3E%3C/svg%3E");
}
.app.purple .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(255, 82, 82, 0.25);
}
.app.purple .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: white;
}
.app.purple .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.app.purple .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87);
}
@media all {
  .app.purple .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
@media all {
  .app.purple .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
.app.purple .mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #7b1fa2);
}
.app.purple .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.app.purple .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.app.purple .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.app.purple .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.app.purple .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.app.purple .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.app.purple .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.app.purple .mdc-text-field--filled .mdc-text-field__ripple::before,
.app.purple .mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
.app.purple .mdc-text-field--filled:hover .mdc-text-field__ripple::before, .app.purple .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.app.purple .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .app.purple .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.app.purple .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: whitesmoke;
}
.app.purple .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.app.purple .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.app.purple .mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-primary, #7b1fa2);
}
.app.purple .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.app.purple .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.app.purple .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.purple .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.app.purple .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.app.purple .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}
.app.purple .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.app.purple .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.app.purple .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #7b1fa2);
}
[dir=rtl] .app.purple .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .app.purple .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .app.purple .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .app.purple .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.app.purple .mdc-text-field--outlined .mdc-text-field__ripple::before,
.app.purple .mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, transparent);
}
[dir=rtl] .app.purple .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .app.purple .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.app.purple .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(123, 31, 162, 0.87);
}
.app.purple .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #ff5252);
}
.app.purple .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #ff5252);
}
.app.purple .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #ff5252);
}
.app.purple .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #ff5252);
}
.app.purple .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #ff5252);
}
.app.purple .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #ff5252);
}
.app.purple .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #ff5252);
}
.app.purple .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.app.purple .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.app.purple .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ff5252);
}
.app.purple .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.app.purple .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.app.purple .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ff5252);
}
.app.purple .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.app.purple .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.app.purple .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ff5252);
}
.app.purple .mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.38);
}
@media all {
  .app.purple .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
@media all {
  .app.purple .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
.app.purple .mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.purple .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.app.purple .mdc-text-field--disabled .mdc-text-field-character-counter,
.app.purple .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.app.purple .mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.app.purple .mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.app.purple .mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.app.purple .mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.app.purple .mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
.app.purple .mdc-text-field--disabled .mdc-notched-outline__leading,
.app.purple .mdc-text-field--disabled .mdc-notched-outline__notch,
.app.purple .mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.purple .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.purple .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.purple .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.purple .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.purple .mdc-text-field--disabled .mdc-text-field-character-counter,
  .app.purple .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.purple .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.purple .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.purple .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.purple .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.purple .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.purple .mdc-text-field--disabled .mdc-notched-outline__leading,
  .app.purple .mdc-text-field--disabled .mdc-notched-outline__notch,
  .app.purple .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.app.purple .mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #fafafa;
}
[dir=rtl] .app.purple .mdc-text-field--end-aligned .mdc-text-field__input, .app.purple .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .app.purple .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker), .app.purple .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.app.purple .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #ff5252);
}
.app.purple .mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}
.app.purple .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}
.app.purple .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.purple .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(123, 31, 162, 0.87);
}
.app.purple .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(224, 224, 224, 0.87);
}
.app.purple .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(255, 82, 82, 0.87);
}
.app.purple .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, #e0e0e0);
}
.app.purple .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-secondary, #e0e0e0);
}
.app.purple .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(224, 224, 224, 0.87);
}
.app.purple .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #ff5252);
}
.app.purple .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #ff5252);
}
.app.purple .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #ff5252);
}
.app.purple .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #ff5252);
}
.app.purple .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #ff5252);
}
.app.purple .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #ff5252);
}
.app.purple .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #ff5252);
}
.app.purple .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.app.purple .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.app.purple .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ff5252);
}
.app.purple .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.app.purple .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.app.purple .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ff5252);
}
.app.purple .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.app.purple .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.app.purple .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ff5252);
}
.app.purple .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.app.purple .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.app.purple .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, #e0e0e0);
}
.app.purple .mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #ff5252);
}
.app.purple .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #ff5252);
}
.app.purple .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(255, 82, 82, 0.87);
}
.app.purple .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #ff5252);
}
.app.purple .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #ff5252);
}
.app.purple .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #ff5252);
}
.app.purple .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #ff5252);
}
.app.purple .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #ff5252);
}
.app.purple .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #ff5252);
}
.app.purple .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #ff5252);
}
.app.purple .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.app.purple .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.app.purple .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ff5252);
}
.app.purple .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.app.purple .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.app.purple .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ff5252);
}
.app.purple .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.app.purple .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.app.purple .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ff5252);
}
.app.purple .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.app.purple .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.app.purple .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ff5252);
}
.app.purple .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.app.purple [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.app.purple .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.app.purple .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.purple .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.app.purple .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.app.purple .mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.purple .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.app.purple .mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.purple .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.app.purple .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.app.purple .mdc-list-item--disabled .mdc-list-item__start,
.app.purple .mdc-list-item--disabled .mdc-list-item__content,
.app.purple .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.app.purple .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.purple .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.purple .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.purple .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.app.purple .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.app.purple .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.app.purple .mdc-list-item--selected .mdc-list-item__primary-text,
.app.purple .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #7b1fa2);
}
.app.purple .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.purple .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #7b1fa2);
}
.app.purple .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.purple .mdc-list-divider::after {
  border-bottom-color: white;
}
.app.purple .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.purple .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-mdc-select-placeholder {
  color: rgba(0, 0, 0, 0.6);
}
.app.purple .mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.app.purple .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: rgba(123, 31, 162, 0.87);
}
.app.purple .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: rgba(224, 224, 224, 0.87);
}
.app.purple .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
  color: rgba(255, 82, 82, 0.87);
}
.app.purple .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: rgba(255, 82, 82, 0.87);
}
.app.purple .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.app.purple .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.app.purple .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.purple .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.app.purple .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.app.purple .mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.purple .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.app.purple .mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.purple .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.app.purple .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.app.purple .mdc-list-item--disabled .mdc-list-item__start,
.app.purple .mdc-list-item--disabled .mdc-list-item__content,
.app.purple .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.app.purple .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.purple .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.purple .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.purple .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.app.purple .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.app.purple .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.app.purple .mdc-list-item--selected .mdc-list-item__primary-text,
.app.purple .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #7b1fa2);
}
.app.purple .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.purple .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #7b1fa2);
}
.app.purple .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.purple .mdc-list-divider::after {
  border-bottom-color: white;
}
.app.purple .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.purple .mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-with-divider-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}
.app.purple .mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
}
.app.purple .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, .app.purple .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #7b1fa2;
  --mdc-chip-elevated-disabled-container-color: #7b1fa2;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.app.purple .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected, .app.purple .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-disabled-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-disabled-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-selected-icon-color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected, .app.purple .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #ff5252;
  --mdc-chip-elevated-disabled-container-color: #ff5252;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.app.purple .mat-mdc-chip-focus-overlay {
  background: black;
}
.app.purple .mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-color: #fff;
}
.app.purple .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.purple .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #8e24aa;
  --mdc-switch-selected-handle-color: #8e24aa;
  --mdc-switch-selected-hover-state-layer-color: #8e24aa;
  --mdc-switch-selected-pressed-state-layer-color: #8e24aa;
  --mdc-switch-selected-focus-handle-color: #4a148c;
  --mdc-switch-selected-hover-handle-color: #4a148c;
  --mdc-switch-selected-pressed-handle-color: #4a148c;
  --mdc-switch-selected-focus-track-color: #ba68c8;
  --mdc-switch-selected-hover-track-color: #ba68c8;
  --mdc-switch-selected-pressed-track-color: #ba68c8;
  --mdc-switch-selected-track-color: #ba68c8;
}
.app.purple .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #757575;
  --mdc-switch-selected-handle-color: #757575;
  --mdc-switch-selected-hover-state-layer-color: #757575;
  --mdc-switch-selected-pressed-state-layer-color: #757575;
  --mdc-switch-selected-focus-handle-color: #212121;
  --mdc-switch-selected-hover-handle-color: #212121;
  --mdc-switch-selected-pressed-handle-color: #212121;
  --mdc-switch-selected-focus-track-color: #e0e0e0;
  --mdc-switch-selected-hover-track-color: #e0e0e0;
  --mdc-switch-selected-pressed-track-color: #e0e0e0;
  --mdc-switch-selected-track-color: #e0e0e0;
}
.app.purple .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e53935;
  --mdc-switch-selected-handle-color: #e53935;
  --mdc-switch-selected-hover-state-layer-color: #e53935;
  --mdc-switch-selected-pressed-state-layer-color: #e53935;
  --mdc-switch-selected-focus-handle-color: #b71c1c;
  --mdc-switch-selected-hover-handle-color: #b71c1c;
  --mdc-switch-selected-pressed-handle-color: #b71c1c;
  --mdc-switch-selected-focus-track-color: #e57373;
  --mdc-switch-selected-hover-track-color: #e57373;
  --mdc-switch-selected-pressed-track-color: #e57373;
  --mdc-switch-selected-track-color: #e57373;
}
.app.purple .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.purple .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #7b1fa2;
  --mdc-radio-selected-hover-icon-color: #7b1fa2;
  --mdc-radio-selected-icon-color: #7b1fa2;
  --mdc-radio-selected-pressed-icon-color: #7b1fa2;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #7b1fa2;
}
.app.purple .mat-mdc-radio-button.mat-primary .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #e0e0e0;
  --mdc-radio-selected-hover-icon-color: #e0e0e0;
  --mdc-radio-selected-icon-color: #e0e0e0;
  --mdc-radio-selected-pressed-icon-color: #e0e0e0;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #e0e0e0;
}
.app.purple .mat-mdc-radio-button.mat-accent .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ff5252;
  --mdc-radio-selected-hover-icon-color: #ff5252;
  --mdc-radio-selected-icon-color: #ff5252;
  --mdc-radio-selected-pressed-icon-color: #ff5252;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #ff5252;
}
.app.purple .mat-mdc-radio-button.mat-warn .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.app.purple .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #7b1fa2;
  --mdc-slider-focus-handle-color: #7b1fa2;
  --mdc-slider-hover-handle-color: #7b1fa2;
  --mdc-slider-active-track-color: #7b1fa2;
  --mdc-slider-inactive-track-color: #7b1fa2;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #7b1fa2;
  --mat-mdc-slider-ripple-color: #7b1fa2;
  --mat-mdc-slider-hover-ripple-color: rgba(123, 31, 162, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(123, 31, 162, 0.2);
}
.app.purple .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #e0e0e0;
  --mdc-slider-focus-handle-color: #e0e0e0;
  --mdc-slider-hover-handle-color: #e0e0e0;
  --mdc-slider-active-track-color: #e0e0e0;
  --mdc-slider-inactive-track-color: #e0e0e0;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #e0e0e0;
  --mat-mdc-slider-ripple-color: #e0e0e0;
  --mat-mdc-slider-hover-ripple-color: rgba(224, 224, 224, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(224, 224, 224, 0.2);
}
.app.purple .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #ff5252;
  --mdc-slider-focus-handle-color: #ff5252;
  --mdc-slider-hover-handle-color: #ff5252;
  --mdc-slider-active-track-color: #ff5252;
  --mdc-slider-inactive-track-color: #ff5252;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #ff5252;
  --mat-mdc-slider-ripple-color: #ff5252;
  --mat-mdc-slider-hover-ripple-color: rgba(255, 82, 82, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(255, 82, 82, 0.2);
}
.app.purple .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.app.purple .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.purple .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.app.purple .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.app.purple .mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.purple .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.app.purple .mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.purple .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.app.purple .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.app.purple .mdc-list-item--disabled .mdc-list-item__start,
.app.purple .mdc-list-item--disabled .mdc-list-item__content,
.app.purple .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.app.purple .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.purple .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.purple .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.purple .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.app.purple .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.app.purple .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.app.purple .mdc-list-item--selected .mdc-list-item__primary-text,
.app.purple .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #7b1fa2);
}
.app.purple .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.purple .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #7b1fa2);
}
.app.purple .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.purple .mdc-list-divider::after {
  border-bottom-color: white;
}
.app.purple .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.purple .mat-mdc-menu-item[disabled],
.app.purple .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.app.purple .mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38));
}
.app.purple .mat-mdc-menu-item .mat-icon-no-color,
.app.purple .mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.purple .mat-mdc-menu-item:hover:not([disabled]),
.app.purple .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.app.purple .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.app.purple .mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.app.purple .mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}
.app.purple .mat-mdc-list-option .mdc-list-item__start, .app.purple .mat-mdc-list-option .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #7b1fa2;
  --mdc-checkbox-selected-hover-icon-color: #7b1fa2;
  --mdc-checkbox-selected-icon-color: #7b1fa2;
  --mdc-checkbox-selected-pressed-icon-color: #7b1fa2;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.app.purple .mat-mdc-list-option .mdc-list-item__start, .app.purple .mat-mdc-list-option .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #7b1fa2;
  --mdc-radio-selected-hover-icon-color: #7b1fa2;
  --mdc-radio-selected-icon-color: #7b1fa2;
  --mdc-radio-selected-pressed-icon-color: #7b1fa2;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #7b1fa2;
}
.app.purple .mat-mdc-list-option .mdc-list-item__start .mdc-radio--disabled + label, .app.purple .mat-mdc-list-option .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-mdc-list-option.mat-accent .mdc-list-item__start, .app.purple .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #e0e0e0;
  --mdc-checkbox-selected-hover-icon-color: #e0e0e0;
  --mdc-checkbox-selected-icon-color: #e0e0e0;
  --mdc-checkbox-selected-pressed-icon-color: #e0e0e0;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.app.purple .mat-mdc-list-option.mat-accent .mdc-list-item__start, .app.purple .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #e0e0e0;
  --mdc-radio-selected-hover-icon-color: #e0e0e0;
  --mdc-radio-selected-icon-color: #e0e0e0;
  --mdc-radio-selected-pressed-icon-color: #e0e0e0;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #e0e0e0;
}
.app.purple .mat-mdc-list-option.mat-accent .mdc-list-item__start .mdc-radio--disabled + label, .app.purple .mat-mdc-list-option.mat-accent .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-mdc-list-option.mat-warn .mdc-list-item__start, .app.purple .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ff5252;
  --mdc-checkbox-selected-hover-icon-color: #ff5252;
  --mdc-checkbox-selected-icon-color: #ff5252;
  --mdc-checkbox-selected-pressed-icon-color: #ff5252;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.app.purple .mat-mdc-list-option.mat-warn .mdc-list-item__start, .app.purple .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ff5252;
  --mdc-radio-selected-hover-icon-color: #ff5252;
  --mdc-radio-selected-icon-color: #ff5252;
  --mdc-radio-selected-pressed-icon-color: #ff5252;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #ff5252;
}
.app.purple .mat-mdc-list-option.mat-warn .mdc-list-item__start .mdc-radio--disabled + label, .app.purple .mat-mdc-list-option.mat-warn .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.app.purple .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #7b1fa2;
}
.app.purple .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.purple .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #7b1fa2;
}
.app.purple .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.app.purple .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.app.purple .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.app.purple .mat-mdc-paginator {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.54);
}
.app.purple .mat-mdc-paginator-decrement,
.app.purple .mat-mdc-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.app.purple .mat-mdc-paginator-first,
.app.purple .mat-mdc-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.app.purple .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.app.purple .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.app.purple .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.app.purple .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.purple .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.12);
}
.app.purple .mat-mdc-tab, .app.purple .mat-mdc-tab-link {
  background-color: transparent;
}
.app.purple .mat-mdc-tab .mdc-tab__text-label, .app.purple .mat-mdc-tab-link .mdc-tab__text-label {
  color: rgba(0, 0, 0, 0.6);
}
.app.purple .mat-mdc-tab.mat-mdc-tab-disabled .mdc-tab__ripple::before,
.app.purple .mat-mdc-tab.mat-mdc-tab-disabled .mat-ripple-element, .app.purple .mat-mdc-tab-link.mat-mdc-tab-disabled .mdc-tab__ripple::before,
.app.purple .mat-mdc-tab-link.mat-mdc-tab-disabled .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .app.purple .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #7b1fa2;
}
.app.purple .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .app.purple .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #7b1fa2);
}
.app.purple .mdc-tab__ripple::before,
.app.purple .mat-mdc-tab .mat-ripple-element,
.app.purple .mat-mdc-tab-header-pagination .mat-ripple-element,
.app.purple .mat-mdc-tab-link .mat-ripple-element {
  background-color: #7b1fa2;
}
.app.purple .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .app.purple .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .app.purple .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .app.purple .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #e0e0e0;
}
.app.purple .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .app.purple .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .app.purple .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .app.purple .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #e0e0e0);
}
.app.purple .mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
.app.purple .mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
.app.purple .mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.app.purple .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element, .app.purple .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
.app.purple .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
.app.purple .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.app.purple .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element {
  background-color: #e0e0e0;
}
.app.purple .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .app.purple .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .app.purple .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .app.purple .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #ff5252;
}
.app.purple .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .app.purple .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .app.purple .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .app.purple .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #ff5252);
}
.app.purple .mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
.app.purple .mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
.app.purple .mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.app.purple .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element, .app.purple .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
.app.purple .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
.app.purple .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.app.purple .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element {
  background-color: #ff5252;
}
.app.purple .mat-mdc-tab-group.mat-background-primary, .app.purple .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-mdc-tab-header-with-background-background-color: #7b1fa2;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.app.purple .mat-mdc-tab-group.mat-background-accent, .app.purple .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-mdc-tab-header-with-background-background-color: #e0e0e0;
  --mat-mdc-tab-header-with-background-foreground-color: #000;
}
.app.purple .mat-mdc-tab-group.mat-background-warn, .app.purple .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-mdc-tab-header-with-background-background-color: #ff5252;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.app.purple .mat-mdc-tab-header-pagination-chevron {
  border-color: var(--mdc-theme-on-surface, #000);
}
.app.purple .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.purple .mat-mdc-checkbox .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.purple .mat-mdc-checkbox .mdc-checkbox__ripple {
  background: #000;
}
.app.purple .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #7b1fa2;
  --mdc-checkbox-selected-hover-icon-color: #7b1fa2;
  --mdc-checkbox-selected-icon-color: #7b1fa2;
  --mdc-checkbox-selected-pressed-icon-color: #7b1fa2;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.app.purple .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(123, 31, 162, 0.1);
}
.app.purple .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #7b1fa2;
}
.app.purple .mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #e0e0e0;
  --mdc-checkbox-selected-hover-icon-color: #e0e0e0;
  --mdc-checkbox-selected-icon-color: #e0e0e0;
  --mdc-checkbox-selected-pressed-icon-color: #e0e0e0;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.app.purple .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(224, 224, 224, 0.1);
}
.app.purple .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #e0e0e0;
}
.app.purple .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ff5252;
  --mdc-checkbox-selected-hover-icon-color: #ff5252;
  --mdc-checkbox-selected-icon-color: #ff5252;
  --mdc-checkbox-selected-pressed-icon-color: #ff5252;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.app.purple .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(255, 82, 82, 0.1);
}
.app.purple .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #ff5252;
}
.app.purple .mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.app.purple .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #7b1fa2;
}
.app.purple .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #e0e0e0;
}
.app.purple .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #ff5252;
}
.app.purple .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.app.purple .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #7b1fa2;
  --mdc-filled-button-label-text-color: #fff;
}
.app.purple .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #e0e0e0;
  --mdc-filled-button-label-text-color: #000;
}
.app.purple .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #ff5252;
  --mdc-filled-button-label-text-color: #fff;
}
.app.purple .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.app.purple .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #7b1fa2;
  --mdc-protected-button-label-text-color: #fff;
}
.app.purple .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #e0e0e0;
  --mdc-protected-button-label-text-color: #000;
}
.app.purple .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #ff5252;
  --mdc-protected-button-label-text-color: #fff;
}
.app.purple .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}
.app.purple .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.app.purple .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.app.purple .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #7b1fa2;
}
.app.purple .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #e0e0e0;
}
.app.purple .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #ff5252;
}
.app.purple .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}
.app.purple .mat-mdc-button, .app.purple .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.purple .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .app.purple .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.purple .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.purple .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .app.purple .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.purple .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.purple .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .app.purple .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.purple .mat-mdc-button.mat-primary, .app.purple .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #7b1fa2;
  --mat-mdc-button-ripple-color: rgba(123, 31, 162, 0.1);
}
.app.purple .mat-mdc-button.mat-accent, .app.purple .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #e0e0e0;
  --mat-mdc-button-ripple-color: rgba(224, 224, 224, 0.1);
}
.app.purple .mat-mdc-button.mat-warn, .app.purple .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #ff5252;
  --mat-mdc-button-ripple-color: rgba(255, 82, 82, 0.1);
}
.app.purple .mat-mdc-raised-button, .app.purple .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.purple .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .app.purple .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.purple .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.purple .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .app.purple .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.purple .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.purple .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .app.purple .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.purple .mat-mdc-raised-button.mat-primary, .app.purple .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.purple .mat-mdc-raised-button.mat-accent, .app.purple .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.purple .mat-mdc-raised-button.mat-warn, .app.purple .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.purple .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.purple .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.purple .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.purple .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.purple .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.purple .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #7b1fa2;
  --mat-mdc-button-ripple-color: rgba(123, 31, 162, 0.1);
}
.app.purple .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #e0e0e0;
  --mat-mdc-button-ripple-color: rgba(224, 224, 224, 0.1);
}
.app.purple .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #ff5252;
  --mat-mdc-button-ripple-color: rgba(255, 82, 82, 0.1);
}
.app.purple .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #7b1fa2;
}
.app.purple .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #e0e0e0;
}
.app.purple .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #ff5252;
}
.app.purple .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-mdc-fab, .app.purple .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.purple .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before, .app.purple .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.purple .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.purple .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .app.purple .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.purple .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.purple .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before, .app.purple .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.purple .mat-mdc-fab.mat-primary, .app.purple .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.purple .mat-mdc-fab.mat-accent, .app.purple .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.purple .mat-mdc-fab.mat-warn, .app.purple .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.purple .mat-mdc-fab.mat-unthemed, .app.purple .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #fff;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.app.purple .mat-mdc-fab.mat-primary, .app.purple .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #7b1fa2;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.app.purple .mat-mdc-fab.mat-accent, .app.purple .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #e0e0e0;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.app.purple .mat-mdc-fab.mat-warn, .app.purple .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #ff5252;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.app.purple .mat-mdc-fab[disabled][disabled], .app.purple .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: #e0e0e0;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
}
.app.purple .mdc-data-table {
  background-color: var(--mdc-theme-surface, #fff);
  border-color: rgba(0, 0, 0, 0.12);
}
.app.purple .mdc-data-table__row {
  background-color: inherit;
}
.app.purple .mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, #fff);
}
.app.purple .mdc-data-table__row--selected {
  background-color: rgba(123, 31, 162, 0.04);
}
.app.purple .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.app.purple .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.app.purple .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.purple .mdc-data-table__cell,
.app.purple .mdc-data-table__header-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.app.purple .mdc-data-table__pagination {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.purple .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.purple .mdc-data-table__header-cell {
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .mdc-data-table__pagination-total,
.app.purple .mdc-data-table__pagination-rows-per-page-label,
.app.purple .mdc-data-table__cell {
  color: rgba(0, 0, 0, 0.87);
}
[dir=rtl] .app.purple .mdc-data-table__pagination-button .mdc-button__icon, .app.purple .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.app.purple .mat-mdc-table {
  background: white;
}
.app.purple .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #7b1fa2;
}
.app.purple .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #e0e0e0;
}
.app.purple .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #ff5252;
}
.app.purple .mat-badge {
  position: relative;
}
.app.purple .mat-badge.mat-badge {
  overflow: visible;
}
.app.purple .mat-badge-hidden .mat-badge-content {
  display: none;
}
.app.purple .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.app.purple .ng-animate-disabled .mat-badge-content,
.app.purple .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.app.purple .mat-badge-content.mat-badge-active {
  transform: none;
}
.app.purple .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.app.purple .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.app.purple .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.app.purple .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .app.purple .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.app.purple .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .app.purple .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.app.purple .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .app.purple .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.app.purple .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .app.purple .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.app.purple .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.app.purple .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.app.purple .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.app.purple .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .app.purple .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.app.purple .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .app.purple .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.app.purple .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .app.purple .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.app.purple .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .app.purple .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.app.purple .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.app.purple .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.app.purple .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.app.purple .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .app.purple .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.app.purple .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .app.purple .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.app.purple .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .app.purple .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.app.purple .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .app.purple .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.app.purple .mat-badge-content {
  color: white;
  background: #7b1fa2;
}
.cdk-high-contrast-active .app.purple .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.app.purple .mat-badge-accent .mat-badge-content {
  background: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-badge-warn .mat-badge-content {
  color: white;
  background: #ff5252;
}
.app.purple .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.app.purple .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.app.purple .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.app.purple .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.purple .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.app.purple .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.app.purple .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.app.purple [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.app.purple .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.app.purple .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.app.purple .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.app.purple .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.app.purple .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.app.purple .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app.purple .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.app.purple .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.app.purple .mat-datepicker-toggle,
.app.purple .mat-datepicker-content .mat-calendar-next-button,
.app.purple .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.app.purple .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.app.purple .mat-calendar-table-header,
.app.purple .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.purple .mat-calendar-body-cell-content,
.app.purple .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.app.purple .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.app.purple .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.app.purple .mat-calendar-body-in-range::before {
  background: rgba(123, 31, 162, 0.2);
}
.app.purple .mat-calendar-body-comparison-identical,
.app.purple .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.purple .mat-calendar-body-comparison-bridge-start::before,
.app.purple [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(123, 31, 162, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.purple .mat-calendar-body-comparison-bridge-end::before,
.app.purple [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(123, 31, 162, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.purple .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.purple .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.purple .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.purple .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.purple .mat-calendar-body-selected {
  background-color: #7b1fa2;
  color: white;
}
.app.purple .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(123, 31, 162, 0.4);
}
.app.purple .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.purple .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.purple .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(123, 31, 162, 0.3);
}
@media (hover: hover) {
  .app.purple .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(123, 31, 162, 0.3);
  }
}
.app.purple .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(224, 224, 224, 0.2);
}
.app.purple .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.app.purple .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.purple .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.app.purple .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(224, 224, 224, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.purple .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.app.purple .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(224, 224, 224, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.purple .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.purple .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.purple .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.purple .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.purple .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(224, 224, 224, 0.4);
}
.app.purple .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.app.purple .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.purple .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(224, 224, 224, 0.3);
}
@media (hover: hover) {
  .app.purple .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(224, 224, 224, 0.3);
  }
}
.app.purple .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(255, 82, 82, 0.2);
}
.app.purple .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.app.purple .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.purple .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.app.purple .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 82, 82, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.purple .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.app.purple .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 82, 82, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.purple .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.purple .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.purple .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.purple .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.purple .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #ff5252;
  color: white;
}
.app.purple .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 82, 82, 0.4);
}
.app.purple .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.purple .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.purple .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 82, 82, 0.3);
}
@media (hover: hover) {
  .app.purple .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 82, 82, 0.3);
  }
}
.app.purple .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-datepicker-toggle-active {
  color: #7b1fa2;
}
.app.purple .mat-datepicker-toggle-active.mat-accent {
  color: #e0e0e0;
}
.app.purple .mat-datepicker-toggle-active.mat-warn {
  color: #ff5252;
}
.app.purple .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.purple .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.app.purple .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.purple .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .app.purple .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .app.purple .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .app.purple .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.app.purple .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-expansion-panel-header-description,
.app.purple .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.purple .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.app.purple .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.app.purple .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.app.purple .mat-icon.mat-primary {
  color: #7b1fa2;
}
.app.purple .mat-icon.mat-accent {
  color: #e0e0e0;
}
.app.purple .mat-icon.mat-warn {
  color: #ff5252;
}
.app.purple .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-drawer.mat-drawer-push {
  background-color: white;
}
.app.purple .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.purple [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.purple [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.app.purple .mat-step-header.cdk-keyboard-focused, .app.purple .mat-step-header.cdk-program-focused, .app.purple .mat-step-header:hover:not([aria-disabled]), .app.purple .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.purple .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .app.purple .mat-step-header:hover {
    background: none;
  }
}
.app.purple .mat-step-header .mat-step-label,
.app.purple .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.app.purple .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.app.purple .mat-step-header .mat-step-icon-selected,
.app.purple .mat-step-header .mat-step-icon-state-done,
.app.purple .mat-step-header .mat-step-icon-state-edit {
  background-color: #7b1fa2;
  color: white;
}
.app.purple .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-step-header.mat-accent .mat-step-icon-selected,
.app.purple .mat-step-header.mat-accent .mat-step-icon-state-done,
.app.purple .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.app.purple .mat-step-header.mat-warn .mat-step-icon-selected,
.app.purple .mat-step-header.mat-warn .mat-step-icon-state-done,
.app.purple .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #ff5252;
  color: white;
}
.app.purple .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #ff5252;
}
.app.purple .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-step-header .mat-step-label.mat-step-label-error {
  color: #ff5252;
}
.app.purple .mat-stepper-horizontal, .app.purple .mat-stepper-vertical {
  background-color: white;
}
.app.purple .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.app.purple .mat-horizontal-stepper-header::before,
.app.purple .mat-horizontal-stepper-header::after,
.app.purple .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.purple .mat-sort-header-arrow {
  color: #757575;
}
.app.purple .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-toolbar.mat-primary {
  background: #7b1fa2;
  color: white;
}
.app.purple .mat-toolbar.mat-accent {
  background: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-toolbar.mat-warn {
  background: #ff5252;
  color: white;
}
.app.purple .mat-toolbar .mat-form-field-underline,
.app.purple .mat-toolbar .mat-form-field-ripple,
.app.purple .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.app.purple .mat-toolbar .mat-form-field-label,
.app.purple .mat-toolbar .mat-focused .mat-form-field-label,
.app.purple .mat-toolbar .mat-select-value,
.app.purple .mat-toolbar .mat-select-arrow,
.app.purple .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.app.purple .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.app.purple .mat-tree {
  background: white;
}
.app.purple .mat-tree-node,
.app.purple .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .bg-primary {
  background: black;
  color: white;
}
.app.purple .bg-accent {
  background: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .bg-warn {
  background: #ff5252;
  color: white;
}
.app.purple .text-muted {
  color: rgba(0, 0, 0, 0.54) !important;
}
.app.purple .secondary-color {
  color: #e1bee7;
}
.app.purple .primary-color {
  color: #7b1fa2;
}
.app.purple .top-navbar.mat-toolbar,
.app.purple .app-dropdown .mat-mdc-menu-item,
.app.purple .app-dropdown .user-info,
.app.purple .account-sidenav .mat-nav-list .mat-mdc-list-item,
.app.purple .product-item .title,
.app.purple .info-bar .mat-mdc-card .content p,
.app.purple .filter-sidenav .mat-expansion-panel-header-title,
.app.purple .mat-tab-body-content,
.app.purple .account-card-title {
  color: #616161 !important;
}
.app.purple .top-toolbar.mat-toolbar-row {
  border-bottom: 1px solid rgba(243, 229, 245, 0.2);
  color: #e1bee7;
}
.app.purple .top-toolbar.mat-toolbar-row .top-menu a,
.app.purple .top-toolbar.mat-toolbar-row .sidenav-toggle {
  color: #e1bee7;
}
.app.purple .top-navbar {
  background: #fafafa;
}
.app.purple .border-bottom-mute {
  border-bottom: 1px solid rgba(243, 229, 245, 0.2);
}
.app.purple .search-dropdown.mat-mdc-menu-panel {
  background: transparent;
}
.app.purple .mat-mdc-snack-bar-container.success .mdc-snackbar__surface {
  background: #388E3C;
}
.app.purple .mat-mdc-snack-bar-container.error .mdc-snackbar__surface {
  background: #E53935;
}
.app.purple .new-price {
  color: #ff5252;
}
.app.purple .primary-text {
  color: #7b1fa2;
}
.app.purple .active-link {
  background-color: black;
  color: #fff;
}
.app.purple .horizontal-active-link,
.app.purple .app-dropdown .mat-mdc-menu-item.horizontal-active-link {
  color: #7b1fa2 !important;
}
.app.purple .filter-brands button.selected,
.app.purple .filter-buttons button.selected {
  box-shadow: 0px 0px 1px 2px #7b1fa2;
}
.app.purple .product-pagination .ngx-pagination {
  margin-bottom: 0;
  padding: 14px 10px;
}
.app.purple .product-pagination .ngx-pagination .current {
  background: black;
  color: white;
}
.app.purple .product-pagination .ngx-pagination a:hover, .app.purple .product-pagination .ngx-pagination button:hover {
  background: rgb(165, 165, 165);
  color: black;
}
.app.purple .breadcrumb a {
  color: #6b6b6b;
}
.app.purple .breadcrumb .breadcrumb-item + .breadcrumb-item:before {
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .user-block {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.app.purple .menu-item .mat-mdc-button:not(.active-link) .menu-icon {
  color: black;
}
.app.purple .messages .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label {
  color: rgba(255, 255, 255, 0.6);
}
.app.purple .messages .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: white;
}
.app.grey {
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  /* ngx-pagination */
}
.app.grey .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.grey .mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.grey .mat-mdc-option:hover:not(.mdc-list-item--disabled), .app.grey .mat-mdc-option:focus:not(.mdc-list-item--disabled), .app.grey .mat-mdc-option.mat-mdc-option-active, .app.grey .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app.grey .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #455a64);
}
.app.grey .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #e0e0e0);
}
.app.grey .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #f44336);
}
.app.grey .mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.grey .mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.app.grey .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.app.grey .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.app.grey .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #455a64;
}
.app.grey .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.app.grey .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #455a64;
}
.app.grey .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.app.grey .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.app.grey .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.app.grey .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #e0e0e0;
}
.app.grey .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.app.grey .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #e0e0e0;
}
.app.grey .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.app.grey .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.app.grey .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.app.grey .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #e0e0e0;
}
.app.grey .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.app.grey .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #e0e0e0;
}
.app.grey .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.app.grey .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.app.grey .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.app.grey .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f44336;
}
.app.grey .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.app.grey .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f44336;
}
.app.grey .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.app.grey .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.app.grey .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.app.grey .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.app.grey .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.app.grey .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}
.app.grey .mat-app-background, .app.grey.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-elevation-z0, .app.grey .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z1, .app.grey .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z2, .app.grey .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z3, .app.grey .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z4, .app.grey .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z5, .app.grey .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z6, .app.grey .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z7, .app.grey .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z8, .app.grey .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z9, .app.grey .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z10, .app.grey .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z11, .app.grey .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z12, .app.grey .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z13, .app.grey .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z14, .app.grey .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z15, .app.grey .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z16, .app.grey .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z17, .app.grey .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z18, .app.grey .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z19, .app.grey .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z20, .app.grey .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z21, .app.grey .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z22, .app.grey .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z23, .app.grey .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z24, .app.grey .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.app.grey .mat-mdc-card {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-color: #fff;
}
.app.grey .mat-mdc-card-outlined {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-outline-color: #e0e0e0;
}
.app.grey .mat-mdc-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.app.grey .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #455a64;
}
.app.grey .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(69, 90, 100, 0.25)'/%3E%3C/svg%3E");
}
.app.grey .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(69, 90, 100, 0.25);
}
.app.grey .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #e0e0e0;
}
.app.grey .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(224, 224, 224, 0.25)'/%3E%3C/svg%3E");
}
.app.grey .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(224, 224, 224, 0.25);
}
.app.grey .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
}
.app.grey .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(244, 67, 54, 0.25)'/%3E%3C/svg%3E");
}
.app.grey .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(244, 67, 54, 0.25);
}
.app.grey .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: white;
}
.app.grey .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.app.grey .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87);
}
@media all {
  .app.grey .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
@media all {
  .app.grey .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
.app.grey .mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #455a64);
}
.app.grey .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.app.grey .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.app.grey .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.app.grey .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.app.grey .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.app.grey .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.app.grey .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.app.grey .mdc-text-field--filled .mdc-text-field__ripple::before,
.app.grey .mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
.app.grey .mdc-text-field--filled:hover .mdc-text-field__ripple::before, .app.grey .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.app.grey .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .app.grey .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.app.grey .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: whitesmoke;
}
.app.grey .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.app.grey .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.app.grey .mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-primary, #455a64);
}
.app.grey .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.app.grey .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.app.grey .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.grey .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.app.grey .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.app.grey .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}
.app.grey .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.app.grey .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.app.grey .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #455a64);
}
[dir=rtl] .app.grey .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .app.grey .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .app.grey .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .app.grey .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.app.grey .mdc-text-field--outlined .mdc-text-field__ripple::before,
.app.grey .mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, transparent);
}
[dir=rtl] .app.grey .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .app.grey .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.app.grey .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(69, 90, 100, 0.87);
}
.app.grey .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.app.grey .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.app.grey .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.app.grey .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.app.grey .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.app.grey .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.app.grey .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.app.grey .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.app.grey .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.app.grey .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.app.grey .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.app.grey .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.app.grey .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.app.grey .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.app.grey .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.app.grey .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.app.grey .mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.38);
}
@media all {
  .app.grey .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
@media all {
  .app.grey .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
.app.grey .mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.grey .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.app.grey .mdc-text-field--disabled .mdc-text-field-character-counter,
.app.grey .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.app.grey .mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.app.grey .mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.app.grey .mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.app.grey .mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.app.grey .mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
.app.grey .mdc-text-field--disabled .mdc-notched-outline__leading,
.app.grey .mdc-text-field--disabled .mdc-notched-outline__notch,
.app.grey .mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.grey .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.grey .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.grey .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.grey .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.grey .mdc-text-field--disabled .mdc-text-field-character-counter,
  .app.grey .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.grey .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.grey .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.grey .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.grey .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.grey .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .app.grey .mdc-text-field--disabled .mdc-notched-outline__leading,
  .app.grey .mdc-text-field--disabled .mdc-notched-outline__notch,
  .app.grey .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.app.grey .mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #fafafa;
}
[dir=rtl] .app.grey .mdc-text-field--end-aligned .mdc-text-field__input, .app.grey .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .app.grey .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker), .app.grey .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.app.grey .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #f44336);
}
.app.grey .mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}
.app.grey .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}
.app.grey .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.grey .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(69, 90, 100, 0.87);
}
.app.grey .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(224, 224, 224, 0.87);
}
.app.grey .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(244, 67, 54, 0.87);
}
.app.grey .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, #e0e0e0);
}
.app.grey .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-secondary, #e0e0e0);
}
.app.grey .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(224, 224, 224, 0.87);
}
.app.grey .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.app.grey .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.app.grey .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.app.grey .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.app.grey .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.app.grey .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.app.grey .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.app.grey .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.app.grey .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.app.grey .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.app.grey .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.app.grey .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.app.grey .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.app.grey .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.app.grey .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.app.grey .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.app.grey .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.app.grey .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.app.grey .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, #e0e0e0);
}
.app.grey .mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.app.grey .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.app.grey .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(244, 67, 54, 0.87);
}
.app.grey .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.app.grey .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.app.grey .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.app.grey .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.app.grey .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.app.grey .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.app.grey .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.app.grey .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.app.grey .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.app.grey .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.app.grey .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.app.grey .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.app.grey .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.app.grey .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.app.grey .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.app.grey .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.app.grey .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.app.grey .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.app.grey .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.app.grey .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.app.grey [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.app.grey .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.app.grey .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.grey .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.app.grey .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.app.grey .mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.grey .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.app.grey .mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.grey .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.app.grey .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.app.grey .mdc-list-item--disabled .mdc-list-item__start,
.app.grey .mdc-list-item--disabled .mdc-list-item__content,
.app.grey .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.app.grey .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.grey .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.grey .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.grey .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.app.grey .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.app.grey .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.app.grey .mdc-list-item--selected .mdc-list-item__primary-text,
.app.grey .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #455a64);
}
.app.grey .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.grey .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #455a64);
}
.app.grey .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.grey .mdc-list-divider::after {
  border-bottom-color: white;
}
.app.grey .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.grey .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-mdc-select-placeholder {
  color: rgba(0, 0, 0, 0.6);
}
.app.grey .mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.app.grey .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: rgba(69, 90, 100, 0.87);
}
.app.grey .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: rgba(224, 224, 224, 0.87);
}
.app.grey .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
  color: rgba(244, 67, 54, 0.87);
}
.app.grey .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: rgba(244, 67, 54, 0.87);
}
.app.grey .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.app.grey .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.app.grey .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.grey .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.app.grey .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.app.grey .mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.grey .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.app.grey .mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.grey .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.app.grey .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.app.grey .mdc-list-item--disabled .mdc-list-item__start,
.app.grey .mdc-list-item--disabled .mdc-list-item__content,
.app.grey .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.app.grey .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.grey .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.grey .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.grey .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.app.grey .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.app.grey .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.app.grey .mdc-list-item--selected .mdc-list-item__primary-text,
.app.grey .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #455a64);
}
.app.grey .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.grey .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #455a64);
}
.app.grey .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.grey .mdc-list-divider::after {
  border-bottom-color: white;
}
.app.grey .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.grey .mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-with-divider-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}
.app.grey .mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
}
.app.grey .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, .app.grey .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #455a64;
  --mdc-chip-elevated-disabled-container-color: #455a64;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.app.grey .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected, .app.grey .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-disabled-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-disabled-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-selected-icon-color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected, .app.grey .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.app.grey .mat-mdc-chip-focus-overlay {
  background: black;
}
.app.grey .mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-color: #fff;
}
.app.grey .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.grey .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #546e7a;
  --mdc-switch-selected-handle-color: #546e7a;
  --mdc-switch-selected-hover-state-layer-color: #546e7a;
  --mdc-switch-selected-pressed-state-layer-color: #546e7a;
  --mdc-switch-selected-focus-handle-color: #263238;
  --mdc-switch-selected-hover-handle-color: #263238;
  --mdc-switch-selected-pressed-handle-color: #263238;
  --mdc-switch-selected-focus-track-color: #90a4ae;
  --mdc-switch-selected-hover-track-color: #90a4ae;
  --mdc-switch-selected-pressed-track-color: #90a4ae;
  --mdc-switch-selected-track-color: #90a4ae;
}
.app.grey .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #757575;
  --mdc-switch-selected-handle-color: #757575;
  --mdc-switch-selected-hover-state-layer-color: #757575;
  --mdc-switch-selected-pressed-state-layer-color: #757575;
  --mdc-switch-selected-focus-handle-color: #212121;
  --mdc-switch-selected-hover-handle-color: #212121;
  --mdc-switch-selected-pressed-handle-color: #212121;
  --mdc-switch-selected-focus-track-color: #e0e0e0;
  --mdc-switch-selected-hover-track-color: #e0e0e0;
  --mdc-switch-selected-pressed-track-color: #e0e0e0;
  --mdc-switch-selected-track-color: #e0e0e0;
}
.app.grey .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e53935;
  --mdc-switch-selected-handle-color: #e53935;
  --mdc-switch-selected-hover-state-layer-color: #e53935;
  --mdc-switch-selected-pressed-state-layer-color: #e53935;
  --mdc-switch-selected-focus-handle-color: #b71c1c;
  --mdc-switch-selected-hover-handle-color: #b71c1c;
  --mdc-switch-selected-pressed-handle-color: #b71c1c;
  --mdc-switch-selected-focus-track-color: #e57373;
  --mdc-switch-selected-hover-track-color: #e57373;
  --mdc-switch-selected-pressed-track-color: #e57373;
  --mdc-switch-selected-track-color: #e57373;
}
.app.grey .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.grey .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #455a64;
  --mdc-radio-selected-hover-icon-color: #455a64;
  --mdc-radio-selected-icon-color: #455a64;
  --mdc-radio-selected-pressed-icon-color: #455a64;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #455a64;
}
.app.grey .mat-mdc-radio-button.mat-primary .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #e0e0e0;
  --mdc-radio-selected-hover-icon-color: #e0e0e0;
  --mdc-radio-selected-icon-color: #e0e0e0;
  --mdc-radio-selected-pressed-icon-color: #e0e0e0;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #e0e0e0;
}
.app.grey .mat-mdc-radio-button.mat-accent .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #f44336;
}
.app.grey .mat-mdc-radio-button.mat-warn .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.app.grey .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #455a64;
  --mdc-slider-focus-handle-color: #455a64;
  --mdc-slider-hover-handle-color: #455a64;
  --mdc-slider-active-track-color: #455a64;
  --mdc-slider-inactive-track-color: #455a64;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #455a64;
  --mat-mdc-slider-ripple-color: #455a64;
  --mat-mdc-slider-hover-ripple-color: rgba(69, 90, 100, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(69, 90, 100, 0.2);
}
.app.grey .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #e0e0e0;
  --mdc-slider-focus-handle-color: #e0e0e0;
  --mdc-slider-hover-handle-color: #e0e0e0;
  --mdc-slider-active-track-color: #e0e0e0;
  --mdc-slider-inactive-track-color: #e0e0e0;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #e0e0e0;
  --mat-mdc-slider-ripple-color: #e0e0e0;
  --mat-mdc-slider-hover-ripple-color: rgba(224, 224, 224, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(224, 224, 224, 0.2);
}
.app.grey .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mat-mdc-slider-ripple-color: #f44336;
  --mat-mdc-slider-hover-ripple-color: rgba(244, 67, 54, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(244, 67, 54, 0.2);
}
.app.grey .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.app.grey .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.grey .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.app.grey .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.app.grey .mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.grey .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.app.grey .mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.grey .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.app.grey .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.app.grey .mdc-list-item--disabled .mdc-list-item__start,
.app.grey .mdc-list-item--disabled .mdc-list-item__content,
.app.grey .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.app.grey .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.grey .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.grey .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.app.grey .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.app.grey .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.app.grey .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.app.grey .mdc-list-item--selected .mdc-list-item__primary-text,
.app.grey .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #455a64);
}
.app.grey .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.grey .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #455a64);
}
.app.grey .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.grey .mdc-list-divider::after {
  border-bottom-color: white;
}
.app.grey .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.grey .mat-mdc-menu-item[disabled],
.app.grey .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.app.grey .mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38));
}
.app.grey .mat-mdc-menu-item .mat-icon-no-color,
.app.grey .mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.grey .mat-mdc-menu-item:hover:not([disabled]),
.app.grey .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.app.grey .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.app.grey .mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.app.grey .mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}
.app.grey .mat-mdc-list-option .mdc-list-item__start, .app.grey .mat-mdc-list-option .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #455a64;
  --mdc-checkbox-selected-hover-icon-color: #455a64;
  --mdc-checkbox-selected-icon-color: #455a64;
  --mdc-checkbox-selected-pressed-icon-color: #455a64;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.app.grey .mat-mdc-list-option .mdc-list-item__start, .app.grey .mat-mdc-list-option .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #455a64;
  --mdc-radio-selected-hover-icon-color: #455a64;
  --mdc-radio-selected-icon-color: #455a64;
  --mdc-radio-selected-pressed-icon-color: #455a64;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #455a64;
}
.app.grey .mat-mdc-list-option .mdc-list-item__start .mdc-radio--disabled + label, .app.grey .mat-mdc-list-option .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-mdc-list-option.mat-accent .mdc-list-item__start, .app.grey .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #e0e0e0;
  --mdc-checkbox-selected-hover-icon-color: #e0e0e0;
  --mdc-checkbox-selected-icon-color: #e0e0e0;
  --mdc-checkbox-selected-pressed-icon-color: #e0e0e0;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.app.grey .mat-mdc-list-option.mat-accent .mdc-list-item__start, .app.grey .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #e0e0e0;
  --mdc-radio-selected-hover-icon-color: #e0e0e0;
  --mdc-radio-selected-icon-color: #e0e0e0;
  --mdc-radio-selected-pressed-icon-color: #e0e0e0;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #e0e0e0;
}
.app.grey .mat-mdc-list-option.mat-accent .mdc-list-item__start .mdc-radio--disabled + label, .app.grey .mat-mdc-list-option.mat-accent .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-mdc-list-option.mat-warn .mdc-list-item__start, .app.grey .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.app.grey .mat-mdc-list-option.mat-warn .mdc-list-item__start, .app.grey .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #f44336;
}
.app.grey .mat-mdc-list-option.mat-warn .mdc-list-item__start .mdc-radio--disabled + label, .app.grey .mat-mdc-list-option.mat-warn .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.app.grey .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #455a64;
}
.app.grey .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.app.grey .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #455a64;
}
.app.grey .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.app.grey .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.app.grey .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.app.grey .mat-mdc-paginator {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.54);
}
.app.grey .mat-mdc-paginator-decrement,
.app.grey .mat-mdc-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.app.grey .mat-mdc-paginator-first,
.app.grey .mat-mdc-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.app.grey .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.app.grey .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.app.grey .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.app.grey .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.grey .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.12);
}
.app.grey .mat-mdc-tab, .app.grey .mat-mdc-tab-link {
  background-color: transparent;
}
.app.grey .mat-mdc-tab .mdc-tab__text-label, .app.grey .mat-mdc-tab-link .mdc-tab__text-label {
  color: rgba(0, 0, 0, 0.6);
}
.app.grey .mat-mdc-tab.mat-mdc-tab-disabled .mdc-tab__ripple::before,
.app.grey .mat-mdc-tab.mat-mdc-tab-disabled .mat-ripple-element, .app.grey .mat-mdc-tab-link.mat-mdc-tab-disabled .mdc-tab__ripple::before,
.app.grey .mat-mdc-tab-link.mat-mdc-tab-disabled .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .app.grey .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #455a64;
}
.app.grey .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .app.grey .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #455a64);
}
.app.grey .mdc-tab__ripple::before,
.app.grey .mat-mdc-tab .mat-ripple-element,
.app.grey .mat-mdc-tab-header-pagination .mat-ripple-element,
.app.grey .mat-mdc-tab-link .mat-ripple-element {
  background-color: #455a64;
}
.app.grey .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .app.grey .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .app.grey .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .app.grey .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #e0e0e0;
}
.app.grey .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .app.grey .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .app.grey .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .app.grey .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #e0e0e0);
}
.app.grey .mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
.app.grey .mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
.app.grey .mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.app.grey .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element, .app.grey .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
.app.grey .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
.app.grey .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.app.grey .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element {
  background-color: #e0e0e0;
}
.app.grey .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .app.grey .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .app.grey .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .app.grey .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #f44336;
}
.app.grey .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .app.grey .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .app.grey .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .app.grey .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #f44336);
}
.app.grey .mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
.app.grey .mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
.app.grey .mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.app.grey .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element, .app.grey .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
.app.grey .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
.app.grey .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.app.grey .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element {
  background-color: #f44336;
}
.app.grey .mat-mdc-tab-group.mat-background-primary, .app.grey .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-mdc-tab-header-with-background-background-color: #455a64;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.app.grey .mat-mdc-tab-group.mat-background-accent, .app.grey .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-mdc-tab-header-with-background-background-color: #e0e0e0;
  --mat-mdc-tab-header-with-background-foreground-color: #000;
}
.app.grey .mat-mdc-tab-group.mat-background-warn, .app.grey .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-mdc-tab-header-with-background-background-color: #f44336;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.app.grey .mat-mdc-tab-header-pagination-chevron {
  border-color: var(--mdc-theme-on-surface, #000);
}
.app.grey .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.app.grey .mat-mdc-checkbox .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.grey .mat-mdc-checkbox .mdc-checkbox__ripple {
  background: #000;
}
.app.grey .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #455a64;
  --mdc-checkbox-selected-hover-icon-color: #455a64;
  --mdc-checkbox-selected-icon-color: #455a64;
  --mdc-checkbox-selected-pressed-icon-color: #455a64;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.app.grey .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(69, 90, 100, 0.1);
}
.app.grey .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #455a64;
}
.app.grey .mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #e0e0e0;
  --mdc-checkbox-selected-hover-icon-color: #e0e0e0;
  --mdc-checkbox-selected-icon-color: #e0e0e0;
  --mdc-checkbox-selected-pressed-icon-color: #e0e0e0;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.app.grey .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(224, 224, 224, 0.1);
}
.app.grey .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #e0e0e0;
}
.app.grey .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.app.grey .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(244, 67, 54, 0.1);
}
.app.grey .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #f44336;
}
.app.grey .mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.app.grey .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #455a64;
}
.app.grey .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #e0e0e0;
}
.app.grey .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
}
.app.grey .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.app.grey .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #455a64;
  --mdc-filled-button-label-text-color: #fff;
}
.app.grey .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #e0e0e0;
  --mdc-filled-button-label-text-color: #000;
}
.app.grey .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: #fff;
}
.app.grey .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.app.grey .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #455a64;
  --mdc-protected-button-label-text-color: #fff;
}
.app.grey .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #e0e0e0;
  --mdc-protected-button-label-text-color: #000;
}
.app.grey .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: #fff;
}
.app.grey .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}
.app.grey .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.app.grey .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.app.grey .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #455a64;
}
.app.grey .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #e0e0e0;
}
.app.grey .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
}
.app.grey .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}
.app.grey .mat-mdc-button, .app.grey .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.grey .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .app.grey .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.grey .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.grey .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .app.grey .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.grey .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.grey .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .app.grey .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.grey .mat-mdc-button.mat-primary, .app.grey .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #455a64;
  --mat-mdc-button-ripple-color: rgba(69, 90, 100, 0.1);
}
.app.grey .mat-mdc-button.mat-accent, .app.grey .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #e0e0e0;
  --mat-mdc-button-ripple-color: rgba(224, 224, 224, 0.1);
}
.app.grey .mat-mdc-button.mat-warn, .app.grey .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.app.grey .mat-mdc-raised-button, .app.grey .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.grey .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .app.grey .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.grey .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.grey .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .app.grey .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.grey .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.grey .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .app.grey .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.grey .mat-mdc-raised-button.mat-primary, .app.grey .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.grey .mat-mdc-raised-button.mat-accent, .app.grey .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.grey .mat-mdc-raised-button.mat-warn, .app.grey .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.grey .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.grey .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.grey .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.grey .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.grey .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.grey .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #455a64;
  --mat-mdc-button-ripple-color: rgba(69, 90, 100, 0.1);
}
.app.grey .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #e0e0e0;
  --mat-mdc-button-ripple-color: rgba(224, 224, 224, 0.1);
}
.app.grey .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.app.grey .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #455a64;
}
.app.grey .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #e0e0e0;
}
.app.grey .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
}
.app.grey .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-mdc-fab, .app.grey .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.grey .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before, .app.grey .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.grey .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.grey .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .app.grey .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.grey .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.grey .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before, .app.grey .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.grey .mat-mdc-fab.mat-primary, .app.grey .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.grey .mat-mdc-fab.mat-accent, .app.grey .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.grey .mat-mdc-fab.mat-warn, .app.grey .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.grey .mat-mdc-fab.mat-unthemed, .app.grey .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #fff;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.app.grey .mat-mdc-fab.mat-primary, .app.grey .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #455a64;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.app.grey .mat-mdc-fab.mat-accent, .app.grey .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #e0e0e0;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.app.grey .mat-mdc-fab.mat-warn, .app.grey .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.app.grey .mat-mdc-fab[disabled][disabled], .app.grey .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: #e0e0e0;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
}
.app.grey .mdc-data-table {
  background-color: var(--mdc-theme-surface, #fff);
  border-color: rgba(0, 0, 0, 0.12);
}
.app.grey .mdc-data-table__row {
  background-color: inherit;
}
.app.grey .mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, #fff);
}
.app.grey .mdc-data-table__row--selected {
  background-color: rgba(69, 90, 100, 0.04);
}
.app.grey .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.app.grey .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.app.grey .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.grey .mdc-data-table__cell,
.app.grey .mdc-data-table__header-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.app.grey .mdc-data-table__pagination {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.grey .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.grey .mdc-data-table__header-cell {
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .mdc-data-table__pagination-total,
.app.grey .mdc-data-table__pagination-rows-per-page-label,
.app.grey .mdc-data-table__cell {
  color: rgba(0, 0, 0, 0.87);
}
[dir=rtl] .app.grey .mdc-data-table__pagination-button .mdc-button__icon, .app.grey .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.app.grey .mat-mdc-table {
  background: white;
}
.app.grey .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #455a64;
}
.app.grey .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #e0e0e0;
}
.app.grey .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}
.app.grey .mat-badge {
  position: relative;
}
.app.grey .mat-badge.mat-badge {
  overflow: visible;
}
.app.grey .mat-badge-hidden .mat-badge-content {
  display: none;
}
.app.grey .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.app.grey .ng-animate-disabled .mat-badge-content,
.app.grey .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.app.grey .mat-badge-content.mat-badge-active {
  transform: none;
}
.app.grey .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.app.grey .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.app.grey .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.app.grey .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .app.grey .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.app.grey .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .app.grey .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.app.grey .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .app.grey .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.app.grey .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .app.grey .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.app.grey .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.app.grey .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.app.grey .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.app.grey .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .app.grey .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.app.grey .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .app.grey .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.app.grey .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .app.grey .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.app.grey .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .app.grey .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.app.grey .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.app.grey .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.app.grey .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.app.grey .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .app.grey .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.app.grey .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .app.grey .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.app.grey .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .app.grey .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.app.grey .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .app.grey .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.app.grey .mat-badge-content {
  color: white;
  background: #455a64;
}
.cdk-high-contrast-active .app.grey .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.app.grey .mat-badge-accent .mat-badge-content {
  background: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
.app.grey .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.app.grey .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.app.grey .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.app.grey .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.grey .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.app.grey .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.app.grey .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.app.grey [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.app.grey .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.app.grey .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.app.grey .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.app.grey .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.app.grey .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.app.grey .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app.grey .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.app.grey .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.app.grey .mat-datepicker-toggle,
.app.grey .mat-datepicker-content .mat-calendar-next-button,
.app.grey .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.app.grey .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.app.grey .mat-calendar-table-header,
.app.grey .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.grey .mat-calendar-body-cell-content,
.app.grey .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.app.grey .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.app.grey .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.app.grey .mat-calendar-body-in-range::before {
  background: rgba(69, 90, 100, 0.2);
}
.app.grey .mat-calendar-body-comparison-identical,
.app.grey .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.grey .mat-calendar-body-comparison-bridge-start::before,
.app.grey [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(69, 90, 100, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.grey .mat-calendar-body-comparison-bridge-end::before,
.app.grey [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(69, 90, 100, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.grey .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.grey .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.grey .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.grey .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.grey .mat-calendar-body-selected {
  background-color: #455a64;
  color: white;
}
.app.grey .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(69, 90, 100, 0.4);
}
.app.grey .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.grey .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.grey .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(69, 90, 100, 0.3);
}
@media (hover: hover) {
  .app.grey .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(69, 90, 100, 0.3);
  }
}
.app.grey .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(224, 224, 224, 0.2);
}
.app.grey .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.app.grey .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.grey .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.app.grey .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(224, 224, 224, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.grey .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.app.grey .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(224, 224, 224, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.grey .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.grey .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.grey .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.grey .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.grey .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(224, 224, 224, 0.4);
}
.app.grey .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.app.grey .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.grey .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(224, 224, 224, 0.3);
}
@media (hover: hover) {
  .app.grey .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(224, 224, 224, 0.3);
  }
}
.app.grey .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.app.grey .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.app.grey .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.grey .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.app.grey .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.grey .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.app.grey .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.grey .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.grey .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.grey .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.grey .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.grey .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.app.grey .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.app.grey .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.grey .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.grey .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .app.grey .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}
.app.grey .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-datepicker-toggle-active {
  color: #455a64;
}
.app.grey .mat-datepicker-toggle-active.mat-accent {
  color: #e0e0e0;
}
.app.grey .mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
.app.grey .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.grey .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.app.grey .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.grey .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .app.grey .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .app.grey .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .app.grey .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.app.grey .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-expansion-panel-header-description,
.app.grey .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.grey .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.app.grey .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.app.grey .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.app.grey .mat-icon.mat-primary {
  color: #455a64;
}
.app.grey .mat-icon.mat-accent {
  color: #e0e0e0;
}
.app.grey .mat-icon.mat-warn {
  color: #f44336;
}
.app.grey .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-drawer.mat-drawer-push {
  background-color: white;
}
.app.grey .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.grey [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.grey [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.app.grey .mat-step-header.cdk-keyboard-focused, .app.grey .mat-step-header.cdk-program-focused, .app.grey .mat-step-header:hover:not([aria-disabled]), .app.grey .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.grey .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .app.grey .mat-step-header:hover {
    background: none;
  }
}
.app.grey .mat-step-header .mat-step-label,
.app.grey .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.app.grey .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.app.grey .mat-step-header .mat-step-icon-selected,
.app.grey .mat-step-header .mat-step-icon-state-done,
.app.grey .mat-step-header .mat-step-icon-state-edit {
  background-color: #455a64;
  color: white;
}
.app.grey .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-step-header.mat-accent .mat-step-icon-selected,
.app.grey .mat-step-header.mat-accent .mat-step-icon-state-done,
.app.grey .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.app.grey .mat-step-header.mat-warn .mat-step-icon-selected,
.app.grey .mat-step-header.mat-warn .mat-step-icon-state-done,
.app.grey .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.app.grey .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.app.grey .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
.app.grey .mat-stepper-horizontal, .app.grey .mat-stepper-vertical {
  background-color: white;
}
.app.grey .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.app.grey .mat-horizontal-stepper-header::before,
.app.grey .mat-horizontal-stepper-header::after,
.app.grey .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.grey .mat-sort-header-arrow {
  color: #757575;
}
.app.grey .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-toolbar.mat-primary {
  background: #455a64;
  color: white;
}
.app.grey .mat-toolbar.mat-accent {
  background: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.app.grey .mat-toolbar .mat-form-field-underline,
.app.grey .mat-toolbar .mat-form-field-ripple,
.app.grey .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.app.grey .mat-toolbar .mat-form-field-label,
.app.grey .mat-toolbar .mat-focused .mat-form-field-label,
.app.grey .mat-toolbar .mat-select-value,
.app.grey .mat-toolbar .mat-select-arrow,
.app.grey .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.app.grey .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.app.grey .mat-tree {
  background: white;
}
.app.grey .mat-tree-node,
.app.grey .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .bg-primary {
  background: black;
  color: white;
}
.app.grey .bg-accent {
  background: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .bg-warn {
  background: #f44336;
  color: white;
}
.app.grey .text-muted {
  color: rgba(0, 0, 0, 0.54) !important;
}
.app.grey .secondary-color {
  color: #cfd8dc;
}
.app.grey .primary-color {
  color: #455a64;
}
.app.grey .top-navbar.mat-toolbar,
.app.grey .app-dropdown .mat-mdc-menu-item,
.app.grey .app-dropdown .user-info,
.app.grey .account-sidenav .mat-nav-list .mat-mdc-list-item,
.app.grey .product-item .title,
.app.grey .info-bar .mat-mdc-card .content p,
.app.grey .filter-sidenav .mat-expansion-panel-header-title,
.app.grey .mat-tab-body-content,
.app.grey .account-card-title {
  color: #616161 !important;
}
.app.grey .top-toolbar.mat-toolbar-row {
  border-bottom: 1px solid rgba(236, 239, 241, 0.2);
  color: #cfd8dc;
}
.app.grey .top-toolbar.mat-toolbar-row .top-menu a,
.app.grey .top-toolbar.mat-toolbar-row .sidenav-toggle {
  color: #cfd8dc;
}
.app.grey .top-navbar {
  background: #fafafa;
}
.app.grey .border-bottom-mute {
  border-bottom: 1px solid rgba(236, 239, 241, 0.2);
}
.app.grey .search-dropdown.mat-mdc-menu-panel {
  background: transparent;
}
.app.grey .mat-mdc-snack-bar-container.success .mdc-snackbar__surface {
  background: #388E3C;
}
.app.grey .mat-mdc-snack-bar-container.error .mdc-snackbar__surface {
  background: #E53935;
}
.app.grey .new-price {
  color: #f44336;
}
.app.grey .primary-text {
  color: #455a64;
}
.app.grey .active-link {
  background-color: black;
  color: #fff;
}
.app.grey .horizontal-active-link,
.app.grey .app-dropdown .mat-mdc-menu-item.horizontal-active-link {
  color: #455a64 !important;
}
.app.grey .filter-brands button.selected,
.app.grey .filter-buttons button.selected {
  box-shadow: 0px 0px 1px 2px #455a64;
}
.app.grey .product-pagination .ngx-pagination {
  margin-bottom: 0;
  padding: 14px 10px;
}
.app.grey .product-pagination .ngx-pagination .current {
  background: black;
  color: white;
}
.app.grey .product-pagination .ngx-pagination a:hover, .app.grey .product-pagination .ngx-pagination button:hover {
  background: rgb(165, 165, 165);
  color: black;
}
.app.grey .breadcrumb a {
  color: #6b6b6b;
}
.app.grey .breadcrumb .breadcrumb-item + .breadcrumb-item:before {
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .user-block {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.app.grey .menu-item .mat-mdc-button:not(.active-link) .menu-icon {
  color: black;
}
.app.grey .messages .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label {
  color: rgba(255, 255, 255, 0.6);
}
.app.grey .messages .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: white;
}

.gradient-purple {
  background: #7873f5 !important;
  background: -webkit-linear-gradient(50deg, #ff6ec4, #7873f5) !important;
  background: -o-linear-gradient(50deg, #ff6ec4, #7873f5) !important;
  background: linear-gradient(50deg, #ff6ec4, #7873f5) !important;
  color: #fff !important;
}

.gradient-indigo {
  background: #303f9f !important;
  background: -webkit-linear-gradient(50deg, #2ec6ff, #303f9f) !important;
  background: -o-linear-gradient(50deg, #2ec6ff, #303f9f) !important;
  background: linear-gradient(50deg, #2ec6ff, #303f9f) !important;
  color: #fff !important;
}

.gradient-blue {
  background: #2196F3 !important;
  background: -webkit-linear-gradient(50deg, #37ffb5, #2196F3) !important;
  background: -o-linear-gradient(50deg, #37ffb5, #2196F3) !important;
  background: linear-gradient(50deg, #37ffb5, #2196F3) !important;
  color: #fff !important;
}

.gradient-orange {
  background: #FF7043 !important;
  background: -webkit-linear-gradient(50deg, #ffd86f, #FF7043) !important;
  background: -o-linear-gradient(50deg, #ffd86f, #FF7043) !important;
  background: linear-gradient(50deg, #ffd86f, #FF7043) !important;
  color: #fff !important;
}

.gradient-green {
  background: #2E7D32 !important;
  background: -webkit-linear-gradient(50deg, #C5D429, #2E7D32) !important;
  background: -o-linear-gradient(50deg, #C5D429, #2E7D32) !important;
  background: linear-gradient(50deg, #C5D429, #2E7D32) !important;
  color: #fff !important;
}

.gradient-pink {
  background: #E91E63 !important;
  background: -webkit-linear-gradient(50deg, #F59E99, #E91E63) !important;
  background: -o-linear-gradient(50deg, #F59E99, #E91E63) !important;
  background: linear-gradient(50deg, #F59E99, #E91E63) !important;
  color: #fff !important;
}

.gradient-red {
  background: #D50000 !important;
  background: -webkit-linear-gradient(50deg, #FF9E80, #D50000) !important;
  background: -o-linear-gradient(50deg, #FF9E80, #D50000) !important;
  background: linear-gradient(50deg, #FF9E80, #D50000) !important;
  color: #fff !important;
}

.gradient-amber {
  background: #e65100 !important;
  background: -webkit-linear-gradient(50deg, #FFBA3A, #e65100) !important;
  background: -o-linear-gradient(50deg, #FFBA3A, #e65100) !important;
  background: linear-gradient(50deg, #FFBA3A, #e65100) !important;
  color: #fff !important;
}

.gradient-gray {
  background: #263238 !important;
  background: -webkit-linear-gradient(50deg, #8EF9FE, #263238) !important;
  background: -o-linear-gradient(50deg, #8EF9FE, #263238) !important;
  background: linear-gradient(50deg, #8EF9FE, #263238) !important;
  color: #fff !important;
}

.gradient-teal {
  background: #00796B !important;
  background: -webkit-linear-gradient(50deg, #A9DC7B, #00796B) !important;
  background: -o-linear-gradient(50deg, #A9DC7B, #00796B) !important;
  background: linear-gradient(50deg, #A9DC7B, #00796B) !important;
  color: #fff !important;
}

.gradient-brown {
  background: #5D4037 !important;
  background: -webkit-linear-gradient(50deg, #FA9151, #5D4037) !important;
  background: -o-linear-gradient(50deg, #FA9151, #5D4037) !important;
  background: linear-gradient(50deg, #FA9151, #5D4037) !important;
  color: #fff !important;
}

.gradient-lime {
  background: #827717 !important;
  background: -webkit-linear-gradient(50deg, #FDD819, #827717) !important;
  background: -o-linear-gradient(50deg, #FDD819, #827717) !important;
  background: linear-gradient(50deg, #FDD819, #827717) !important;
  color: #fff !important;
}

.app[dir=rtl] .mat-icon.caret {
  width: auto;
}
.app[dir=rtl] .flag-menu-title {
  margin-left: 0;
  margin-right: 3px;
}
.app[dir=rtl] .app-dropdown.account .mat-mdc-menu-content .user-info img {
  margin-right: 0;
  margin-left: 16px;
}
.app[dir=rtl] .search-form input[type=text] {
  border-left: 0;
  border-right: 1px solid #ccc;
}
.app[dir=rtl] .search-form .search-btn {
  margin-left: 0;
  margin-right: -20px;
}
.app[dir=rtl] .swiper-container {
  direction: ltr;
}
.app[dir=rtl] .swiper-slide {
  direction: rtl;
}
.app[dir=rtl] .info-bar .mat-mdc-card .content {
  margin-left: 0;
  margin-right: 10px;
}
.app[dir=rtl] .footer .contact-information .mat-icon {
  margin-right: 0;
  margin-left: 10px;
}
.app[dir=rtl] .shopping-cart-btn .mat-badge-content {
  left: 8px !important;
  right: 0 !important;
}
.app[dir=rtl] .cart-dropdown.mat-mdc-menu-panel .list-item img {
  margin-left: 0;
  margin-right: 10px;
}
.app[dir=rtl] .ratings-count {
  margin-left: 0;
  margin-right: 12px;
}
.app[dir=rtl] .details button {
  margin-left: 0;
  margin-right: 6px;
}
.app[dir=rtl] .pl-3 {
  padding-left: 0;
  padding-right: 30px;
}
.app[dir=rtl] .all-products.p-left,
.app[dir=rtl] .account-content.p-left {
  padding-left: 2px;
  padding-right: 16px;
}
.app[dir=rtl] .sub-category button {
  padding-left: 0;
  padding-right: 30px;
}
.app[dir=rtl] .sub-category .sub-category button {
  padding-left: 0;
  padding-right: 50px;
}
.app[dir=rtl] .orders-table.mat-mdc-table .mat-mdc-cell .btn-invoice {
  margin-left: 0;
  margin-right: 8px;
}
.app[dir=rtl] .back-to-top {
  right: auto;
  left: 20px;
}
.app[dir=rtl] .ps__rail-y {
  right: auto !important;
  left: 0 !important;
}
.app[dir=rtl] .ps__thumb-y {
  right: auto !important;
  left: 1px !important;
}
.app[dir=rtl] .menu-expand-icon {
  right: auto;
  left: 10px;
}

button.mdc-button.mdc-button--raised.mat-mdc-raised-button.mat-primary.mat-mdc-button-base {
  background-color: black;
  border-radius: 15px;
}

button.mdc-button.mdc-button--raised.mat-mdc-raised-button.mat-warn.mat-mdc-button-base {
  border-radius: 15px;
}

.admin-table.mat-mdc-table .mat-mdc-cell .actions button {
  background-color: black;
}

mat-card.mat-mdc-card.mdc-card {
  border-radius: 15px;
}

.user-name {
  padding: 0 12px;
  font-weight: 400;
}

.user-content {
  padding: 10px;
}
.user-content .user-img {
  position: relative;
}
.user-content .user-img img {
  border-radius: 50%;
  width: 80px;
  height: 80px;
}
.user-content .user-img img.blocked {
  opacity: 0.5;
}
.user-content .user-img .mat-icon {
  position: absolute;
  top: -8px;
  left: -8px;
  font-size: 96px;
  height: 96px;
  width: 96px;
}
.user-content .mat-mdc-slide-toggle {
  margin-top: 15px;
}
.user-content .user-details span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.user-content .user-details .mat-icon {
  padding: 2px 10px;
}
.user-content .user-details.blocked {
  opacity: 0.5;
}

.user-search {
  width: 0;
  overflow: hidden;
  transition: 0.3s;
}
.user-search .mat-mdc-form-field {
  width: 100% !important;
  text-align: center !important;
}
.user-search .mat-mdc-form-field .mat-mdc-form-field-infix {
  min-height: 36px;
  padding: 8px 0 !important;
}
.user-search .mat-mdc-form-field .mat-mdc-form-field-subscript-wrapper {
  display: none;
}
.user-search .mat-mdc-input-element {
  text-align: center;
}
.user-search.show {
  width: 100%;
  overflow: visible;
}

.user-spinner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.mat-mdc-card {
  overflow: hidden;
}

.mdc-radio__outer-circle {
  border-color: rgb(0, 0, 0) !important;
}

.mdc-radio__inner-circle {
  border-color: rgb(58, 58, 58) !important;
}

.add-btn {
  height: 53px;
}

#searchBy {
  /*
  #search input[type='search']:hover {
    background-color: #fff;
    padding-left: 30px;
  } */
}
#searchBy input[type=search] {
  background: #ffffff url(https://static.tumblr.com/ftv85bp/MIXmud4tx/search-icon.png) no-repeat 9px center;
  border: solid 1px #000000;
  padding: 0px 5px 0px 35px;
  width: 355px;
  -webkit-border-radius: 10em;
  -moz-border-radius: 10em;
  border-radius: 10em;
  height: 40px;
  /* -webkit-transition: all 0.5s; */
  /* -moz-transition: all 0.5s; */
  /* transition: all 0.5s; */
  /* -webkit-box-sizing: content-box; */
  -webkit-appearance: textfield;
  outline: none;
  /* cursor: pointer; */
  z-index: 5;
}
#searchBy #search input[type=search].filled {
  /* width: 400px; */
  /* padding-left: 32px; */
  color: #000000;
  background-color: #fff;
  cursor: auto;
  font-size: 14px;
  height: 40px;
}
#searchBy input[type=search]:focus {
  /* width: 400px; */
  background-color: #fff;
  border-color: #000000;
  /* padding-left: 32px; */
  cursor: auto;
  -webkit-box-shadow: 0 0 5px rgba(109, 207, 246, 0.5);
  -moz-box-shadow: 0 0 5px rgba(109, 207, 246, 0.5);
  box-shadow: 0 0 5px rgba(109, 207, 246, 0.5);
}
#searchBy #search input:-moz-placeholder {
  color: transparent;
}
#searchBy #search input::-webkit-input-placeholder {
  color: transparent;
}
#searchBy #search input[type=search].filled:-moz-placeholder {
  color: #000000;
}
#searchBy #search input[type=search].filled::-webkit-input-placeholder {
  color: #000000;
  /* padding-left: 25px; */
}
#searchBy .search-box,
#searchBy .close-icon,
#searchBy .search-wrapper {
  position: relative;
  padding: 10px;
}
#searchBy #search input[type=search]:not(:valid) ~ .close-icon {
  display: none;
}
#searchBy .search-box:not(:valid) ~ .close-icon {
  display: none;
}

.action-section {
  display: grid;
  grid-template-columns: 2fr 0.3fr;
}