/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '~swiper/swiper-bundle.min.css';
@import "app/theme/styles/base";
@import "app/theme/styles/spaces";
@import "app/theme/styles/admin";
@import "app/theme/styles/theme";
@import "app/theme/styles/gradients";
@import "app/theme/styles/rtl";


button.mdc-button.mdc-button--raised.mat-mdc-raised-button.mat-primary.mat-mdc-button-base{
  background-color: black;
  border-radius: 15px;
}
button.mdc-button.mdc-button--raised.mat-mdc-raised-button.mat-warn.mat-mdc-button-base{
  border-radius: 15px;
}

.admin-table.mat-mdc-table .mat-mdc-cell .actions button{
  background-color: black;
}

mat-card.mat-mdc-card.mdc-card{
  border-radius: 15px;
}


// user component scss start
.user-name{
  padding: 0 12px;
  font-weight: 400;
}
.user-content{
  padding: 10px;
  .user-img{
      position: relative;
      img{
          border-radius: 50%;
          width: 80px;
          height: 80px;
          &.blocked{
              opacity: 0.5;
          }
      }
      .mat-icon{
          position: absolute;
          top: -8px;
          left: -8px;
          font-size: 96px;
          height: 96px;
          width: 96px;
      }
  }
  .mat-mdc-slide-toggle{
      margin-top: 15px;
  }
  .user-details{
      span{
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
      }
      .mat-icon{
          padding: 2px 10px;
      }
      &.blocked{
          opacity: 0.5;
      }
  }
}

.user-search{
  width: 0;
  overflow: hidden;
  transition: 0.3s;
  .mat-mdc-form-field{
      width: 100% !important;
      text-align: center !important;
      .mat-mdc-form-field-infix {
          min-height: 36px;
          padding: 8px 0 !important;
      }
      .mat-mdc-form-field-subscript-wrapper {
          display: none;
      }
  }
  .mat-mdc-input-element{
      text-align: center;
  }
  &.show{
      width: 100%;
      overflow: visible;
  }
}

.user-spinner{
  position: absolute;
  top:0; right: 0; bottom: 0; left:0;
}

.mat-mdc-card{
  overflow: hidden;
}
// user component scss end


.mdc-radio__outer-circle {
  border-color: rgb(0, 0, 0) !important;
}
.mdc-radio__inner-circle{
  border-color: rgb(58, 58, 58) !important;
}


.add-btn{
  height: 53px
}



#searchBy {
  // .right {
  //     float: right;
  //   }

    input[type='search'] {
      background: #ffffff
        url(https://static.tumblr.com/ftv85bp/MIXmud4tx/search-icon.png) no-repeat
        9px center;
      border: solid 1px #000000;
      padding: 0px 5px 0px 35px;
      width: 355px;
      -webkit-border-radius: 10em;
      -moz-border-radius: 10em;
      border-radius: 10em;
      height: 40px;

      /* -webkit-transition: all 0.5s; */
      /* -moz-transition: all 0.5s; */
      /* transition: all 0.5s; */
      /* -webkit-box-sizing: content-box; */
      -webkit-appearance: textfield;
      outline: none;
      /* cursor: pointer; */
      z-index: 5;
    }
    #search input[type='search'].filled {
      /* width: 400px; */
      /* padding-left: 32px; */
      color: #000000;
      background-color: #fff;
      cursor: auto;
      font-size: 14px;
      height: 40px;
    }

    input[type='search']:focus {
      /* width: 400px; */
      background-color: #fff;
      border-color: #000000;
      /* padding-left: 32px; */
      cursor: auto;
      -webkit-box-shadow: 0 0 5px rgba(109, 207, 246, 0.5);
      -moz-box-shadow: 0 0 5px rgba(109, 207, 246, 0.5);
      box-shadow: 0 0 5px rgba(109, 207, 246, 0.5);
    }
    /*
    #search input[type='search']:hover {
      background-color: #fff;
      padding-left: 30px;
    } */

    #search input:-moz-placeholder {
      color: transparent;
    }

    #search input::-webkit-input-placeholder {
      color: transparent;
    }
    #search input[type='search'].filled:-moz-placeholder {
      color: #000000;
    }
    #search input[type='search'].filled::-webkit-input-placeholder {
      color: #000000;
      /* padding-left: 25px; */
    }

    .search-box,
    .close-icon,
    .search-wrapper {
      position: relative;
      padding: 10px;
    }

  //   .close-icon {
  //     border: 1px solid transparent;
  //     background-color: transparent;
  //     display: inline-block;
  //     vertical-align: middle;
  //     outline: 0;
  //     cursor: pointer;
  //   }

  //   .close-icon:after {
  //     content: 'X';
  //     display: block;
  //     width: 15px;
  //     height: 15px;
  //     position: absolute;
  //     background-color: #fa9595;
  //     z-index: 1;
  //     right: 35px;
  //     top: 0;
  //     bottom: 0;
  //     margin: auto;
  //     padding: 2px;
  //     border-radius: 50%;
  //     text-align: center;
  //     color: white;
  //     font-weight: normal;
  //     font-size: 12px;
  //     box-shadow: 0 0 2px #e50f0f;
  //     cursor: pointer;
  //   }
    #search input[type='search']:not(:valid) ~ .close-icon {
      display: none;
    }

    .search-box:not(:valid) ~ .close-icon {
      display: none;
    }

}


.action-section {
  display: grid;
  grid-template-columns: 2fr 0.3fr;
}
